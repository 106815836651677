import { useState } from 'react';
import { Card, Button, Modal, Col, Row, Form } from 'react-bootstrap';
import { sendExcel } from '../../services/orderService';
import { OrderForm } from '../shared/OrderForm';
import { t } from '../../services/translationService';
import { addOrderRowsToCart } from '../../services/CartService';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { useDispatch } from 'react-redux';
import { setCartSize } from '../../redux/reducers/cartSlice.reducer';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from "../../typescript/enums/enums";

export const BulkOrder = () => {
    const dispatch = useDispatch();
    const [rows, setRows] = useState<any>([]);
    const [excelData, setExcelData] = useState('');
    const [hasClientErrors, setHasClientErrors] = useState(false);
    const [hasServerErrors, setHasServerErrors] = useState(false);

    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [canAddToCart, setCanAddToCart] = useState(false);

    const addAll = () => {
        const data = excelData
            .split('\n')
            .map((d) => d.split('\t'))
            .map((d) => ({
                articleNumber: d[0],
                amount: d[1],
                selectedDistributor: d[2],
                reference: d[3],
            }));

        setRows(data);
        setExcelData("");
    }

    const addItemsToCart = async () => {
        dispatch(setLoader(true));
        let cartItems = await addOrderRowsToCart(rows);
        dispatch(setLoader(false));
        dispatch(setCartSize(cartItems
            .map((p: any) => p.amount)
            .reduce((p: any, c: any) => p + c, 0)));

        NotificationClass.createNotification({
            type: NotificationTypes.Success,
            message: t('itemsAddedToCart')
        });

        setConfirmationVisible(true);
    }

    const closeConfirmation = () => {
        setRows([]);
        setConfirmationVisible(false);
    }

    const ConfirmationModal = ({ show }: { show: boolean }) => (
        <Modal show={show} size="lg" centered>
            <Modal.Body>
                <p>{t('itemsAddedToCart')}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={ closeConfirmation }>{t('okExclamation')}</Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <>
            <ConfirmationModal show={confirmationVisible} />
            <Card className="nopadding">
                <Card.Header className={`p-3 ${!hasServerErrors ? 'border-0' : ''}`}>
                    <Row>
                        <Col sm={4}>
                            <p className="text-muted mb-3 u-text-include-linebreak">
                                {t('massOrderInstructions')}
                            </p>
                        </Col>
                        <Col sm={8} className="d-flex flex-column align-items-end">
                            <Form.Control
                                className="u-text-monospace-pre"
                                as="textarea"
                                value={excelData}
                                onChange={(e) =>
                                    setExcelData((e.target as HTMLTextAreaElement).value)
                                }
                                isInvalid={hasClientErrors}
                            />
                            <div className="d-flex justify-content-between w-100">
                                <span>
                                    {hasClientErrors && (
                                        <Form.Control.Feedback type="invalid" className="d-block">
                                            {t('missingArticleNumber')}
                                        </Form.Control.Feedback>
                                    )}
                                </span>
                                <div className="d-flex gap-3">
                                    <Button className="mt-3" variant="primary" onClick={addAll}>
                                        {t('addAll')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                {!hasServerErrors && rows.length > 0 && (
                    <>
                        <Card.Body>
                            <OrderForm rows={rows} setRows={setRows} isValid={setCanAddToCart} populated={true} autoPopulate={true} />
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                onClick={addItemsToCart}
                                disabled={rows.some((r: any) => r.invalid) || !canAddToCart}
                            >
                                {t('addAll')}
                            </Button>
                        </Card.Footer>
                    </>
                )}
            </Card>
        </>
    );
};
