import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoaderState {
  value: boolean;
  delay?: number;
}

const initialState: LoaderState = {
  value: false,
  delay: 400
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {

      setLoader: (state, action: {payload: boolean, delay?: number}) => {
      // highlight-end
      state.value = action.payload;
      state.delay = action.delay ?? 400;
    },
  }
})

export const { setLoader } = loaderSlice.actions
export default loaderSlice.reducer