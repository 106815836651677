import { useEffect, useRef, useState } from 'react';
import {
	Button,
	Card,
	Col,
	FormCheck,
	FormControl,
	FormSelect,
	Modal,
	OverlayTrigger,
	Row,
	Tooltip,
} from 'react-bootstrap';
import { Table } from '../shared/Table';
import { PrintOrder } from './PrintOrder';
import {
	archive,
	getOrder,
	getOrders,
	getSearchOptions,
	sendOrderConfirmation,
} from '../../services/orderService';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import { t } from '../../services/translationService';
import { ItemResponse } from './ItemResponse';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../typescript/enums/enums';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { useSearchParams } from 'react-router-dom';
import { isDisabled } from '@testing-library/user-event/dist/utils';

declare global {
	interface Bokinfo {
		isBookstore: boolean;
		isPublisher: boolean;
	}
}

export const Order = () => {
	const md = useScreenWidth(768);

    const [queryParams] = useSearchParams();
	const [orders, setOrders] = useState<any>([]);
	const [current, setCurrent] = useState<any>(undefined);
	const [details, setDetails] = useState<any>([]);
	const [items, setItems] = useState<any>([]);
	const [loadingOrder, setLoadingOrder] = useState<any>(undefined);
    const dispatch = useDispatch();

	const [isBookstore, setisBookstore] = useState(
		window.bokinfo.isBookstore
	);

	const [isPublisher, setisPublisher] = useState(
		window.bokinfo.isPublisher
	);

	const searchTabs: { [key: string]: string } = {
		InProgress: t('inbox'),
		Completed: t('archive'),
	};

	const [searchTab, setSearchTab] = useState(Object.keys(searchTabs)[0]);
	const [searchFilters, setSearchFilters] = useState<{ [key: string]: string }>(
		{}
	);
	const [searchFilter, setSearchFilter] = useState(
		Object.values(searchFilters)[0]
	);
	const prevSearchFilterRef = useRef('');
	const [searchQuery, setSearchQuery] = useState(queryParams.get('id') ?? '');
	const [confirmation, setConfirmation] = useState(false);

	useEffect(() => {
		getSearchOptions().then((res) => {
			setSearchFilters(res);
		});
	}, []);

	useEffect(() => {
		setSearchFilter(Object.values(searchFilters)[0]);
	}, [searchFilters]);

	useEffect(() => {
		if(prevSearchFilterRef.current === '' && searchFilter) {
			prevSearchFilterRef.current = searchFilter;
			search();
		}
	}, [searchFilter]);

	useEffect(() => {
		setCurrent(undefined);
		if(searchFilter) {
			search();
		}
	}, [searchTab]);

	useEffect(() => {
		if (loadingOrder?.orderNumber) {
			dispatch(setLoader(true));
            getOrder(loadingOrder.orderNumber).then(({ items, ...details }: any) => {
				const mappedDetails = [
					[
						[t('customerOrderNumber'), details.orderComment],
						[t('orderDate'), new Date(details.created).toLocaleDateString('sv-SE') + ' ' + new Date(details.created).toLocaleTimeString('sv-SE', { timeStyle: 'short'}) ],
                        [t('confirmedDate'), details.issueDateTime ? new Date(details.issueDateTime).toLocaleDateString('sv-SE') : null],
                        [t('phone'), details.customerPhone],
                        [t('email'), details.customerEmail],
                        [t('orderNumber'), loadingOrder.orderNumber],
                        [t('address'), details.publicAddress],
                        [t('invoiceAddress'), details.billingAddress],
                        [t('deliveryAddress'), details.shippingAddress],
                        [t('gln'), loadingOrder.gln],
                        [t('bokinfoId'), details.customId],
                        [t('primaryContactName'), loadingOrder.primaryContactName],
                       
					],
					[
						[t('distributor'), details.distributor],
						[t('phone'), details.distributorPhone],
						[t('email'), details.distributorEmail],
                    ],
                    [
                        [t('customerName'), details.customerOrganizationName],
                        [t('address'), details.publicAddress],
                        [t('deliveryAddress'), details.shippingAddress],
                        [t('organizationNumber'), details.organizationNumber],
                        [t('website'), details.webb],
                    ],
				];

				dispatch(setLoader(false));
				setCurrent(loadingOrder);
				setLoadingOrder(undefined);
                setDetails(mappedDetails);
               
				setItems(items);
			});
		}
	}, [loadingOrder]);

	const search = () => {
        dispatch(setLoader(true));
        console.log(searchFilter);
		getOrders(searchTab, searchQuery, searchFilter).then((res) => {
			if (res) {
				setOrders(res);
				dispatch(setLoader(false));
			}
		});		
	}

	const sendConfirmation = () => {
		dispatch(setLoader(true));
		sendOrderConfirmation(current?.orderGroupId).then(() => {
			setConfirmation(false);
			setCurrent(undefined);
			dispatch(setLoader(false));
			NotificationClass.createNotification({
				type: NotificationTypes.Success,
				message: 'Orderbekräftelsen skickades',
			});

			search();
		});
	};

	const setQuantityMessage = (item: any) => {
		let message = '';

		if (item.quantityShipping > 0)
			message += t('confirmed') + ': ' + item.quantityShipping;
		if (item.canceledQuantity > 0) {
			if (message !== '') message += ', ';
			message += t('denied') + ': ' + item.canceledQuantity;
		}
		if (item.backorderedQuantity > 0) {
			if (message !== '') message += ', ';
			message += t('rest') + ': ' + item.backorderedQuantity;
		}

		return message;
	};

	const archiveOrder = (orderId: any) => {
		dispatch(setLoader(true));
		archive(orderId).then(() => {
			dispatch(setLoader(false));
			setOrders(orders.filter((order: any) => order.orderGroupId !== orderId));
			setCurrent(undefined);
		});
	};

	const renderTooltip = (statusText: string, props: any) => {
		if (statusText.length === 0) return <></>;

		return (
			<Tooltip className="text-start" {...props}>
				<span className="d-block">{statusText}</span>
			</Tooltip>
		);
	};

	return (
		<>
			<div>
				<Modal show={confirmation} size="lg" centered>
					<Modal.Body>
						<p>{t('confirmSendConfirmation')}</p>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => sendConfirmation()}>{t('yes')}</Button>
						<Button variant="secondary" onClick={() => setConfirmation(false)}>
							{t('no')}
						</Button>
					</Modal.Footer>
				</Modal>
				<Row>
					<Col
						style={{ height: md ? 222 : 'auto' }}
						md={6}
						className="mb-md-0 mb-4"
					>
						<Card className="nopadding h-100">
							<Card.Body className="d-flex flex-column p-3">
								{isPublisher && (
									<Row>
										<Col>
											{Object.entries(searchTabs).map(([k, v]) => (
												<FormCheck
													type="radio"
													label={v}
													id={'orderfilter-' + v.replace(/\s/g, "")}
													inline
													onChange={() => setSearchTab(k)}
													checked={k === searchTab}
												/>
											))}
										</Col>
									</Row>
								)}
								<Row className="d-flex flex-grow-1 align-items-center mt-md-0 mt-3">
									<Col md={6} className="mb-md-0 mb-3">
										<FormSelect
											onChange={(e) => setSearchFilter(e.target.value)}
										>
											{Object.entries(searchFilters).map(([k, v]) => (
												<option value={k}>{v}</option>
											))}
										</FormSelect>
									</Col>
									<Col md={6}>
										<FormControl
											placeholder={`Skriv ${searchFilter?.toLowerCase()}...`}
											value={searchQuery}
											onInput={(e) =>
												setSearchQuery((e.target as HTMLTextAreaElement).value)
											}
										/>
									</Col>
								</Row>
							</Card.Body>
							<Card.Footer>
								<Button onClick={search}>{t('search')}</Button>
							</Card.Footer>
						</Card>
					</Col>
					<Col style={{ height: md ? 222 : 'auto' }} md={6}>
						<Card className="nopadding h-100 u-vertical-scroll">
							<Card.Body className="p-3">
								<Table
									width={md ? undefined : 768}
									columnGap={2}
									rowHeight={2}
									smallTableHeadFont
									firstColumnBold
									striped
									columns={[
										{ title: t('orderNumber'), width: '30%' },
										{ title: t('date'), width: '20%' },
										{ title: '', width: '45%', key: 'organizationName' },
										{ title: '', width: '5%', key: 'status' },
									]}
									items={
										orders?.length > 0
											? orders.map((order: any) => ({
													columns: [
														<Button
															variant="link"
															style={{
																fontWeight: 500,
																textDecoration: 'none',
															}}
															onClick={() => setLoadingOrder(order)}
														>
															{order.orderNumber}
														</Button>,
														new Date(order.date).toLocaleDateString('sv-SE'),
														order.organizationName,
														isBookstore &&
														order.backorderedOrCanNotBeSupplied ? (
															<OverlayTrigger
																placement="top"
																delay={{ show: 100, hide: 400 }}
																overlay={(props) =>
																	renderTooltip(order.statusText, props)
																}
															>
																<i
																	className="bi bi-exclamation-triangle text-danger"
																	style={{
																		height: '1rem',
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'center',
																		fontSize: '1rem',
																		width: '1rem',
																	}}
																/>
															</OverlayTrigger>
														) : (
															order.issued && (
																<OverlayTrigger
																	placement="top"
																	delay={{ show: 100, hide: 400 }}
																	overlay={(props) =>
																		renderTooltip(order.statusText, props)
																	}
																>
																	<i
																		className="bi bi-check text-success"
																		style={{
																			height: '1rem',
																			display: 'flex',
																			alignItems: 'center',
																			justifyContent: 'center',
																			fontSize: '2rem',
																			width: '1rem',
																		}}
																	/>
																</OverlayTrigger>
															)
														),
													],
											  }))
											: []
									}
								/>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				{current?.orderNumber && (
					<Row className="mt-5">
						<Col>
							<h2 className="h3">
								{t('order')} {current?.orderNumber}
							</h2>
							<Card className="nopadding">
								<Card.Body className="p-3">
									<Row>
										<Col>
											<Table
												columnGap={2}
												rowHeight={2}
												hideHeader
												firstColumnBold
												striped
												columns={[
													{ title: '', width: '200px', key: 'title' },
													{
														title: '',
														width: 'calc(100% - 200px)',
														key: 'value',
													},
												]}
												items={
													 details[0]
														? details[0].map((t: any) => ({
																columns: t,
														  }))
														: []
												}
											/>
										</Col>
										<Col>
											<Table
												columnGap={2}
												rowHeight={2}
												hideHeader
												firstColumnBold
												striped
												columns={[
													{
														title: '',
														width: md ? '180px' : '200px',
														key: 'title',
													},
													{
														title: '',
														width: `calc(100% - ${md ? 180 : 200}px)`,
														key: 'value',
													},
												]}
												items={
													details[1] && !isPublisher
														? details[1].map((t: any) => ({
																columns: t,
														  }))
                                                        : details[2] && isPublisher ? details[2].map((t: any) => ({
                                                            columns: t,
                                                        })) : []
												}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="d-flex justify-content-end gap-2 py-4">
											{isPublisher && searchTab === 'InProgress' && (
												<Button
													variant="secondary"
													onClick={() => archiveOrder(current?.orderGroupId)}
												>
													<i className="bi bi-archive me-2" />
													{t('moveToArchive')}
												</Button>
											)}

											<Button variant="secondary" onClick={window.print}>
												<i className="bi bi-printer me-2" />
												{t('print')}
											</Button>
										</Col>
									</Row>
									<Row>
										<Col>
											<Table
												width={1222}
												columnGap={2}
												rowHeight={2}
												smallTableHeadFont
												firstColumnBold={false}
												striped
												columns={[
													{ title: t('articleNumber'), width: '15%' },
													{ title: t('author'), width: '20%', truncated: true },
													{ title: t('title'), width: '20%', truncated: true },
													{ title: t('amount'), width: '5%' },
													{ title: t('price'), width: '5%' },
													{ title: t('reference'), width: '10%' },
													{ title: t('priceId'), width: '10%' },
													{ title: t('response'), width: '15%' },
												]}
												items={items.map((t: any, i: number) => ({
													columns: [
														t.articleNr,
														t.author,
														t.title,
														<span title={setQuantityMessage(t)}>
															{t.quantity}
														</span>,
														t.fPrice,
														t.reference,
														t.priceIdentifier,
														<ItemResponse
															key={current.orderGroupId + '-' + i}
															isPublisher={isPublisher}
															isBookstore={isBookstore}
															status={t.status}
															orderId={current.orderGroupId}
															index={i}
															issued={current.issued}
														/>,
													],
												}))}
											/>
										</Col>
									</Row>
								</Card.Body>
								{isPublisher && !current.issued && (
									<Card.Footer>
										<Button onClick={() => setConfirmation(true)}>
											{t('sendConfirmation')}
										</Button>
									</Card.Footer>
								)}
							</Card>
						</Col>
					</Row>
				)}
			</div>
			<div className="print-version">
				{current?.orderNumber && (
					<PrintOrder
						currentOrder={current?.orderNumber}
						orderDetails={
							details[0] && details[1] ? [...details[0], ...details[1]] : []
						}
						orderItems={items}
					/>
				)}
			</div>
		</>
	);
};
