import { CartItem } from '../../redux/types/types';
import { removeItem } from '../../services/CartService';
import { useDispatch } from 'react-redux';
import {
	CheckBox,
	ProductDetails,
	Distributors,
	DistributorDetails,
	Amount,
	Reference,
	Delete,
} from './CartModules';
import { setCartSize } from '../../redux/reducers/cartSlice.reducer';

interface Props {
	item: CartItem;
	setItems: any;
	setInitialItems: any;
}

export const CartMobileItem = ({
	item: {
		cartItemId,
		catalogEntryCode,
		amount,
		reference,
		includeInOrder,
		selectedPriceShortName,
		coverUrl,
		itemUrl,
		title,
		author,
		prices,
	},
	setItems,
	setInitialItems,
}: Props) => {
  const dispatch = useDispatch();

	const updateItem = (cartItemId: number, field: string, value: any) => {
		setItems((current: CartItem[]) => {
			const index = current.findIndex(
				(c) => cartItemId === c.cartItemId
			);
			return [
				...current.slice(0, index),
				{ ...current[index], [field]: value },
				...current.slice(index + 1),
			];
		});
	};

	return (
		<div className="border-bottom py-4">
			<CheckBox
				includeInOrder={includeInOrder}
				cartItemId={cartItemId}
				updateItem={updateItem}
			/>

			<div className="d-flex">
				<ProductDetails
					cover={coverUrl || ''}
					title={title}
					author={author}
					articleNumber={catalogEntryCode}
					itemUrl={itemUrl}
				/>
				<Delete
					removeItem={removeItem}
					cartItemId={cartItemId}
					dispatch={dispatch}
					setCartSize={setCartSize}
					setItems={setItems}
					setInitialItems={setInitialItems}
				/>
			</div>

			<div className="d-flex py-4">
				<div className="w-50 d-flex">
					<div className="me-2">
						<Distributors
							prices={prices}
							selectedPriceShortName={selectedPriceShortName}
							cartItemId={cartItemId}
							updateItem={updateItem}
						/>
					</div>
					<div>
						<DistributorDetails
							prices={prices}
							selectedPriceShortName={selectedPriceShortName}
							cartItemId={cartItemId}
							updateItem={updateItem}
						/>
					</div>
				</div>

				<div className="">
					<Amount
						cartItemId={cartItemId}
						amount={amount}
						updateItem={updateItem}
					/>
				</div>
			</div>

			<Reference
				reference={reference}
				cartItemId={cartItemId}
				updateItem={updateItem}
			/>
		</div>
	);
};
