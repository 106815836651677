export const LockedFields = {
    newCert: ['DistributorGLN', 'Status', 'GTINinfo', 'Created', 'Modified'],
    approved: [
        'Title',
        'DistributorGLN',
        'BuyerGLN',
        'SellerGLN',
        'Status',
        'GTINinfo',
        'Created',
        'Modified',
        'Price',
        'PalletType',
        'GTINPallet',
        'PalletLayers',
        'PalletItemsPerLayer',
        'PalletTotalWeight',
        'PalletTotalHeight',
        'PalletTotalItems',
    ],
	allFields: [
		'Title',
		'DistributorGLN',
		'BuyerGLN',
		'SellerGLN',
		'Status',
		'GTINinfo',
		'Created',
		'Modified',
		'ProductClassCategory',
		'Tradename',
		'Trademark_Author',
		'ShelfEdge_ReceiptText',
		'Width',
		'Height',
		'BackWidth',
		'Weight',
		'VAT',
		'Price',
		'PalletType',
		'GTINPallet',
		'PalletLayers',
		'PalletItemsPerLayer',
		'PalletTotalWeight',
		'PalletTotalHeight',
		'PalletTotalItems',
	],
};
