import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../types/types';

// highlight-start
// Define a type for the slice state
interface NotificationsState {
  value: Notification[];
}

// Define the initial state using that type
const initialState: NotificationsState = {
  value: []
}
// highlight-end

export const notificationsSlice = createSlice({
  name: 'notifications',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // highlight-start
    // Use the PayloadAction type to declare the contents of `action.payload`
    addNotification: (state, action: PayloadAction<Notification>) => {
      // highlight-end
      state.value.push(action.payload)
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      // highlight-end
      const id: number = state.value.findIndex(x => x.id === action.payload);
      if (id > -1) {
        state.value.splice(id, 1);
      }
    }
  }
})

export const { addNotification, removeNotification } = notificationsSlice.actions
export default notificationsSlice.reducer