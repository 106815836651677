import axios from 'axios';

const uri = '/sv-se/api/ShoppingCartApi/shoppingcart';
const placeUri = '/sv-se/api/ShoppingCartApi/place';
const clearUri = '/sv-se/api/ShoppingCartApi/shoppingcartdelete';
const baseUri = '/sv-se/api/ShoppingCartApi';

interface SubmitResponse {
    created: boolean,
    message: string
}

export const getCart = async () => {
    try {
        const res = await axios.get(uri);

        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const getArticleInfo = async (catalogEntryCode: string) => {
    try {
        const { data } = await axios.get(`${baseUri}/article/${catalogEntryCode}`);
        return data;
    } catch (e) {
        console.log(e);
    }
};

export const addItem = async (catalogEntryCode: string, amount: number, distributorId: number, distributorShortName: string, reference?: string) => {
    try {
        const res = await axios.post(uri, [
            {
                catalogEntryCode: catalogEntryCode,
                amount: amount,
                selectedPriceShortName: distributorShortName,
                includeInOrder: true,
                distributorId: distributorId,
                reference: reference || '',
            },
        ]);

        return res;

    } catch (err) {
        console.log(err);
    }
};

export const addOrderRowsToCart = async (rows: any) => {
    let cartItems;
    for (const row of rows) {
        if (row.articleNumber?.length) {
            const distributor = row.distributors.filter((d: any) => d.shortName == row.selectedDistributor)[0];
            cartItems = await addItem(row.articleNumber, row.amount, (distributor as any).distributorId, (distributor as any).shortName, row.reference);
        }
    }
    return cartItems?.data;
}

export const removeItem = async (id: number) => {
    try {
        const res = await axios.delete(uri + `/${id}`);

        return res;
    } catch (err) {
        console.log(err);
    }
};

export const clearCart = async () => {
    try {
        const res = await axios.delete(clearUri);

        return res;
    } catch (err) {
        console.log(err);
    }
};

export const updateCart = async (newCart: any) => {
    try {
        const res = await axios.put(uri, newCart);

        return res;
    } catch (err) {
        console.log(err);
    }
};

export const saveOrder = async (cart: any, reference: string) : Promise<SubmitResponse> => {
    try {
        const { data } = await axios.post<SubmitResponse>(placeUri, { items: cart, reference: reference });
        return data;
    } catch (err) {
        console.log(err);
        return { created: false, message: "Ok�nt fel" };
    }
};
