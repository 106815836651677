import { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';
import { t } from '../../../services/translationService';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getItemStyle, getDragContainerClass } from '../common';

interface Props {
  errors: any;
  register: any;
  control: any;
  getValues: any;
  setValue: any;
  trigger: any;
  errorRows?: number[];
}

export const Kapitel = ({
  errors,
  register,
  control,
  getValues,
  trigger,
}: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  const {
    fields: kapitel,
    append: appendKapitel,
    remove: removeKapitel,
    move: moveKapitel,
    update: updateKapitel
  } = useFieldArray({
    control,
    name: 'kapitel',
  });

  const onKapitelDragEnd = (result: any) => {
    moveKapitel(result.source.index, result.destination.index);
  }

  const saveKapitel = () =>
    trigger(
      `kapitel.${currentIndex !== undefined ? currentIndex : kapitel.length - 1
      }`
    ).then((validResult: boolean) => {
      if (validResult) {
        const last = getValues(`kapitel.${kapitel.length - 1}`);
        const current = getValues(`kapitel.${currentIndex}`);

        if (!!current) {
          current.startsida = current.startsida === "" ? null : current.startsida;
        }
        else if (!!last) {
          last.startsida = last.startsida === "" ? null : last.startsida;
        }

        currentIndex === undefined && removeKapitel(kapitel.length - 1);
        currentIndex !== undefined
          ? updateKapitel(currentIndex, current)
          : appendKapitel({ ...last });

        setShowModal(false);
      }
    });

  const displayModal = (index?: number) => {
    index === undefined ? appendKapitel({}) : setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    currentIndex === undefined && removeKapitel(kapitel.length - 1);
  };

  return (
    <>
      <Col xs={12}>
        <Form.Label className="mb-1 text-body">
          {t("kapitel")}
        </Form.Label>
        <Card className="mb-4 nopadding">
          <Card.Body className="">
            {kapitel?.length > 0 && (
              <div className='mb-3 w-100 table'>
                <div className="small px-3 py-1 d-flex  border-bottom">
                  <div style={{ width: "10%" }}>
                    <span className="d-block">{t('sekvens')}</span>
                  </div>
                  <div style={{ width: "10%" }}>
                    <span className="d-block text-end">{t('startTid')}</span>
                  </div>
                  <div style={{ width: "10%" }}>
                    <span className="d-block text-end pe-2">{t('startSida')}</span>
                  </div>
                  <div style={{ width: "30%" }}>
                    <span className="d-block">{t('beskrivning')}</span>
                  </div>
                  <div style={{ width: "40%" }}>
                    <span className="d-block"></span>
                  </div>
                </div>
                <DragDropContext onDragEnd={onKapitelDragEnd}>
                  <Droppable droppableId="droppablekapitel">
                    {(provided: any, snapshot: any) => (
                      <div
                        ref={provided.innerRef}
                        className={`mb-3 ${getDragContainerClass(
                          snapshot.isDraggingOver
                        )}`}
                        {...provided.droppableProps}
                      >
                        {kapitel.map((row: any, index: number) => {
                          return (
                            <Draggable
                              key={row.id}
                              draggableId={row.id}
                              index={index}
                            >
                              {(provided: any, snapshot: any) => (
                                <div
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  key={row.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                    } d-flex gx-0 px-3 u-text-small-medium u-text-grey py-3 align-items-center border-bottom ${getDragContainerClass(snapshot.isDraggingOver)}`}
                                >
                                  <div style={{ width: "10%" }}>
                                    {getValues(`kapitel.${index}.sekvensnummer`)}
                                  </div>
                                  <div className="text-end" style={{ width: "10%" }}>
                                    {getValues(`kapitel.${index}.starttid`)}
                                  </div>
                                  <div className="text-end pe-2" style={{ width: "10%" }}>
                                    {getValues(`kapitel.${index}.startsida`)}
                                  </div>
                                  <div style={{ width: "30%" }}>
                                    <span className="text-truncate d-block">
                                      {getValues(`kapitel.${index}.beskrivning`)}
                                    </span>
                                  </div>
                                  <div style={{ width: "40%" }}>
                                    <div className="d-flex gap-3 justify-content-end">
                                      <i
                                        className="bi bi-pencil u-text-primary u-cursor-pointer"
                                        onClick={() => displayModal(index)}
                                      />
                                      <i
                                        className="bi bi-trash3 u-text-error u-cursor-pointer"
                                        onClick={() => removeKapitel(index)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>)}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </Card.Body>
          <Card.Footer>
            <Button
              type="button"
              className="primary"
              onClick={() => displayModal()}
            >
              <i className="bi bi-plus" />
              {t('laggTillKapitel')}
            </Button>
          </Card.Footer>
        </Card>
      </Col>

      <Modal
        show={showModal}
        centered
        size="lg"
        onHide={closeModal}
        onExited={() => setCurrentIndex(undefined)}
      >
        <Modal.Header>
          <p>{t("laggTill")}</p>
        </Modal.Header>
        <Modal.Body>
          {kapitel.map((row: any, index: number) => {
            return (
              index ===
              (currentIndex !== undefined
                ? currentIndex
                : kapitel.length - 1) && (
                <Row key={index}>
                  <Col xs={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t('sekvens')}*
                      </Form.Label>
                      <Form.Control
                        key={row.id}
                        placeholder={"n.n.n"}
                        {...register(`kapitel.${index}.sekvensnummer`,
                          {
                            required: t("valideringSekvensNummer"),
                            pattern: { value: /^\d+(\.\d+)*$/, message: t("valideringSekvensNummer") }
                          })}
                        isInvalid={
                          !!errors.kapitel && errors.kapitel[index].sekvensnummer
                        }
                      />
                      {!!errors.kapitel && errors.kapitel[index].sekvensnummer && (
                        <Form.Control.Feedback type="invalid">
                          {`${errors.kapitel[index].sekvensnummer.message}`}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t("startTid")}
                      </Form.Label>
                      <Form.Control
                        key={row.id}
                        placeholder={"hh:mm:ss"}
                        {...register(`kapitel.${index}.starttid`,
                          {
                            pattern: {
                              value: /^\d+:\d+:\d+$/, message: t("valideringStartTid")
                            },
                            validate: (value: any) => !!value || !!getValues(`kapitel.${index}.startsida`) ? true : t("valideringStartTidOrStartPage")
                          })}
                        isInvalid={
                          !!errors.kapitel && !!errors.kapitel[index].starttid
                        }
                      />
                      {!!errors.kapitel && !!errors.kapitel[index].starttid && (
                        <Form.Control.Feedback type="invalid">
                          {`${errors.kapitel[index].starttid.message}`}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t("startSida")}
                      </Form.Label>
                      <Form.Control
                        key={row.id}
                        placeholder={t('skrivEllips')}
                        {...register(`kapitel.${index}.startsida`,
                          {
                            validate: (value: any) => !!value || !!getValues(`kapitel.${index}.starttid`) ? true : t("valideringStartTidOrStartPage")
                          }
                        )}
                        isInvalid={
                          !!errors.kapitel && !!errors.kapitel[index].startsida
                        }
                      />
                      {!!errors.kapitel && !!errors.kapitel[index].startsida && (
                        <Form.Control.Feedback type="invalid">
                          {`${errors.kapitel[index].starttid.message}`}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t('beskrivning')}
                      </Form.Label>
                      <Form.Control
                        key={row.id}
                        placeholder={t('skrivEllips')}
                        {...register(`kapitel.${index}.beskrivning`, {
                          required: t("valideringBeskrivning")
                        })}
                        isInvalid={
                          !!errors.kapitel && errors.kapitel[index].beskrivning
                        }
                      />
                      {!!errors.kapitel &&
                        errors.kapitel[index].beskrivning && (
                          <Form.Control.Feedback type="invalid">
                            {`${errors.kapitel[index].beskrivning.message}`}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
              )
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            {t('avbryt')}
          </Button>
          <Button variant="primary" onClick={saveKapitel}>
            {t('spara')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
