import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface baseIconProps {
	text: string;
	className?: string;
	regularSize?: boolean;
}
interface childIconProps extends baseIconProps {
	child: JSX.Element;
	inline?: boolean;
}
interface inlineIconProps extends baseIconProps {
	inline: boolean;
	child?: JSX.Element;
}

type props = childIconProps | inlineIconProps;

export const InformationIcon = ({
	text,
	child,
	inline,
	className,
	regularSize,
}: props) => {
	const renderTooltip = (props: any) => <Tooltip {...props}>{text}</Tooltip>;

	return (
		<>
			{!inline ? (
				<div className="input-group information-icon">
					{child}
					<OverlayTrigger
						placement="top"
						delay={{ show: 100, hide: 400 }}
						overlay={renderTooltip}
					>
						<span className="input-group-text">
							<i
								className={`bi bi-question-circle ${
									!regularSize ? 'tiny' : ''
								} u-text-grey-light u-cursor-pointer ${className}`}
							/>
						</span>
					</OverlayTrigger>
				</div>
			) : (
				<OverlayTrigger
					placement="top"
					delay={{ show: 100, hide: 400 }}
					overlay={renderTooltip}
				>
					<i
						className={`bi bi-question-circle ${
							!regularSize ? 'tiny' : ''
						} u-text-grey-light u-cursor-pointer ${className}`}
					/>
				</OverlayTrigger>
			)}
		</>
	);
};
