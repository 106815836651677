import { IChangePriceScaleResult, IChangePriceScaleForm, changePriceScale } from "../../services/userService";

export default class changePriceScaleClass {
    changePriceScaleForm: HTMLFormElement;
    priceScaleSelector: HTMLSelectElement;
    submitbutton: HTMLButtonElement;
    alertBox: HTMLDivElement;
    pricePercentageContainer: HTMLDivElement;
    pricePercentageInput: HTMLInputElement;

    static init() {
        let form = document.getElementById("changepricescaleform") as HTMLFormElement;

        if (form) {
            new changePriceScaleClass(form);
        }
    }

    constructor(form: HTMLFormElement) {
        this.changePriceScaleForm = form;
        this.changePriceScaleForm.addEventListener("submit", (e: Event) => {
            this.submitForm(e, this);
        });

        this.priceScaleSelector = this.changePriceScaleForm.querySelector("#PriceScale") as HTMLSelectElement;
        this.alertBox = this.changePriceScaleForm.querySelector("#changepricescalealert") as HTMLDivElement;
        this.submitbutton = document.getElementById('changepricescalesubmitbutton') as HTMLButtonElement;
        this.pricePercentageContainer = this.changePriceScaleForm.querySelector("#PriceScalePercentageContainer") as HTMLDivElement;
        this.pricePercentageInput = this.changePriceScaleForm.querySelector("#PriceScalePercentage") as HTMLInputElement;
        this.priceScaleSelector = this.changePriceScaleForm.querySelector("#PriceScale") as HTMLSelectElement;

        this.priceScaleSelector.dataset.originalValue = this.priceScaleSelector.value;

        this.submitbutton.disabled = this.checkIfFormIsInvalid();

        this.priceScaleSelector.addEventListener("change", () => {
            console.log(this.priceScaleSelector.value);
            if(this.priceScaleSelector.value === '12') {
                this.pricePercentageContainer.classList.remove('d-none');
            } else {
                this.pricePercentageContainer.classList.add('d-none');
            }
            this.submitbutton.disabled = this.checkIfFormIsInvalid();
        });

        this.pricePercentageInput.addEventListener("change", () => {
            this.submitbutton.disabled = this.checkIfFormIsInvalid();
        });

        this.alertBox.dataset.genericText = this.alertBox.innerText;
        this.alertBox.innerText = "";
    }

    submitForm(event: Event, cObject: changePriceScaleClass) {
        event.preventDefault();
        if (!this.alertBox.classList.contains("d-none")) {
            this.alertBox.classList.add("d-none");
        }

        this.submitbutton.disabled = true;
        this.submitbutton.dataset.initialtext = this.submitbutton.innerText;
        this.submitbutton.innerText = this.submitbutton.dataset.waittext as string;

        let dataObject = {
            priceScale: this.priceScaleSelector.value,
            priceScalePercentage: this.pricePercentageInput.value
        } as IChangePriceScaleForm;

        changePriceScale(dataObject, this.alertBox.dataset.genericText as string).then((data: IChangePriceScaleResult) => {
            let message = data.message as string ?? "";

            let errorList = data.errorList as string[];

            if (errorList !== null && errorList.length > 0) {
                if (message !== "") {
                    message += "<br />";
                }

                let isMulti = errorList.length > 1;
                message += isMulti ? "<ul class=\"mb-0\" >" : "";
                for (var i = 0; i < errorList.length; i++) {
                    if ((errorList[i] ?? "") !== "") {
                        let description = `${isMulti ? "<li>" : ""}${errorList[i]}${isMulti ? "</li>" : "" }`;
                        message += description;
                    }
                }
                message += isMulti ? "</ul>" : "";
            }

            this.showMessage(message, data.success ? "success" : "error");

            this.submitbutton.disabled = false;
            this.submitbutton.innerText = this.submitbutton.dataset.initialtext as string;
        });
    }

    checkIfFormIsInvalid() : boolean {
        let invalid = false;

        if (this.priceScaleSelector.value === "" || (this.priceScaleSelector.value !== '12' && this.priceScaleSelector.value === this.priceScaleSelector.dataset.originalValue)) {
            invalid = true;
        }
        console.log(this.priceScaleSelector.value);
        if(this.priceScaleSelector.value === '12' && this.pricePercentageInput.value === "") {
            invalid = true;
        }

        return invalid;
    }

    showMessage(message: string, type: string) {

        this.alertBox.innerHTML = message;
        if (type === "error") {
            this.alertBox.classList.remove("alert-success");
            this.alertBox.classList.add("alert-danger");
        } else {
            this.alertBox.classList.remove("alert-danger");
            this.alertBox.classList.add("alert-success");
        }

        this.alertBox.classList.remove("d-none");
    }
}