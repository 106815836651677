import axios from 'axios';

const baseUri = 'api/returnRequests';

export const getCurrent = async () => {
	try {
		const { data } = await axios.get(`/${baseUri}/current`);
		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};

export const getHistory = async (page: number) => {
	try {
		const { data } = await axios.get(`/${baseUri}/history?page=${page}`);
		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};

export const getDetails = async (id: string) => {
	try {
		const { data } = await axios.get(`/${baseUri}/${id}`);
		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};

export const createOrUpdate = async (
	articleNumber: string,
	distributor: string,
	amount: number
) => {
	try {
		const { data } = await axios.post(`/${baseUri}/${articleNumber}`, {
			data: { distributor, amount },
		});
		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};
