import React, {
	ChangeEvent,
	useEffect,
	useRef,
	useState,
	KeyboardEvent,
} from 'react';
import { Navbar, Overlay, Popover, Button } from 'react-bootstrap';
import { useAppSelector } from '../../redux/hooks';
import {
	postSignin,
	postSignOut,
	SigninModel,
	SignInResult,
} from '../../services/authenticateService';

interface Props {
	labels: any;
	mobile: boolean;
	userOrganizationName: string;
	userName: string;
	forgotPasswordUrl: string;
	userSettingsUrl: string;
}

export const HeaderLogin = ({
	labels,
	mobile,
	userOrganizationName,
	userName,
	forgotPasswordUrl,
	userSettingsUrl,
}: Props) => {
	const [loggedIn, setLoggedIn] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [show, setShow] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>();
	const language = useAppSelector((state: any) => state.language.value);
	const target = useRef(null);

	const handleOpenClose = () => {
		setShow(!show);
	};

	const updateEmail = (event: ChangeEvent<HTMLInputElement>): void => {
		let value: string = (event.target as HTMLInputElement).value;
		setEmail(value);
	};

	const updatePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		let value: string = (event.target as HTMLInputElement).value;
		setPassword(value);
	};

	const doLogin = () => {
		if (
			email !== undefined &&
			email !== null &&
			email !== '' &&
			password !== undefined &&
			password !== null &&
			password !== ''
		) {
			let signInData = {
				userName: email,
				password: password,
			} as SigninModel;

			postSignin(language, signInData).then((result: SignInResult) => {
				if (result.succeeded) {
					setShow(false);
					setLoggedIn(true);
					window.location.reload();
				} else {
					setPassword('');
					setErrorMessage(result.message);
				}
			});
		}
	};

	const doLogout = () => {
        postSignOut(language).then((value: any) => {
            if (value?.redirectUrl?.length) {
                window.location.href = value.redirectUrl;
            } else {
                window.location.reload();
            }
		});
	};

	const onKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			doLogin();
		}
	};

	useEffect(() => {
		if (userName !== undefined && userName !== '' && userName !== null) {
			setLoggedIn(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{mobile ? (
				<Navbar.Brand
					onClick={handleOpenClose}
					ref={target}
					className="mobile-icon-item ms-auto d-block d-lg-none"
				>
					<i className="bi bi-person me-2"></i>
				</Navbar.Brand>
			) : (
				<Button
					onClick={handleOpenClose}
					ref={target}
					className="btn btn-secondary u-text-weight-medium u-text-grey ms-auto d-none d-lg-block"
				>
					<i className="bi bi-person me-2"></i>
					{!loggedIn ? labels.buttonLogin : labels.myBokinfo}
				</Button>
			)}
			<Overlay
				rootClose={true}
				show={show}
				onHide={() => setShow(false)}
				target={target.current}
				placement="bottom-end"
			>
				<Popover id="popover-login" className="header__login">
					<Popover.Body>
						{loggedIn ? (
							<div>
								<span className="u-text-small-medium mb-4 d-block">
									{labels.loggedInAs}
								</span>
								<span className="d-block form-label">
									{userOrganizationName} - {userName}
								</span>
								<Button
									onClick={doLogout}
									type="button"
									variant="primary"
									className="d-block mt-4 w-100"
								>
									{labels.buttonLogout}
								</Button>

								<a
									href={userSettingsUrl}
									className="btn btn-secondary d-block mt-4 mb-3 w-100"
								>
									<i className="bi bi-gear me-2"></i>
									{labels.settings}
								</a>
							</div>
						) : (
							<div>
								<h3 className="h3 mb-4">{labels.buttonLogin}</h3>

								<span className="form-label my-1">{labels.username}</span>
								<input
									type="text"
                                    id="username"
									className="w-100"
									value={email}
									onChange={updateEmail}
								></input>

								<span className="form-label my-1">{labels.password}</span>
								<input
									type="password"
                                    id="password"
									className="w-100"
									value={password}
									onChange={updatePassword}
									onKeyDown={onKeyDownHandler}
								></input>

								<a
									className="text-end d-block mt-3 text-decoration-none u-text-small-medium"
									href={forgotPasswordUrl}
								>
									{labels.forgottenPassword}?
								</a>
								{errorMessage && (
									<p className="u-text-error mt-3">{errorMessage}</p>
								)}
								<Button
									onClick={doLogin}
									type="button"
									variant="primary"
									className="d-block mt-3 w-100"
								>
									{labels.buttonLogin}
								</Button>
							</div>
						)}
					</Popover.Body>
				</Popover>
			</Overlay>
		</>
	);
};
