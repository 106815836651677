
export default class CollapserOverflowCheck {

    static init() {
        let pageHasCollapsers = document.getElementsByClassName('collapse');

        if (pageHasCollapsers) {
            new CollapserOverflowCheck();
        }
    }

    constructor() {
        const collapsers = [].slice.call(
            document.querySelectorAll('.collapse')
        );
        collapsers.forEach((el: HTMLElement) => {
            if (!el.parentNode) {
                return;
            }

            if (this.checkOverflow(el)) {
                const parent = el.parentNode;
                let sibling = parent.firstChild;
                while (sibling) {
                    if (sibling.nodeType === 1 && (<HTMLElement>sibling).className.indexOf('hidden-collapser') > 0) {
                        (<HTMLElement>sibling).classList.remove("d-none");
                    }
                    sibling = sibling.nextSibling;
                }
            }
        });
    }


    checkOverflow(el: HTMLElement): boolean {
        var curOverflow = el.style.overflow;

        if (!curOverflow || curOverflow === "visible")
            el.style.overflow = "hidden";

        var isOverflowing = el.clientWidth < el.scrollWidth
            || el.clientHeight < el.scrollHeight;

        el.style.overflow = curOverflow;

        return isOverflowing;
    }

    
}
