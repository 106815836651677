import axios from 'axios';

const BASEURIAUTOCOMPLETE = '/api/autocomplete';

export const getAutoComplete = async (query: string, type: string,language: string): Promise<string[]> => {
    try {
      const { data } = await axios.get<string[]>(
        `/${language}${BASEURIAUTOCOMPLETE}/${type}/${query}`,
        {
          headers: {
            Accept: 'application/json',
          },
        },
      );
  
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message);
        return [];
      } else {
        console.log('unexpected error: ', error);
        return [];
      }
    }
  }
    