import { CartItem } from '../../redux/types/types';
import { removeItem } from '../../services/CartService';
import { useDispatch } from 'react-redux';
import { setCartSize } from '../../redux/reducers/cartSlice.reducer';
import {
    CheckBox,
    ProductDetails,
    Distributors,
    DistributorDetails,
    Amount,
    Reference,
    Delete,
} from './CartModules';

interface Props {
    item: CartItem;
    setItems: any;
    setInitialItems: any;
    updateCart?: Function;
    hasChanged?: boolean;
    submitCart?: Function;
}

export const CartTableRow = ({
    item: {
        cartItemId,
        catalogEntryCode,
        itemUrl,
        amount,
        reference,
        includeInOrder,
        selectedPriceShortName,
        coverUrl,
        title,
        author,
        prices,
    },
    setItems,
    setInitialItems,
    updateCart,
    hasChanged,
    submitCart
}: Props) => {
    const dispatch = useDispatch();

    const updateItem = (cartItemId: number, field: string, value: any) => {
        setItems((current: CartItem[]) => {
            const index = current.findIndex(
                (c) => cartItemId === c.cartItemId
            );

            return [
                ...current.slice(0, index),
                { ...current[index], [field]: value },
                ...current.slice(index + 1),
            ];
        });
    };

    return (
        <tr className="align-middle border-bottom">
            <td style={{ width: '6rem' }}>
                <CheckBox
                    includeInOrder={includeInOrder}
                    cartItemId={cartItemId}
                    updateItem={updateItem}
                />
            </td>
            <td style={{ width: '20rem' }} className="d-flex py-4">
                <ProductDetails
                    cover={coverUrl || ''}
                    title={title}
                    author={author}
                    articleNumber={catalogEntryCode}
                    itemUrl={itemUrl}
                />
            </td>
            <td style={{ width: '8%' }}>
                <Distributors
                    prices={prices}
                    selectedPriceShortName={selectedPriceShortName}
                    cartItemId={cartItemId}
                    updateItem={updateItem}
                />
            </td>
            <td style={{ width: '15%' }}>
                <DistributorDetails
                    prices={prices}
                    selectedPriceShortName={selectedPriceShortName}
                    updateItem={updateItem}
                    cartItemId={cartItemId}
                />
            </td>
            <td style={{ width: '10rem' }}>
                <Amount
                    cartItemId={cartItemId}
                    amount={amount}
                    updateItem={updateItem}
                    updateCart={updateCart}
                    hasChanged={hasChanged}
                    submitCart={submitCart}
                />
            </td>
            <td style={{ width: '15%' }}>
                <Reference
                    reference={reference}
                    cartItemId={cartItemId}
                    updateItem={updateItem}
                    updateCart={updateCart}
                    hasChanged={hasChanged}
                    submitCart={submitCart}
                />
            </td>
            <td style={{ width: '10%' }}>
                <Delete
                    removeItem={removeItem}
                    cartItemId={cartItemId}
                    dispatch={dispatch}
                    setCartSize={setCartSize}
                    setItems={setItems}
                    setInitialItems={setInitialItems}
                />
            </td>
        </tr>
    );
};
