import { useState, createContext, useContext } from 'react';

export const RegistrationContext = createContext<any>({});

export const RegistrationProvider = ({ children }: any) => {
	const [data, setData] = useState({});

	const setFormValues = (values: any) => {
		setData((prevValues) => ({
			...prevValues,
			...values,
		}));
	};

	return (
		<RegistrationContext.Provider value={{ data, setFormValues }}>
			{children}
		</RegistrationContext.Provider>
	);
};

export const useRegistrationData = () => useContext(RegistrationContext);
