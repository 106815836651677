import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { getApplicationHistory } from '../../services/registrationService';
import { Pagination } from '../shared/PaginationComponent';
import { StatusBadge } from '../shared/StatusBadge';
import { Table } from '../shared/Table';
import { t } from '../../services/translationService';
import { ApplicationResult } from '../../redux/types/types';

const columns = [
	{ id: 0, title: '', width: '15%' },
	{ id: 1, title: t('tableBokinfoId'), width: '10%' },
	{ id: 2, title: t('tableCompanyName'), width: '20%' },
	{ id: 3, title: t('tableOrganizationNumber'), width: '15%' },
	{ id: 4, title: t('tableContact'), width: '15%' },
	{ id: 5, title: t('tableCreated'), width: '15%' },
	{ id: 6, title: t('tableStatus'), width: '10%' },
];

export const RegistrationAdministrationHistory = () => {

	const [data, setData] = useState<ApplicationResult>({} as ApplicationResult);

	useEffect(() => {
        getApplicationHistory(0).then((res: ApplicationResult) => {
			setData(res)
		});
	}, []);

    const setCurrentPage = (currentPage: number) => {
		if (currentPage !== data.page) {
            getApplicationHistory(currentPage).then((res: ApplicationResult) => {
				setData(res)
			});
        }
    };

	return (
		<>
			<Card className="nopadding">
				<Card.Header>
					<h2 className="h5 py-3 m-0">{t('newCustomerRequestHistory')}</h2>
				</Card.Header>
				<Card.Body>
					{data.items && (
						<Table
							smallTableHeadFont
							firstColumnBold={false}
							striped
							columns={columns}
							items={data?.items.map(
								({
                                    id,
                                    customerId,
									companyName,
									organizationNumber,
									contactName,
									created,
									status,
								}: any) => ({
									key: id,
									columns: [
										<a href={document.location.pathname.replace("historik/", "") + "granska/?id=" + id}>
											{t('buttonReviewRequest')}
										</a>,
										customerId,
										companyName,
										organizationNumber,
										contactName,
										created,
										<StatusBadge status={status} />,
									],
								})
							)}
						/>
					)}
				</Card.Body>
				<Card.Footer className="text-muted">
					<Pagination
						currentPage={ data.page ?? 0}
						pageSize={data.pageSize ?? 0}
						totalItems={data.totalCount ?? 0}
						setCurrent={setCurrentPage}
					/>
				</Card.Footer>
			</Card>
		</>
	);
};
