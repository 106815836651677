import axios from 'axios';
import { MediaPublication, MediaPublicationMedia } from '../components/shared/Interfaces/MediaPublication';

export interface IPublicationPostData {
    catalogEntryCode: string | undefined;
    publications: MediaPublicationMedia[] | undefined;
    errorMessage?: string;
}

export class PublicationPostData implements IPublicationPostData {
    catalogEntryCode: string | undefined;
    publications: MediaPublicationMedia[] | undefined;
    errorMessage?: string | undefined;
    publisherId?: number;
}

export interface PublicationServiceError {
    message: string;
    statusCode: string;
}

export const GetPublications = async (catalogEntryCode: string): Promise<MediaPublication | PublicationServiceError> => {
    try {
        const { data } = await axios.get<MediaPublication>(`/api/publications/publication/${catalogEntryCode}`);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return { message: error.message, statusCode: error.code || ""}
        } else {
            console.log('unexpected error: ', error);
            return { message: "Okänt fel", statusCode: "Okänd status" }
        }
    }

}

export const PostPublications = async (dataObject: PublicationPostData): Promise<IPublicationPostData> => {
    try {
        const { data } = await axios.post<IPublicationPostData>("/api/publications/publication", dataObject, {
            headers: {
                Accept: 'application/json',
            },
        });

        return data;
    }
    catch (error) {
        const em = new PublicationPostData()
        if (axios.isAxiosError(error)) {
            em.errorMessage = error.message;
            console.error('error message: ', error.message);
            return em;
        } else {
            console.error('unexpected error: ', error);
            return em;
        }
    }
}