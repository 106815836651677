import { Button, Nav } from 'react-bootstrap';
import { useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { FormCheck } from 'react-bootstrap';
import { t } from '../../services/translationService';

interface Props {
  blockRefs: any,
  isPublisher: any,
  isAdmin: any,
  currentForm: any,
  reviewed: any,
  setReviewed: any,
  setTab: any,
  tab: any,
  isEpub: boolean,
}

export const SideMenu = (
  {
    blockRefs,
    isPublisher,
    isAdmin,
    currentForm,
    reviewed,
    setReviewed,
    setTab,
    tab,
    isEpub
  }: Props) => {
  const [blocks, setBlocks] = useState<any>([]);
  const [highlight, setHighlight] = useState(0);
  const [override, setOverride] = useState(-1);
  const [reviewedValue, setReviewedValue] = useState(reviewed);

  useEffect(() => setBlocks(blockRefs.current
    .filter((element: any) => !!element)
    .map((el: any) => el)), [blockRefs, isEpub]);

  const onChangeReviewed = (value: boolean) => {
    setReviewed(value);
    setReviewedValue(value);
  }

  const setScroll = useCallback(() => {
    const breakpoints = blocks.map((b: any) => b.offsetTop);

    if (override < 0) {
      const block =
        breakpoints.filter(
          (o: any) => (window.innerHeight / 3) * 2 + window.scrollY > o
        ).length - 1;

      setHighlight(block >= 0 ? block : 0);
    } else {
      if (window.scrollY === breakpoints[override]) {
        setOverride(-1);
        setHighlight(override);
      }
    }
  }, [blocks, override]);

  useEffect(() => {
    window.addEventListener('scroll', setScroll);

    return () => window.removeEventListener('scroll', setScroll);
  }, [setScroll]);

  const scrollToBlock = (block: any, index: number) => {
    block.scrollIntoView();
    setOverride(index);
  };

  return (
    <div className="side-menu py-5">
      <div className="side-menu--wrapper">
        <p>{t('sidansInnehall')}</p>
        <div className="side-menu--buttons">
          {blocks.map((current: any, i: number) => (
            <Button
              key={current.textContent}
              variant=""
              className={
                (override >= 0 ? override : highlight) === i ? 'active' : ''
              }
              onClick={() => scrollToBlock(current, i)}
            >
              {current.textContent}
            </Button>
          ))}
        </div>
        <div className="pt-3 mt-4 border-top">
          {isAdmin ? (
            <FormCheck
              label="Granskad"
              className="mb-3"
              checked={reviewedValue}
              onChange={(ev) => onChangeReviewed(ev.target.checked)}
            />
          ) :
            <></>
          }
          {!isPublisher && (
            <>
              <Nav
                variant="tabs"
                className="mb-3 w-100"
                role="tablist"
              >
                <Nav.Item>
                  <Nav.Link className={tab === 'quick' ? 'active' : ''} aria-selected={tab === 'quick'} onClick={(e: any) => { e.stopPropagation(); setTab('quick') }}>{t('snabben')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className={tab === 'large' ? 'active' : ''} aria-selected={tab === 'large'} onClick={(e: any) => { e.stopPropagation(); setTab('large') }}>{t('storen')}</Nav.Link>
                </Nav.Item>
              </Nav>
            </>
          )}
          <a
            href={window.bokinfo.book?.boksidaUrl}
            className="btn btn-secondary mb-3 w-100"
          >
            {t('tillBoksida')} <i className="bi bi-arrow-right-short" />
          </a>
          <Button
            type="submit"
            form={currentForm}
            className="btn-primary w-100"
          >
            {t('spara')}
          </Button>
        </div>
      </div>
    </div>
  );
};
