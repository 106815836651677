import { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { getApplications } from '../../services/registrationService';
import { StatusBadge } from '../shared/StatusBadge';
import { Table } from '../shared/Table';
import { t } from '../../services/translationService';
import { useAppSelector } from '../../redux/hooks';
import { ApplicationResult, Notification } from '../../redux/types/types';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../typescript/enums/enums';


const columns = [
	{ id: 0, title: '', width: '15%' },
	{ id: 1, title: t('tableBokinfoId'), width: '10%' },
	{ id: 2, title: t('tableCompanyName'), width: '20%' },
	{ id: 3, title: t('tableOrganizationNumber'), width: '15%' },
	{ id: 4, title: t('tableContact'), width: '15%' },
	{ id: 5, title: t('tableCreated'), width: '15%' },
	{ id: 6, title: t('tableStatus'), width: '10%' },
];

export const RegistrationAdministration = () => {
    const [data, setData] = useState<ApplicationResult>();

    useEffect(() => {
        getApplications().then((res: ApplicationResult) => {
            setData(res)
        });
    }, []);

    useEffect(() => {
        const updateApplicationNotification = localStorage.getItem("updateApplicationNotification");

        try {
            if (updateApplicationNotification !== undefined && updateApplicationNotification !== null) {
                let notification: Notification = JSON.parse(updateApplicationNotification);
                NotificationClass.createNotification(notification);
            }
        }
        finally {
            localStorage.removeItem("updateApplicationNotification");
        }
    }, [data]);

	return (
		<>
			<Card className="nopadding">
				<Card.Header>
					<h2 className="h5 py-3 m-0">{t('newCustomerRequest')}</h2>
				</Card.Header>
				<Card.Body>
					{data && (
						<Table
							smallTableHeadFont
							firstColumnBold={false}
							striped
							columns={columns}
							items={data?.items?.map(
								({
                                    id,
                                    customerId,
									companyName,
									organizationNumber,
									contactName,
									created,
									status,
								}: any) => ({
									key: id,
									columns: [
										<a href={"granska/?id=" + id}>
											{t('buttonReviewRequest')}
										</a>,
                                        customerId,
										companyName,
										organizationNumber,
										contactName,
										created,
										<StatusBadge status={status} />,
									],
								})
							)}
						/>
					)}
				</Card.Body>
				<Card.Footer className="text-muted">
					<Button
						variant="primary"
						href="historik/"
					>
						{t('buttonShowHistory')}
					</Button>
				</Card.Footer>
			</Card>
		</>
	);
};
