import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { t } from '../../../services/translationService';

const getDragClass = (isDragging: boolean) => {
	return isDragging ? 'u-dnd-active' : '';
};

const getDragContainerClass = (isDraggingOver: boolean) => {
	return isDraggingOver ? 'u-dnd-container-active' : 'u-dnd-container-inactive';
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
	let transform = draggableStyle.transform;
	if (isDragging && transform) {
		transform = transform.replace(/\(.+\,/, '(0,');
	}
	return {
		...draggableStyle,
		transform: transform,
	};
};

const scrollToFirstError = () => {
    const errors = document.querySelectorAll('.invalid-feedback');
    if (errors.length) {
        const firstError = errors[0];
        const errorParent = firstError.parentElement?.parentElement;
        if (errorParent) {
            errorParent.scrollIntoView();
        }
    }
}

const hasChanged = (
	changedProperties: ChangedProperty[],
	fieldName: string
): boolean => {
	return changedProperties
		? changedProperties?.some(
            ({ propertyName }) => propertyName.toLocaleLowerCase() === fieldName.toLocaleLowerCase()
		  )
		: false;
};

const shouldBeLocked = (
	listOfLockedFields: keyValue[],
	fieldName: string
): boolean => {
    let test = listOfLockedFields?.some(
        (field: keyValue) => field.key.toLocaleLowerCase() === fieldName.toLocaleLowerCase());
    return test;
};

const getChangedInformation = (
	changedProperties: ChangedProperty[],
	fieldName: string
): ChangedProperty | undefined => {
	return changedProperties
		? changedProperties?.find(
				(changedProperty: ChangedProperty) =>
                changedProperty.propertyName.toLocaleLowerCase() === fieldName.toLocaleLowerCase()
		  )
		: undefined;
};

const getOptions = (name: string, useValue?: boolean) => {
	if (window.bokinfo.dictionaries[name]?.length > 0) {
		let list = window.bokinfo.dictionaries[name].slice(0);
		list.unshift({
			key: '',
			value: t('valjEllips'),
		});

		return list.map((entry: any, index: number) => {
			return index === 0 ? (
				<option key={null} value={''}>
					{entry.value}
				</option>
			) : useValue ? (
				<option key={entry.value} value={entry.value}>
					{entry.value}
				</option>
			) : (
				<option key={entry.key} value={entry.key}>
					{entry.value}
				</option>
			);
		});
	}
};

const getFilteredOptions = (name: string, filters: string[], useValue?: boolean) => {
    if (window.bokinfo.dictionaries[name]?.length > 0) {
        let list = window.bokinfo.dictionaries[name].slice(0);
        list.unshift({
            key: '',
            value: t('valjEllips'),
        });

        filters = filters?.filter(f => f !== undefined && f !== null && f !== "");

        return list.map((entry: any, index: number) => {
            
            if (filters?.length && ((useValue && filters.includes(entry.value)) || (!useValue && filters.includes(entry.key)))) {
                return null;
            }

            return index === 0 ? (
                <option key={null} value={''}>
                    {entry.value}
                </option>
            ) : useValue ? (
                <option key={entry.value} value={entry.value}>
                    {entry.value}
                </option>
            ) : (
                <option key={entry.key} value={entry.key}>
                    {entry.value}
                </option>
            );
        });
    }
};

const getOptionsLabel = (collectionName: string, key: any) => {
	if (
		window.bokinfo.dictionaries[collectionName] &&
        window.bokinfo.dictionaries[collectionName]?.length > 0 &&
		key !== undefined &&
		key !== null
	) {
		const result = window.bokinfo.dictionaries[collectionName].filter(
            (obj: any) => {
				return obj.key.toString() === key.toString();
			}
        );
		return result && result[0] ? result[0].value : key;
    } else {
		return key;
	}
};

const getOptionsString = (name: string) => {
	if (window.bokinfo.dictionaries[name]?.length > 0) {
		let list = window.bokinfo.dictionaries[name].slice(0);
		list.unshift(t('valjEllips'));
		return list.map((entry: any, index: number) => {
			return index === 0 ? (
				<option key={null} value={''}>
					{entry}
				</option>
			) : (
				<option key={entry} value={entry}>
					{entry}
				</option>
			);
		});
	}
};

const getBooleanOptions = () => {
	return (
		<>
			<option key={''} value={''}>
				{t('valjEllips')}
			</option>
			<option key={'true'} value={'true'}>
				{t('yes')}
			</option>
			<option key={'false'} value={'false'}>
				{t('no')}
			</option>
		</>
	);
};

export {
	getDragClass,
	getDragContainerClass,
	getItemStyle,
	hasChanged,
	shouldBeLocked,
	getChangedInformation,
	getOptions,
	getOptionsString,
	getOptionsLabel,
    getBooleanOptions,
    scrollToFirstError,
    getFilteredOptions
};
