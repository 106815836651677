import { recoverPassword, IRecoverPasswordPageViewModel, RecoverPasswordForm } from "../../services/userService";

export default class recoverPasswordClass {
    recoverPasswordForm: HTMLFormElement;
    usernameOrEmailField: HTMLInputElement;
    submitbutton: HTMLButtonElement;
    alertBox: HTMLDivElement;

    static init() {
        let form = document.getElementById("forgotpwdform") as HTMLFormElement;

        if (form) {
            new recoverPasswordClass(form);
        }
    }

    constructor(form: HTMLFormElement) {
        this.recoverPasswordForm = form;
        this.recoverPasswordForm.addEventListener("submit", (e: Event) => {
            this.submitForm(e, this);
        });

        this.usernameOrEmailField = this.recoverPasswordForm.querySelector("#UsernameOrEmail") as HTMLInputElement;
        this.alertBox = this.recoverPasswordForm.querySelector("#resetpasswordalert") as HTMLDivElement;
        this.submitbutton = document.getElementById('formsubmitbutton') as HTMLButtonElement;

        this.usernameOrEmailField.addEventListener("input", () => {
            this.submitbutton.disabled = this.usernameOrEmailField.value === "";
        });

        this.alertBox.dataset.genericText = this.alertBox.innerText;
        this.alertBox.innerText = "";
    }

    submitForm(event: Event, cObject: recoverPasswordClass) {
        event.preventDefault();
        if (!this.alertBox.classList.contains("d-none")) {
            this.alertBox.classList.add("d-none");
        }

        this.submitbutton.disabled = true;
        this.submitbutton.dataset.initialtext = this.submitbutton.innerText;
        this.submitbutton.innerText = this.submitbutton.dataset.waittext as string;

        let username = this.usernameOrEmailField.value;
        let dataObject = new RecoverPasswordForm();
        dataObject.usernameOrEmail = username;

        recoverPassword(dataObject, this.alertBox.dataset.genericText as string).then((data: IRecoverPasswordPageViewModel) => {
            this.showMessage(data.submitConfirmMessage as string, data.submitState ? "success" : "error");

            this.submitbutton.disabled = false;
            this.submitbutton.innerText = this.submitbutton.dataset.initialtext as string;
        });
    }

    showMessage(message: string, type: string) {

        this.alertBox.innerText = message;
        if (type === "error") {
            this.alertBox.classList.remove("alert-success");
            this.alertBox.classList.add("alert-danger");
        } else {
            this.alertBox.classList.remove("alert-danger");
            this.alertBox.classList.add("alert-success");
        }

        this.alertBox.classList.remove("d-none");
    }
}