import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
	callback: any;
	control: any;
	setValue: any;
	propName: string;
	fileUrl?: string;
}

export const UploadArea = ({
	callback,
	control,
	setValue,
	propName,
	fileUrl,
}: Props) => {
	const [dragActive, setDragActive] = useState<boolean>(false);
	const [imgData, setImgData] = useState('');
	const inputRef = useRef<any>(null);

	const onclick = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		inputRef.current?.click();
	};

	const clearSelection = (event?: React.MouseEvent<HTMLElement>) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		inputRef.current.value = '';
		setImgData('');
	};

	const handleFile = (file: File) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onloadend = () => {
			setImgData(reader.result as string);
			callback(reader.result, file.name);
			clearSelection(undefined);
		};
	};

	const fileOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.stopPropagation();
		if (event && event.target && event.target.files) {
			handleFile(event.target.files[0]);
		}
	};

	const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
			handleFile(e.dataTransfer.files[0]);
		}
	};

	return (
		<>
			<Form.Group
				className="mb-3 upload-area"
				id="file-upload"
				onDragEnter={handleDrag}
			>
				<Form.Control
					ref={inputRef}
					type="file"
					accept="image/jpg, image/jpeg, image/png"
					id="input-file-upload"
					className="upload-area__input"
					multiple={false}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						fileOnchange(event);
					}}
				/>
				<Form.Label
					htmlFor="input-file-upload"
					className={
						dragActive
							? 'upload-area__label upload-area__label--drag-active'
							: 'upload-area__label'
					}
				>
					<div
						className="upload-area__label__image"
						style={{
							backgroundImage: `url(${imgData})`,
						}}
					></div>
					<div
						className={`u-z-index-1 py-2 ${
							imgData ? 'u-background-grey-low-opacity' : ''
						}`}
					>
						<i className="bi bi-cloud-upload d-block"></i>
						<span className="upload-area__text">
							<p className="upload-area__text__link" onClick={onclick}>
								Klicka för att ladda upp
							</p>{' '}
							eller dra in filen
						</span>
						<span className="upload-area__text d-block">
							PNG, JPG (min. 600x600px)
						</span>
						{imgData && (
							<span
								className="u-text-error d-block u-cursor-pointer"
								onClick={clearSelection}
							>
								Ta bort
							</span>
						)}
					</div>
				</Form.Label>
				{dragActive && (
					<div
						className="upload-area__element u-z-index-1"
						onDragEnter={handleDrag}
						onDragLeave={handleDrag}
						onDragOver={handleDrag}
						onDrop={handleDrop}
					></div>
				)}
			</Form.Group>
		</>
	);
};
