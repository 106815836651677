import { Button, Form, Modal } from 'react-bootstrap';
import { CartFunctions } from './CartFunctions';
import { useCallback, useEffect, useState, KeyboardEvent } from 'react';
import { CartTable } from './CartTable';
import { CartMobile } from './CartMobile';
import { useDispatch } from 'react-redux';
import { clearCart, updateCart, saveOrder } from '../../services/CartService';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import { CartItem } from '../../redux/types/types';
import { setCartSize } from '../../redux/reducers/cartSlice.reducer';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../typescript/enums/enums';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { t } from '../../services/translationService';
import { useKeyEvent } from '../../hooks/useKeyPressed';

declare global {
    interface Bokinfo {
        cart: any;
        orderPageUrl: string;
        messages: string[];
    }
}

export const Cart = () => {
    const isDesktop = useScreenWidth(991);

    const [items, setItems] = useState<CartItem[]>([]);
    const [initialItems, setInitialItems] = useState<CartItem[]>([]);
    const [hasChanged, setHasChanged] = useState(false);
    const [anySelected, setAnySelected] = useState(false);
    const [anyInvalid, setAnyInvalid] = useState(true);
    const orderPage = window.bokinfo.orderPageUrl || "";

    const dispatch = useDispatch();

    const onKeyEvent = useKeyEvent;

    useEffect(() => {
        setItems(window.bokinfo.cart);
        setInitialItems(window.bokinfo.cart);
    }, []);

    useEffect(() => {
        setHasChanged(JSON.stringify(items) !== JSON.stringify(initialItems));
    }, [items, initialItems]);

    useEffect(() => {
        setAnySelected(!!items?.find((i) => i.includeInOrder));
        let invalidItems = items?.filter((item: CartItem) => {
            const selected = item.prices.find(price => item.selectedPriceShortName == price.distributorShortName);
            return selected && item.includeInOrder && !selected.isSellable;
        })
        setAnyInvalid(invalidItems?.length > 0);
    }, [items]);

    const [total, setTotal] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showSendOrder, setShowSendOrder] = useState(false);
    const [orderReference, setOrderReference] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const calculateTotalPrice = useCallback(
        () =>
            items
                ?.map(
                    (x) =>
                        (
                            x.prices.find(
                                (d) => d.distributorShortName === x.selectedPriceShortName
                            ) || x.prices[0]
                        ).price * x.amount
                )
                .reduce((p, c) => p + c, 0) ?? 0,
        [items]
    );

    useEffect(() => setTotal(calculateTotalPrice()), [calculateTotalPrice]);

    const toggleAll = () => {
        const noDeselected = !items?.map((i) => i.includeInOrder).includes(false);

        setItems(items?.map((i) => ({ ...i, includeInOrder: !noDeselected })));
    };

    const clear = () => {
        setShowConfirmation(true);
    };

    const confirmClear = () => {
        clearCart().then(() => {
            dispatch(setCartSize(0));
            setItems([]);
            setInitialItems([]);
            setShowConfirmation(false);
        });
    };

    const update = async () => {
        items?.length &&
            updateCart(items).then((res) => {
                dispatch(
                    setCartSize(
                        res?.data
                            .map((p: any) => p.amount)
                            .reduce((p: any, c: any) => p + c, 0)
                    )
                );
                setItems(res?.data);
                setInitialItems(res?.data);
            });
    };

    const submit = async () => {
        if (!items?.length) return;
        setSubmitting(true);
        setShowSendOrder(false)
        dispatch(setLoader(true));
        // Make sure latest changes are sent with order.
        await updateCart(items);

        saveOrder(items, orderReference).then(result => {
                (result && result.created) && orderPage.length && window.location.replace(orderPage);
                if (!result || !result.created) {
                    dispatch(setLoader(false));
                    setSubmitting(false);
                    console.error("Submit order error message: ", result.message);
                    NotificationClass.createNotification({
                        type: NotificationTypes.Error,
                        message: window.bokinfo.messages[0] || "",
                    })
                }
            }).catch(er => {
                dispatch(setLoader(false));
                setSubmitting(false);
                NotificationClass.createNotification({
                    type: NotificationTypes.Error,
                    message: window.bokinfo.messages[1] || "",
                });
            });
    }

    return (
        <>
            <Modal show={showSendOrder} centered
                onExited={() => setOrderReference("")} 
            >
                <Modal.Header>						
                    {t('addReference')}
                </Modal.Header>
                <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">		
                    {t('addReferenceLabel')}
                    </Form.Label>
                    <Form.Control
                        type={'text'}
                        value={orderReference}
                        onChange={(e) => setOrderReference(e.target.value)}
                        onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => onKeyEvent(e, [
                            { eventKey: "Enter", onEvent: () => { submit() } },
                            { eventKey: "Escape", onEvent: () => { setShowSendOrder(false) } },
                        ])}
                        autoFocus 
                    />
                </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => submit()}>{t('sendOrder')}</Button>
                    <Button variant="secondary" onClick={() => { setShowSendOrder(false);  } }>                        
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showConfirmation}
                onHide={() => setShowConfirmation(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('clearCartHeader')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('clearCartText')}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmation(false)}
                    >
                        {t('no')}
                    </Button>
                    <Button variant="primary" onClick={confirmClear}>
                        {t('yes')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {items && items.length > 0 ? (
                <div className="cart">
                    <CartFunctions
                        clear={clear}
                        setShowSendOrder={setShowSendOrder}
                        update={update}
                        hasChanged={hasChanged}
                        anySelected={anySelected}
                        anyInvalid={anyInvalid}
                        submitting={submitting}
                    />
                    {isDesktop ? (
                        <CartTable
                            items={items}
                            setItems={setItems}
                            setInitialItems={setInitialItems}
                            updateCart={update}
                            hasChanged={hasChanged}
                            submitCart={setShowSendOrder}
                            toggleAll={toggleAll}
                        />
                    ) : (
                        <CartMobile
                            items={items}
                            setItems={setItems}
                            setInitialItems={setInitialItems}
                            clear={clear}
                            toggleAll={toggleAll}
                        />
                    )}
                    <div className="d-flex justify-content-end mb-5">
                        {t('totalAmount')} {total.toFixed(2)} {t('currency')}
                    </div>
                    <CartFunctions
                        clear={clear}
                        setShowSendOrder={setShowSendOrder}
                        update={update}
                        hasChanged={hasChanged}
                        anySelected={anySelected}
                        anyInvalid={anyInvalid}
                        submitting={submitting}
                    />
                </div>
            ) : (
                <p className="text-muted">
                    <i className="bi bi-info-circle pe-2" />
                    {t('nothingInTheCart')}
                </p>
            )}
        </>
    );
};
