import { KeyboardEvent } from 'react'
import { FormCheck, Button, FormControl } from 'react-bootstrap';
import { t } from '../../services/translationService';
import { StatusIcon } from '../shared/StatusIcon';
import { useKeyEvent } from '../../hooks/useKeyPressed';

interface ICheckBox {
    includeInOrder: boolean;
    cartItemId: number;
    updateItem: any;
}

export const CheckBox = ({
    includeInOrder,
    cartItemId,
    updateItem,
}: ICheckBox) => (
    <FormCheck
        checked={includeInOrder}
        onChange={() =>
            updateItem(cartItemId, 'includeInOrder', !includeInOrder)
        }
    />
);

interface IProductDetails {
    cover: string;
    title: string;
    author: string;
    articleNumber: string;
    itemUrl: string;
}

export const ProductDetails = ({
    cover,
    title,
    author,
    articleNumber,
    itemUrl,
}: IProductDetails) => (
    <>
        <img src={cover} alt="cover" style={{ maxHeight: '134px' }} />
        <div className="ms-3">
            <h2 className='mb-0'>{title}</h2>
            <p style={{ fontSize: '.875rem' }} className="my-1">
                {author}
            </p>
            <p>
                <a href={itemUrl} title="ISBN" className="no-visited">
                    {articleNumber}
                </a>
            </p>
        </div>
    </>
);

interface IDistributors {
    prices: {
        isSellable: boolean;
        distributorLink?: string;
        distributorShortName: string;
    }[];
    selectedPriceShortName: string;
    cartItemId: number;
    updateItem: any;
}

export const Distributors = ({
    prices,
    selectedPriceShortName,
    cartItemId,
    updateItem,
}: IDistributors) => {

    return <>
        {prices.map(({ distributorShortName, distributorLink, isSellable }) => (
            <div key={`${distributorShortName}`} className="d-flex py-1">
                <FormCheck
                    type="radio"
                    checked={distributorShortName === selectedPriceShortName}
                    name={`${distributorShortName}_${cartItemId}`}
                    disabled={!isSellable}
                    onChange={() =>
                        updateItem(
                            cartItemId,
                            'selectedPriceShortName',
                            distributorShortName
                        )
                    }
                />
                <a href={distributorLink} className="ms-2">
                    {distributorShortName}
                </a>
            </div>
        ))}
    </>
};

interface IDistributorDetails {
    prices: {
        distributorShortName: string;
        price: number;
        stockBalance: number;
        stockStatus: string;
        stockStatusDescription: string;
    }[];
    selectedPriceShortName: string;
    updateItem: any;
    cartItemId: number;
}

export const DistributorDetails = ({ prices, cartItemId }: IDistributorDetails) => (
    <>
        {prices.map(
            ({
                distributorShortName,
                price,
                stockBalance,
                stockStatus,
                stockStatusDescription,
            }) => (
                <div key={`${cartItemId}-${distributorShortName}`} className="d-flex py-1 gap-3">
                    <div>{price.toFixed(2)} {t('currency')}</div>
                    <StatusIcon
                        status={stockStatus}
                        amount={stockBalance}
                        description={stockStatusDescription}
                    />
                </div>
            )
        )}
    </>
);

interface IAmount {
    cartItemId: number;
    amount: number;
    updateItem: any;
    updateCart?: Function;
    hasChanged?: boolean;
    submitCart?: Function;
}

const ensurePositiveNumber = (value: string | number) =>
    Number(value) && Number(value) >= 0 ? Number(value) : 0;

const onKeyEvent = useKeyEvent;

export const Amount = ({ cartItemId, amount, updateItem, updateCart, hasChanged, submitCart }: IAmount) => (
    <div className="d-flex align-items-center gap-2">
        <Button variant="secondary" className="rounded-circle p-0">
            <i
                className="bi bi-dash-lg"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '2rem',
                    height: '2rem',
                    justifyContent: 'center',
                }}
                onClick={() =>
                    updateItem(cartItemId, 'amount', ensurePositiveNumber(amount - 1))
                }
            />
        </Button>
        <FormControl
            value={amount}
            className="text-center p-md-2"
            onInput={(e) =>
                updateItem(
                    cartItemId,
                    'amount',
                    ensurePositiveNumber((e.target as HTMLTextAreaElement).value)
                )
            }
            onKeyUp={(e:KeyboardEvent<HTMLInputElement>) => {
                if (onKeyEvent) {
                    onKeyEvent(e, [
                        {
                            eventKey: "Enter", onEvent: () => {
                                if (hasChanged && updateCart) {
                                    updateCart();
                                } else if (submitCart) {
                                    submitCart(true);
                                }
                            }
                        }
                    ]);
                }
            }}
        />
        <Button variant="secondary" className="rounded-circle p-0">
            <i
                className="bi bi-plus-lg"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '2rem',
                    height: '2rem',
                    justifyContent: 'center',
                }}
                onClick={() =>
                    updateItem(cartItemId, 'amount', ensurePositiveNumber(amount + 1))
                }
            />
        </Button>
    </div>
);

interface IReference {
    reference: string;
    cartItemId: number;
    updateItem: any;
    updateCart?: Function;
    hasChanged?: boolean;
    submitCart?: Function;
}

export const Reference = ({
    reference,
    cartItemId,
    updateItem,
    updateCart,
    hasChanged,
    submitCart
}: IReference) => (
    <FormControl
        value={reference}
        onInput={(e) =>
            updateItem(
                cartItemId,
                'reference',
                (e.target as HTMLTextAreaElement).value
            )
        }
        onKeyUp={(e:KeyboardEvent<HTMLInputElement>) => {
            if (onKeyEvent) {
                onKeyEvent(e, [
                    {
                        eventKey: "Enter", onEvent: () => {
                            if (hasChanged && updateCart) {
                                updateCart();
                            } else if (submitCart) {
                                submitCart(true);
                            }
                        }
                    }
                ]);
            }
        }}    />
);

interface IDelete {
    removeItem: any;
    cartItemId: number;
    dispatch: any;
    setCartSize: any;
    setItems: any;
    setInitialItems: any;
}

export const Delete = ({
    removeItem,
    cartItemId,
    dispatch,
    setCartSize,
    setItems,
    setInitialItems
}: IDelete) => (
    <Button
        variant="outline-danger"
        className="w-100 border-0 px-0"
        style={{ fontSize: '.875rem' }}
        onClick={() =>
            removeItem(cartItemId).then(
                (res: { data: any }) => {
                    res?.data &&
                        dispatch(
                            setCartSize(res?.data.reduce((p: any, c: any) => p.amount + c, 0))
                        );
                    setItems(res?.data);
                    setInitialItems(res?.data);
                }
            )
        }
    >
        <i className="bi bi-trash3 pe-2 lh-1" />
        {t('remove')}
    </Button>
);
