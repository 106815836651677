import { useState, KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { Button, Col } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { SearchResult } from '../../redux/types/types';
import { getAutoComplete } from '../../services/autocompleteService';
import { getSearch } from '../../services/SearchService';
import { SortBy } from '../../typescript/enums/enums';

interface Props {
    labels: any;
    searchPageUrl: string;
}

export const HeaderSearch = ({ labels, searchPageUrl }: Props) => {
    const [queryString, setQueryString] = useState<string | undefined>(undefined);
    const [searchResultList, setSearchResultList] = useState<any>([]);
    const language = useAppSelector((state: any) => state.language.value);
    const dispatch = useAppDispatch();
    const inputRef = useRef<Typeahead>(null);

    const onKeyUpHandler = (event: KeyboardEvent<HTMLInputElement>) => {
        const target: HTMLInputElement | null = event.target as HTMLInputElement;
        const searchValue: string = encodeURIComponent(target?.value || "");
        if (event.key === 'Enter') {
            setQueryString(searchValue);
        }
    };

    const buttonSearch = () => {
        const value = encodeURIComponent(inputRef?.current?.getInput()?.value || "");
        setQueryString(value);
    }

    /**
     * Updates the search object from autocomplete
     * @param option
     * @param type
     */
    const autocompleteOnSelect = (option: string, type: string) => {
        const value = encodeURIComponent(option.length === 1 ? option[0] : '');
        setQueryString(value);
    };

    const getSuggestions = useCallback(
        (queryString: string, type: string): void => {
            dispatch(setLoader(true));
            getAutoComplete(queryString, type, language)
                .then((data: any) => {
                    setSearchResultList(data);
                    dispatch(setLoader(false));
                })
                .catch((err) => console.log(err));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (queryString !== undefined) {
            search();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryString]);

    const search = async () => {
        const isbnPattern = new RegExp("^(?=(?:[^0-9]*[0-9]){10}(?:(?:[^0-9]*[0-9]){3})?$)[\\d-]+$");
        const isIsbn = queryString && isbnPattern.test(queryString);
        const searchResult = isIsbn && await getSearch(language, {
            searchString: queryString.replaceAll('-', ''),
            coworker: '',
            title: '',
            series: '',
            marketSeries: '',
            subject: '',
            publisher: '',
            commodityGroup: '',
            language: '',
            fromReleaseDate: undefined,
            toReleaseDate: undefined,
            excludeDownloadable: false,
            pageSize: 0,
            sortBy: SortBy.relevance,
            filters: [],
            themas: [],
            similarToCode: undefined,
            typeAheadValues: undefined,
        })

        if ((searchResult as SearchResult)?.redirectUrl?.length) {
            window.location.assign((searchResult as SearchResult)?.redirectUrl);
            return;
        }

        if (searchPageUrl) {
            window.location.assign(`${searchPageUrl}?query=${queryString || ""}`);
        }
    };

    return (
        <Col xs={12} className="mt-3 header__search">
            <div className="row">
                <div className="col-12 col-md-11">
                    <div className="inner-icon">
                <i className="bi bi-search inner-icon__icon inner-icon--left__icon" />
                        <AsyncTypeahead
                            inputProps={{
                                className: 'inner-icon--left__input',
                                onKeyUp: onKeyUpHandler,
                                id: "main-search-textbox"
                            }}
                            filterBy={() => true}
                            id="main-search-type-ahead"
                            isLoading={false}
                            minLength={2}
                            onSearch={(query: string) => {
                                getSuggestions(query, 'TitleAndCoWorker');
                            }}
                            onChange={(selected: any) => {
                                autocompleteOnSelect(selected, 'TitleAndCoWorker');
                            }}
                            options={searchResultList}
                            placeholder={labels.search}
                            ref={inputRef}
                            emptyLabel={labels.noSuggestionsText}
                            promptText={ labels.searchingText }
                        />
                    </div>
            </div>
            <Button
                type="button"
                onClick={buttonSearch}
                variant="primary"
                className="col-12 col-md-1 ms-0 ms-md-0 mt-3 mt-md-0"
            >
                    {labels.search}
                </Button>
            </div>
        </Col>
    );
};
