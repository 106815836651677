import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ChangedProperty } from '../../redux/types/types';

interface baseProps {
    child: JSX.Element;
    locked: boolean;
    changed?: boolean;
    changedInformation?: ChangedProperty;
    showExtendedInfo: boolean,
    className?: string;
}

/**
 * Sets the field to either locked, changed or leaves it as is according to the ruleset and passet params.
 * @param param0
 * @returns New jsx.Element or the same child as passed in.
 */
export const LockedOrChanged = ({
    child,
    locked,
    changed,
    changedInformation,
    showExtendedInfo,
    className,
}: baseProps) => {
    const renderTooltip = (props: any) => (
        <Tooltip className="text-start" {...props}>
            <>
                <span className="d-block">
                    {changedInformation?.modified &&
                        new Date(changedInformation?.modified).toLocaleDateString(
                            'sv-SE'
                        )}{' '}
                    {changedInformation?.modifiedBy}
                </span>
                <span className="d-block">Nuvarande: {changedInformation?.value}</span>
                <span className="d-block">
                    Tidigare: {changedInformation?.oldValue}
                </span>
            </>
        </Tooltip>
    );
    const field =
        changed || locked ? (
            React.cloneElement(
                child,
                { className: changed ? `${child.props.className} changed` : child.props.className, disabled: locked ? true : null }
            )
        ) : (
            <></>
        );

    return (
        <>
            {locked && !changed && (
                <div className="input-group lock-icon">
                    {field}
                    <span className="input-group-text u-background-grey">
                        <i
                            className={`bi bi-lock-fill tiny u-text-grey-light u-cursor-pointer  ${className}`}
                        />
                    </span>
                </div>
            )}
            {changed && showExtendedInfo && (
                <>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 100, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <div className={`input-group ${locked ? "lock-icon" : ""}`}>
                            {field}
                            {locked && <span className="input-group-text u-background-grey">
                                <i
                                    className={`bi bi-lock-fill tiny u-text-grey-light u-cursor-pointer  ${className}`}
                                    />
                            </span>}
                        </div>
                    </OverlayTrigger>
                </>
            )}
            {changed && !showExtendedInfo && (<div className={`input-group ${locked ? "lock-icon" : ""}`}>
                {field}
                {locked && <span className="input-group-text u-background-grey">
                    <i
                        className={`bi bi-lock-fill tiny u-text-grey-light u-cursor-pointer  ${className}`}
                    />
                </span>}
            </div>) }
            {!locked && !changed && <>{child}</>}
        </>
    );
};
