export enum NotificationTypes {
    Success = "success",
    Warning = "warning",
    Error = "danger",
    Info = "info",
}

export enum StockStatus {
    Not_released = "Ej utkommen",
    In_stock = "Finns i lager",
    Tempo_out_stock = "Tillfälligt slut",
    Disabled = "Spärrad",
    Will_not_publish = "Utkommer ej",
    Order_item = "Beställningsvara",
    Definitly_over = "Definitivt slut",
}

export enum UnsellableStockStatuses {
    Blocked = 4,
    WillNotBePublished = 5,
    DefinitelyOutOfStock = 7,
}

export enum SortBy {
    relevance = 0,
    publishDateAsc = 1,
    publishDateDesc = 2,
    nameAÖ = 3,
    nameÖA = 4,
    seriesAndReadingOrder = 5,
    publisher = 6
}