import { useState, useEffect } from 'react';
import {
	Nav,
	Tab,
	Card,
	Button,
	Form,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
	FullCertificate,
	saveCertificate,
	calculatePallet,
	SelectDropdown,
} from '../../services/certificateService';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../typescript/enums/enums';
import { LockedFields } from './LockedFields';
import { t } from '../../services/translationService';
import { LockedOrChanged } from '../shared/LockedOrChanged';
import { getValue } from '@testing-library/user-event/dist/utils';

declare global {
	interface Bokinfo {
		certificate: FullCertificate;
	}
}

export const EditCertificate = () => {
    const { certificate, isAdmin } = window.bokinfo;

    const { handleSubmit, formState, register, setValue, getValues, reset }: any =
		useForm<FullCertificate>({
			mode: 'onBlur',
			defaultValues: window.bokinfo.certificate,
        });

    const [palletType, setPalletType] = useState(window.bokinfo.certificate.palletType || 10);
	const [lockedFields, setLockedFields] = useState<string[]>([]);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const { errors, isDirty } = formState;

    useEffect(() => {
        (async () => {
            if (palletType) {
                setValue('palletType', palletType);
                const palletFields = getValues([
                    'height',
                    'width',
                    'backwidth',
                    'weight',
                ]);

                await setPalletFields({
                    palletType,
                    height: palletFields[0],
                    width: palletFields[1],
                    backwidth: palletFields[2],
                    weight: palletFields[3],
                });
            }
        })();
        reset({}, { keepValues: true, keepIsValid: true });
    }, [palletType, setValue, getValues, certificate, isDirty]);

	useEffect(() => {
		lockFields(certificate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const dropdowns = window.bokinfo.certificate.dropDowns;

    const setPalletFields = async (cert: any) => {
		const pallet = await calculatePallet(
			cert.palletType,
			cert.height,
			cert.width,
			cert.backwidth,
			cert.weight
		);

		if (pallet) {
			setValue('palletItemsPerLayer', pallet.palletItemsPerLayer);
			setValue('palletLayers', pallet.palletLayers);
			setValue('palletTotalHeight', pallet.palletTotalHeight);
			setValue('palletTotalItems', pallet.palletTotalItems);
			setValue('palletTotalWeight', pallet.palletTotalWeight);
		}
	};

    const onSubmit = (values: any) => {
        setErrorMessages([]);
        saveCertificate(values).then((message: any) => {
            if (message.valid) {
                NotificationClass.createNotification({
                    type: NotificationTypes.Success,
                    message: 'Certifikatet uppdaterades',
                });
            }
            else {
                NotificationClass.createNotification({
                    type: NotificationTypes.Error,
                    message: "Certifikatet kunde inte sparas",
                });
            }
		});
	};

	const lockFields = (certificate: FullCertificate) => {
		if ([1, 4, 5].includes(certificate.status)) {
			// Lock new cert-field
            setLockedFields(LockedFields.newCert);
        } else if ([3].includes(certificate.status)) {
            // Lock fields not subject to change for status Approved
            setLockedFields(LockedFields.approved);
		} else if ([2].includes(certificate.status)) {
			// Lock all fields
			setLockedFields(LockedFields.allFields);
		} else {
			// Lock new cert-field
			setLockedFields(LockedFields.newCert);
		}
	};

	const dropdownValues = (dropdown: SelectDropdown) =>
		dropdown?.values &&
		Object.keys(dropdown.values).map((key: string) => (
			<option value={key}>{dropdown.values[key]}</option>
		));

	const shouldBeLocked = (
		listOfLockedFields: string[],
		fieldName: string
    ): boolean => listOfLockedFields.includes(fieldName);

  

	return (
        <Tab.Container defaultActiveKey="info">
			<Nav variant="tabs" role="tablist">
				<Nav.Item>
					<Nav.Link eventKey="info">{t('certifikatinfo')}</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link eventKey="properties">{t('egenskaper')}</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link eventKey="pallet">{t('pall')}</Nav.Link>
				</Nav.Item>
			</Nav>
			{Object.keys(errors)?.length > 0 && (
				<div className="my-4">
					<div className="validation__error__block">
						<span className="validation__error__block__description">
							<i className="bi bi-exclamation-circle me-3" />
							{t('cantSave')}
						</span>
						<ul className="validation__error__block__list">
							{Object.values(errors).map((e: any) => (
								<li>{e.message}</li>
							))}
						</ul>
					</div>
				</div>
            )}
			<Card className="mt-3 nopadding">
				<Card.Body className="pt-3">
					<Form id="certificateForm" onSubmit={handleSubmit(onSubmit)}>
						<Tab.Content>
							<Tab.Pane eventKey="info">
								<Container>
									<Row>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('titel')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'Title')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('title', {
																required: `${t('titel')} ${t('isObligatory')}`,
															})}
															isInvalid={!!errors.title}
														/>
													}
												/>
												{errors.title && (
													<Form.Control.Feedback type="invalid">
														{`${errors.title.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('glnSaljare')}
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'SellerGLN')}
                                                    showExtendedInfo={true}
													child={
														<Form.Select
															isInvalid={!!errors.sellerGln}
															{...register('sellerGln')}
														>
															{dropdowns?.glnSell &&
																dropdownValues(dropdowns.glnSell)}
														</Form.Select>
													}
												/>
												{errors.sellerGln && (
													<Form.Control.Feedback type="invalid">
														{`${errors.sellerGln.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('glnKopare')}
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'BuyerGLN')}
                                                    showExtendedInfo={true}
													child={
														<Form.Select
															isInvalid={!!errors.buyerGln}
															{...register('buyerGln')}
														>
															{dropdowns?.glnBuy &&
																dropdownValues(dropdowns.glnBuy)}
														</Form.Select>
													}
												/>
												{errors.buyerGln && (
													<Form.Control.Feedback type="invalid">
														{`${errors.buyerGln.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('status')}
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'Status')}
                                                    showExtendedInfo={true}
													child={
														<Form.Select {...register('status')}>
															{dropdowns?.status &&
																dropdownValues(dropdowns.status)}
														</Form.Select>
													}
												/>
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('gtin')}
												</Form.Label>
												<Form.Control
													readOnly
													plaintext
													{...register('gtin')}
												/>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('skapad')}
												</Form.Label>
												<Form.Control
													readOnly
													plaintext
													{...register('created')}
												/>
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('andrad')}
												</Form.Label>
												<Form.Control
													readOnly
													plaintext
													{...register('modified')}
												/>
											</Form.Group>
										</Col>
									</Row>
								</Container>
							</Tab.Pane>
							<Tab.Pane eventKey="properties">
								<Container>
									<Row>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('produktklasskategori')}
												</Form.Label>
												<LockedOrChanged
													locked={shouldBeLocked(
														lockedFields,
														'ProductClassCategory'
                                                    )}
                                                    showExtendedInfo={true}
													child={
														<Form.Select
                                                            {...register('productClassCategory')}                                                            
														>
															{dropdowns?.productClassCategory &&
																dropdownValues(dropdowns.productClassCategory)}
														</Form.Select>
													}
												/>
												{errors.productClassCategory && (
													<Form.Control.Feedback type="invalid">
														{`${errors.productClassCategory.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('varubenamning')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'Tradename')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('tradename', {
																required: `${t('varubenamning')} ${t(
																	'isObligatory'
                                                                )}`,
                                                                maxLength: { value: 35, message: t("tradenameLengthErrorMessage") },
															})}
															isInvalid={!!errors.tradename}
														/>
													}
												/>
												{errors.tradename && (
													<Form.Control.Feedback type="invalid">
														{`${errors.tradename.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('varumarke')}*
												</Form.Label>
												<LockedOrChanged
													locked={shouldBeLocked(
														lockedFields,
														'Trademark_Author'
                                                    )}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('trademark_Author', {
																required: `${t('varumarke')} ${t(
																	'isObligatoryAlt'
																)}`,
															})}
															isInvalid={!!errors.trademark_Author}
														/>
													}
												/>
												{errors.trademark_Author && (
													<Form.Control.Feedback type="invalid">
														{`${errors.trademark_Author.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('hyllkantKvittotext')}*
												</Form.Label>
												<LockedOrChanged
													locked={shouldBeLocked(
														lockedFields,
														'ShelfEdge_ReceiptText'
                                                    )}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('shelfEdge_ReceiptText', {
																required: `${t('hyllkantKvittotext')} ${t(
																	'isObligatory'
                                                                )}`,
                                                                maxLength: { value: 18, message: t("shelfTextLengthErrorMessage") },
															})}
															isInvalid={!!errors.shelfEdge_ReceiptText}
														/>
													}
												/>
												{errors.shelfEdge_ReceiptText && (
													<Form.Control.Feedback type="invalid">
														{`${errors.shelfEdge_ReceiptText.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('bredd')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'Width')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
                                                            {...register('width', {
                                                                required: `${t('bredd')} ${t('isObligatory')}`,
                                                                min: { value: 1, message: t('widthErrorMessage')},
															})}
															isInvalid={!!errors.width}
														/>
													}
												/>
												{errors.width && (
													<Form.Control.Feedback type="invalid">
														{`${errors.width.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('hojd')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'Height')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('height', {
                                                                required: `${t('hojd')} ${t('isObligatory')}`,
                                                                min: { value: 1, message: t('heightErrorMessage') },
															})}
															isInvalid={!!errors.height}
														/>
													}
												/>
												{errors.height && (
													<Form.Control.Feedback type="invalid">
														{`${errors.height.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('ryggbredd')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'BackWidth')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('backwidth', {
																required: `${t('ryggbredd')} ${t(
																	'isObligatory'
                                                                )}`,
                                                                min: { value: 1, message: t('backWidthErrorMessage') },
															})}
															isInvalid={!!errors.backwidth}
														/>
													}
												/>
												{errors.backwidth && (
													<Form.Control.Feedback type="invalid">
														{`${errors.backwidth.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('vikt')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'Weight')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('weight', {
                                                                required: `${t('vikt')} ${t('isObligatory')}`,
                                                                min: { value: 1, message: t('weightErrorMessage') },
															})}
															isInvalid={!!errors.weight}
														/>
													}
												/>
												{errors.weight && (
													<Form.Control.Feedback type="invalid">
														{`${errors.weight.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('moms')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'VAT')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('vat', {
																required: `${t('moms')} ${t('isObligatory')}`,
															})}
															isInvalid={!!errors.vat}
														/>
													}
												/>
												{errors.vat && (
													<Form.Control.Feedback type="invalid">
														{`${errors.vat.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('pris')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'Pris')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('price', {
																required: `${t('price')} ${t('isObligatory')}`,
															})}
															isInvalid={!!errors.price}
														/>
													}
												/>
												{errors.price && (
													<Form.Control.Feedback type="invalid">
														{`${errors.price.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
										</Col>
									</Row>
								</Container>
							</Tab.Pane>
							<Tab.Pane eventKey="pallet">
								<Container>
									<Row>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('palltyp')}
												</Form.Label>
												<LockedOrChanged
                                                    locked={!isAdmin && shouldBeLocked(lockedFields, 'PalletType')}
                                                    showExtendedInfo={true}
													child={
														<Form.Select
															onChange={(e) => setPalletType(e.target.value)}
															value={palletType}
														>
															{dropdowns?.palletType &&
																dropdownValues(dropdowns.palletType)}
														</Form.Select>
													}
												/>
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('gtin')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'GTINPallet')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('gtinPallet', {
																required: `${t('gtin')} ${t('isObligatory')}`,
															})}
															isInvalid={!!errors.gtin}
														/>
													}
												/>

												{errors.gtin && (
													<Form.Control.Feedback type="invalid">
														{`${errors.gtin.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('antalLager')}*
												</Form.Label>
												<LockedOrChanged
                                                    locked={shouldBeLocked(lockedFields, 'PalletLayers')}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('palletLayers', {
																required: `${t('antalLager')} ${t(
																	'isObligatory'
																)}`,
															})}
															isInvalid={!!errors.palletLayers}
														/>
													}
												/>
												{errors.palletLayers && (
													<Form.Control.Feedback type="invalid">
														{`${errors.palletLayers.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('antalPerLager')}*
												</Form.Label>
												<LockedOrChanged
													locked={shouldBeLocked(
														lockedFields,
														'PalletItemsPerLayer'
                                                    )}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('palletItemsPerLayer', {
																required: `${t('antalPerLager')} ${t(
																	'isObligatory'
																)}`,
															})}
															isInvalid={!!errors.palletItemsPerLayer}
														/>
													}
												/>
												{errors.palletItemsPerLayer && (
													<Form.Control.Feedback type="invalid">
														{`${errors.palletItemsPerLayer.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('totalVikt')}*
												</Form.Label>
												<LockedOrChanged
													locked={shouldBeLocked(
														lockedFields,
														'PalletTotalWeight'
                                                    )}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('palletTotalWeight', {
																required: `${t('totalVikt')} ${t(
																	'isObligatory'
																)}`,
															})}
															isInvalid={!!errors.palletTotalWeight}
														/>
													}
												/>
												{errors.palletTotalWeight && (
													<Form.Control.Feedback type="invalid">
														{`${errors.palletTotalWeight.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('totalHojd')}*
												</Form.Label>
												<LockedOrChanged
													locked={shouldBeLocked(
														lockedFields,
														'PalletTotalHeight'
                                                    )}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('palletTotalHeight', {
																required: `${t('totalHojd')} ${t(
																	'isObligatory'
																)}`,
															})}
															isInvalid={!!errors.palletTotalHeight}
														/>
													}
												/>
												{errors.palletTotalHeight && (
													<Form.Control.Feedback type="invalid">
														{`${errors.palletTotalHeight.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label className="mb-1 text-body">
													{t('totaltAntal')}*
												</Form.Label>
												<LockedOrChanged
													locked={shouldBeLocked(
														lockedFields,
														'PalletTotalItems'
                                                    )}
                                                    showExtendedInfo={true}
													child={
														<Form.Control
															placeholder={t('skrivEllips')}
															{...register('palletTotalItems', {
																required: `${t('totaltAntal')} ${t(
																	'isObligatory'
																)}`,
															})}
															isInvalid={!!errors.palletTotalItems}
														/>
													}
												/>
												{errors.palletTotalItems && (
													<Form.Control.Feedback type="invalid">
														{`${errors.palletTotalItems.message}`}
													</Form.Control.Feedback>
												)}
											</Form.Group>
										</Col>
									</Row>
								</Container>
							</Tab.Pane>
						</Tab.Content>
					</Form>
				</Card.Body>
				<Card.Footer className="text-muted">
					<a href="/sv-SE/certifikat" className="btn btn-secondary">
						{t('avbryt')}
					</a>
					<Button variant="primary" form="certificateForm" type="submit">
						{t('sparaCertifikat')}
					</Button>
				</Card.Footer>
			</Card>
		</Tab.Container>
	);
};
