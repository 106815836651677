import axios from 'axios';

const uri = `${document.location.pathname}sendErrorDescription`;

export const sendErrorDescription = async (catalogEntryCode: string, message: string): Promise<boolean> => {
    try {
        const { data } = await axios.post<boolean>(uri, { catalogEntryCode: catalogEntryCode, message: message });
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
};
