declare global {
	interface Bokinfo {
		language?: string;
		labels: { [key: string]: string };
	}
}

export const language = window.bokinfo.language;

export const t = (translation: string) =>
	window.bokinfo.labels && window.bokinfo.labels[translation]?.replace(/\\n/g, '\n');
