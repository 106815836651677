import './assets/scss/main.scss';
import 'bootstrap';
import './typescript/main.ts';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Header } from './components/header/header';
import { Router } from './components/content/Router';
import { BrowserRouter } from 'react-router-dom';

const init = () => {
	const navElement = document.getElementById('app-nav')!;
	const navRoot = createRoot(navElement);
	navRoot.render(
		<Provider store={store}>
			<Header />
		</Provider>
	);

	const contentElement = document.getElementById('app-content')!;
	if (contentElement !== null) {
		const contentRoot = createRoot(contentElement);
		contentRoot.render(
			<Provider store={store}>
				<BrowserRouter>
					<Router />
				</BrowserRouter>
			</Provider>
		);
	}
};

init();

