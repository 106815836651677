import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import { useFieldArray } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  getDragClass,
  getDragContainerClass,
  getItemStyle,
  getOptions,
  getOptionsString,
} from '../common';
import { InformationIcon } from '../../shared/InformationIcon';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { getChangedInformation, hasChanged, shouldBeLocked } from '../common';
import { LockedOrChanged } from '../../shared/LockedOrChanged';
import { t } from '../../../services/translationService';
import { setLoader } from '../../../redux/reducers/loaderSlice.reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getAutoComplete } from '../../../services/autocompleteService';
import { postCopyClassificationFields } from '../../../services/editBookService';
import NotificationClass from '../../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../../typescript/enums/enums';

interface Props {
  errors: any;
  register: any;
  control: any;
  getValues: any;
  setValue: any;
  blockRef: any;
  pageVariant: string;
  lockedFields: keyValue[];
  changedProps: ChangedProperty[];
}

export const SubjectClassification = ({
  errors,
  register,
  control,
  getValues,
  setValue,
  blockRef,
  pageVariant,
  lockedFields,
  changedProps,
}: Props) => {
  const [thema, setThema] = useState<any>([]);
  const [themaList, setThemaList] = useState<any>([]);
  const [katalogsignum, setKatalogsignum] = useState<string>('');
  const [copyArticleNumber, setCopyArticleNumber] = useState<string>('');
  const [explicitSearch, setExplicitSearch] = useState(false);
  const language = useAppSelector((state: any) => state.language.value);
  const dispatch = useAppDispatch();
  const showExtendedChangeInfo = pageVariant !== "publisher";
  const themaRef = useRef<Typeahead>(null);

  const {
    fields: themas,
    append: appendThemas,
    move: moveThemas,
    remove: removeThemas,
  } = useFieldArray({
    control,
    name: 'thema',
    rules: { minLength: 1, required: t('valideringThema') },
  });

  const {
    fields: katalogsignums,
    append: appendKatalogsignums,
    move: moveKatalogsignums,
    remove: removeKatalogsignums,
  } = useFieldArray({
    control,
    name: 'katalogsignum',
  });

  const updateKatalogsignum = (event: ChangeEvent<HTMLInputElement>): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setKatalogsignum(value);
  };

  const addThema = () => {
    appendThemas(thema);
    themaRef.current?.clear();
    setThema([]);
    setExplicitSearch(false);
  };

  const addKatalogsignum = () => {
    if (katalogsignum !== '') {
      appendKatalogsignums(katalogsignum);
      setKatalogsignum('');
    }
  };

  const onThemaDragEnd = (result: any) => {
    moveThemas(result.source.index, result.destination.index);
  };
  const onKatalogsignumsDragEnd = (result: any) => {
    moveKatalogsignums(result.source.index, result.destination.index);
  };

  const getSuggestions = useCallback(
    (queryString: string, type: string): void => {
      dispatch(setLoader(true));
      setExplicitSearch(queryString.length > 0);
      getAutoComplete(queryString, type, language)
        .then((data: any) => {
          setThemaList(data);
          dispatch(setLoader(false));
        })
        .catch((err) => console.error(err));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateCopyArticleNumber = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setCopyArticleNumber(value);
  };

  const setCopyClassificationResponse = (responseData: any) => {
    responseData.forEach((keyValueObject: keyValue | { key: string, value: { key: string, value: string } }) => {
      if ((keyValueObject.value as { key: string, value: string })?.value) {
        setValue(keyValueObject.key.toLocaleLowerCase(), (keyValueObject.value as { key: string, value: string })?.value);
      } else {
        setValue(keyValueObject.key.toLocaleLowerCase(), keyValueObject.value);
      }
    });
  };

  const copyInformation = () => {
    dispatch(setLoader(true));
    postCopyClassificationFields(language, copyArticleNumber)
      .then((responseData: any) => {
        setCopyClassificationResponse(responseData);
        dispatch(setLoader(false));
        NotificationClass.createNotification({
          type: NotificationTypes.Success,
          message: t('infoHarKopierats'),
        });
      })
      .catch((error: any) => {
        dispatch(setLoader(false));
        NotificationClass.createNotification({
          type: NotificationTypes.Error,
          message: error as string,
        });
      });
  };

  return (
    <>
      <h3 ref={blockRef}>{t('amnesklassificering')}</h3>
      <Col xs={12}>
        <hr className="u-text-grey" />
      </Col>
     <Card className="nopadding my-4 ">
        <Card.Body className="p-3">
          <Row>
            <Col xs={12} className="mb-3">
              <div className="d-flex flex-wrap align-items-end flex-wrap w-100">
                <Form.Group as={Col} className="col me-3">
                  <Form.Label className="mb-1 text-body">
                    {t('thema')}*
                    <InformationIcon
                      text={t('hjalpThema')}
                      inline
                      className="ps-1"
                    />
                  </Form.Label>
                  {!shouldBeLocked(lockedFields, 'thema') && (
                    <AsyncTypeahead
                      id="thema"
                      labelKey={(option: any) =>
                        `${option.key} ${option.value}`
                      }
                      isLoading={false}
                      minLength={0}
                      options={themaList}
                      placeholder={t('skrivEllips')}
                      ref={themaRef}
                      inputProps={{
                        className: `${errors.thema?.root ? 'is-invalid' : ''}`,
                      }}
                      filterBy={() => true}
                      onSearch={(query: string) => {
                        getSuggestions(query, 'thema');
                      }}
                      onChange={(selected: any) => {
                        setThema(selected);
                      }}
                      onFocus={() => !explicitSearch && getSuggestions("", 'thema')}
                      paginationText={t('showMore')}
                    />
                  )}
                </Form.Group>
                {!shouldBeLocked(lockedFields, 'thema') && (
                  <Form.Group as={Col} className="col-auto">
                    <Button
                      className="primary"
                      type="button"
                      onClick={addThema}
                    >
                      <i className="bi bi-plus"></i>
                    </Button>
                  </Form.Group>
                )}
                <Col sm={12}>
                  {errors.thema?.root && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {`${errors.thema.root.message}`}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </div>
            </Col>
            <DragDropContext onDragEnd={onThemaDragEnd}>
              <Droppable droppableId="droppableThemas">
                {(provided: any, snapshot: any) => (
                  <Col
                    xs={12}
                    className={`mb-3 ${getDragContainerClass(
                      snapshot.isDraggingOver
                    )}`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {themas.map((row: any, index: number) => {
                      return (
                        <Draggable
                          key={row.id}
                          draggableId={row.id}
                          index={index}
                          isDragDisabled={shouldBeLocked(
                            lockedFields,
                            'thema'
                          )}
                        >
                          {(provided: any, snapshot: any) => (
                            <Row
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              key={row.id}
                              className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                } mt-2 p-1 ${getDragClass(
                                  snapshot.isDragging
                                )}`}
                            >
                              <Col
                                xs={11}
                                className="d-flex align-items-center"
                              >
                                {getValues(`thema.${index}.key`)}{' '}
                                {getValues(`thema.${index}.value`)}
                              </Col>
                              <Col
                                xs={1}
                                className="d-flex align-items-center"
                              >
                                {!shouldBeLocked(lockedFields, 'thema') && (
                                  <i
                                    className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                    onClick={() => removeThemas(index)}
                                  />
                                )}
                              </Col>
                            </Row>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Col>
                )}
              </Droppable>
            </DragDropContext>

            {themas.length === 0 && (
              <p className="text-muted">{t('ingaThemanTillagda')}</p>
            )}
            <Col xs={12}>
              <hr className="u-text-grey" />
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('aldersgrupp')}
                </Form.Label>
                <LockedOrChanged
                  locked={shouldBeLocked(lockedFields, 'aldersgrupp')}
                  changed={hasChanged(changedProps, 'aldersgrupp')}
                  showExtendedInfo={showExtendedChangeInfo}
                  changedInformation={getChangedInformation(
                    changedProps,
                    'aldersgrupp'
                  )}
                  child={
                    <Form.Control
                      as={'select'}
                      type="select"
                      className="form-select"
                      placeholder={t('valjEllips')}
                      {...register('aldersgrupp')}
                      isInvalid={!!errors.aldersgrupp}
                    >
                      {getOptionsString('readingAges')}
                    </Form.Control>
                  } />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('forlagetsNyckelord')}
                  <InformationIcon
                    text={t('hjalpNyckelord')}
                    inline
                    className="ps-1"
                  />
                </Form.Label>
                <Form.Control
                  placeholder={t('skrivEllips')}
                  {...register('nyckelord', {})}
                />
              </Form.Group>
            </Col>
            {pageVariant !== 'publisher' && (
              <Col xs={pageVariant !== 'quick' ? 12 : 6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-body">
                    {t('varugrupp')}
                  </Form.Label>
                  <LockedOrChanged
                    locked={shouldBeLocked(lockedFields, 'varugrupp')}
                    changed={hasChanged(changedProps, 'varugrupp')}
                    showExtendedInfo={showExtendedChangeInfo}
                    changedInformation={getChangedInformation(
                      changedProps,
                      'varugrupp'
                    )}
                    child={
                      <Form.Control
                        as={'select'}
                        type="select"
                        className="form-select"
                        placeholder={t('valjEllips')}
                        {...register('varugrupp', {})}
                      >
                        {getOptions('commodityGroups')}
                      </Form.Control>
                    }
                  />
                </Form.Group>
              </Col>
            )}
            {pageVariant !== 'publisher' && (
              <Col xs={12}>
                <div className="d-flex align-items-end w-100">
                  <Form.Group as={Col} className="mb-3 col me-3">
                    <Form.Label className="mb-1 text-body">
                      {t('katalogsignum')}
                    </Form.Label>
                    {!shouldBeLocked(lockedFields, 'katalogsignum') && (
                      <Form.Control
                        placeholder={t('skrivEllips')}
                        value={katalogsignum}
                        onChange={updateKatalogsignum}
                      />
                    )}
                  </Form.Group>
                  {!shouldBeLocked(lockedFields, 'katalogsignum') && (
                    <Form.Group as={Col} className="mb-3 col-auto">
                      <Button
                        className="primary"
                        type="button"
                        onClick={addKatalogsignum}
                      >
                        <i className="bi bi-plus"></i>
                      </Button>
                    </Form.Group>
                  )}
                </div>
              </Col>
            )}
            {pageVariant !== 'publisher' && (
              <DragDropContext onDragEnd={onKatalogsignumsDragEnd}>
                <Droppable droppableId="droppableKatalogsignums">
                  {(provided: any, snapshot: any) => (
                    <Col
                      xs={12}
                      className={`mb-3 ${getDragContainerClass(
                        snapshot.isDraggingOver
                      )}`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {katalogsignums.map((row: any, index: number) => {
                        return (
                          <Draggable
                            key={row.id}
                            draggableId={row.id}
                            index={index}
                            isDragDisabled={shouldBeLocked(
                              lockedFields,
                              'katalogsignum'
                            )}
                          >
                            {(provided: any, snapshot: any) => (
                              <Row
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                key={row.id}
                                className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                  } mt-2 p-1 ${getDragClass(
                                    snapshot.isDragging
                                  )}`}
                              >
                                <Col
                                  xs={11}
                                  className="d-flex align-items-center"
                                >
                                  {getValues(`katalogsignum.${index}`)}
                                </Col>
                                <Col
                                  xs={1}
                                  className="d-flex align-items-center"
                                >
                                  {!shouldBeLocked(
                                    lockedFields,
                                    'katalogsignum'
                                  ) && (
                                      <i
                                        className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                        onClick={() =>
                                          removeKatalogsignums(index)
                                        }
                                      />
                                    )}
                                </Col>
                              </Row>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Col>
                  )}
                </Droppable>
              </DragDropContext>

            )}
            {katalogsignums.length === 0 && (
              <p className="text-muted">{t('ingaKatalogsignumTillagda')}</p>
            )}
            {pageVariant !== 'publisher' && (
              <Col xs={12}>
                <hr className="u-text-grey" />
              </Col>
            )}
            {pageVariant !== 'quick' && pageVariant !== 'publisher' && (
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-body">
                    {t('dewey')}
                  </Form.Label>
                  <LockedOrChanged
                    locked={shouldBeLocked(lockedFields, 'dewey')}
                    changed={hasChanged(changedProps, 'dewey')}
                    showExtendedInfo={showExtendedChangeInfo}
                    changedInformation={getChangedInformation(
                      changedProps,
                      'dewey'
                    )}
                    child={
                      <Form.Control
                        placeholder={t('skrivEllips')}
                        {...register('dewey', {})}
                      />
                    }
                  />
                </Form.Group>
              </Col>
            )}
            {pageVariant !== 'large' && pageVariant !== 'publisher' && (
              <Col xs={12} md={6}>
                <div className="d-flex align-items-end w-100">
                  <Form.Group as={Col} className="mb-3 col me-3">
                    <Form.Label className="mb-1 text-body">
                      {t('kopieraKlassificering')}
                    </Form.Label>
                    <Form.Control
                      className="form-inline"
                      value={copyArticleNumber}
                      onChange={updateCopyArticleNumber}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3 col-auto">
                    <Button
                      type="button"
                      className="primary"
                      onClick={copyInformation}
                    >
                      {t('kopiera')}
                    </Button>
                  </Form.Group>
                </div>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
