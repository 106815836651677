import { useEffect, useState } from 'react';
import { Badge, Button, Col, Collapse, Row } from 'react-bootstrap';
import { Filter, FilterGroup } from '../../redux/types/types';
import { t } from '../../services/translationService';

interface Props {
	filterGroups: FilterGroup[];
	returnFilters: (filters: { key: string; value: string }[]) => void;
	toggleExcludeDownloadableContent: (exclude: boolean) => void;
	excludeDownloadableContent: boolean;
	css?: string;
}

export const SearchFilterComponent = ({
	filterGroups,
	returnFilters,
	toggleExcludeDownloadableContent,
	excludeDownloadableContent,
	css,
}: Props) => {
	const [internalCss, setInternalCss] = useState<string>('');
	const [filters, setFilters] = useState<FilterGroup[]>([]);
	const [activeFilters, setActiveFilters] = useState<
		{ key: string; value: string }[]
	>([]);
	const [expandedFacets, setExpandedFacets] = useState<boolean[]>([]);

	useEffect(() => {
		setFilters(filterGroups);
		if (css !== undefined) {
			setInternalCss(css);
		}
	}, [filterGroups, css]);

	useEffect(() => {
		filterActiveFilters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const filterActiveFilters = () => {
		var newActiveFilters = [] as { key: string; value: string }[];
		filters.forEach((filterGroup: FilterGroup) => {
			filterGroup.facets.map((facet: Filter) => {
				if (facet.selected) {
					newActiveFilters.push({
						key: filterGroup.fieldName,
						value: facet.key,
					});
				}
			});
		});

		setActiveFilters(newActiveFilters);
	};

	/**
	 *
	 * @param fieldName
	 * @param filter
	 */
	const setActiveFilter = (fieldName: string, filter: Filter) => {
		let currentFilters = activeFilters;
		if (filter.selected === true && filters && currentFilters.length > 0) {
			const id: number = currentFilters.findIndex(
				(x) => x.key === fieldName && x.value === filter.key
			);
			if (id > -1) {
				currentFilters.splice(id, 1);
			}
		} else {
			filter.selected = true;
			currentFilters.push({ key: fieldName, value: filter.key });
		}
		setActiveFilters(currentFilters);
		returnFilters(currentFilters);
	};

	const clearActiveFilters = () => {
		setActiveFilters([]);
		returnFilters([]);
	};

	const getFilterGroupActionText = (filterGroup: FilterGroup) => {
		if (filterGroup.facets.findIndex((x) => x.selected !== true) > -1) {
			return (
				<>
					<i className="bi bi-check2 tiny u-text-grey-dark"></i>{' '}
					{t('selectAll')}
				</>
			);
		} else {
			return (
				<>
					<i className="bi bi-x tiny u-text-grey-dark"></i> {t('unSelectAll')}
				</>
			);
		}
	};

	const setFilterGroupSelected = (filterGroup: FilterGroup) => {
		let active = false;
		let currentFilters = activeFilters;
		if (filterGroup.facets.findIndex((x) => x.selected !== true) > -1) {
			active = true;
		}

		filterGroup.facets.map((filter: Filter, index: number) => {
			const id: number = currentFilters.findIndex((x) => x.key === filter.key);
			if (active) {
				if (id === -1) {
					filter.selected = true;
					currentFilters.push({
						key: filterGroup.fieldName,
						value: filter.key,
					});
				}
			} else {
				currentFilters.splice(id, 1);
			}
		});
		setActiveFilters(currentFilters);
		returnFilters(currentFilters);
	};

    const excludeDownloadable = () => {
        toggleExcludeDownloadableContent(!excludeDownloadableContent)
	};

	const modifyExpandedFacets = (index: number, expanded: boolean) => {
		setExpandedFacets((prevState) => ({ ...prevState, [index]: expanded }));
	};

    const formatFacets = (fieldName: string, facets: Filter[], index: number) => {
		return (
			<>
				{facets.slice(0, 5).map((filter: Filter, index: number) => {
					return (
						<Col
							sm={12}
							key={index.toString()}
							className={`d-flex justify-content-between ${
								index !== facets.length - 1 ? 'mb-2' : ''
							}`}
						>
							<div className="search__filter__group__list__desc">
								<label className="u-cursor-pointer">
									<input
										type="checkbox"
										className="me-1"
										checked={filter.selected}
										onChange={() => setActiveFilter(fieldName, filter)}
									/>
									{filter.description}
								</label>
							</div>
							{/* <div className="badge rounded-pill">{filter.count}</div> */}
							<div>
								<Badge pill className="filter-pill">
									{filter.count}
								</Badge>
							</div>
						</Col>
					);
				})}
				{facets.length > 5 && (
					<Col>
						<Collapse in={expandedFacets[index]}>
							<Row>
								{facets.slice(5).map((filter: Filter, index: number) => {
									return (
										<Col
											sm={12}
											key={index.toString()}
											className={`d-flex justify-content-between ${
												index !== facets.length - 1 ? 'mb-2' : ''
											}`}
										>
											<div className="search__filter__group__list__desc">
												<label className="u-cursor-pointer">
													<input
														type="checkbox"
														className="me-1"
														checked={filter.selected}
														onChange={() => setActiveFilter(fieldName, filter)}
													/>
													{filter.description}
												</label>
											</div>
											<div>
												<Badge pill className="filter-pill">
													{filter.count}
												</Badge>
											</div>
										</Col>
									);
								})}
							</Row>
						</Collapse>
						{expandedFacets[index] ? (
							<button
								className="btn btn-link no-visited"
								onClick={() =>
									modifyExpandedFacets(index, !expandedFacets[index])
								}
							>
								{t('showLess')}
							</button>
						) : (
							<button
								className="btn btn-link btn-link-small no-visited text-decoration-none"
								onClick={() =>
									modifyExpandedFacets(index, !expandedFacets[index])
								}
							>
								{t('showMore')}
							</button>
						)}
					</Col>
				)}
			</>
		);
	};

	return (
		<>
			<Row className={internalCss}>
				<Col>
					<Row>
						<Col>
							<div className={`search__filter__group `}>
								<h4 className="h4">{t('filter')}</h4>
								<Button 
									className="w-100 mt-3" 
									color="primary"
									onClick={() => excludeDownloadable()}
								>
									{excludeDownloadableContent ? (
										t('showDownloadableBook')
									) : (
										t('hideDownloadableBook')
									)}
								</Button>
								<Button
									className="w-100 mt-3"
									variant="secondary"
									onClick={clearActiveFilters}
								>
									{t('clearFilter')}	
								</Button>
							</div>
						</Col>
					</Row>
					{filters.length > 0 &&
						filters.map((filterGroup: FilterGroup, index: number) => {
							return (
								<Row key={index.toString()}>
									<Col>
										<div
											className={`search__filter__group ${
												index === filters.length - 1
													? 'search__filter__group--no-border'
													: ''
											} `}
										>
											<Row>
												<Col>
													<span className="d-inline-block">
														{filterGroup.description}
													</span>
													<button
														className="d-sm-block d-md-inline-block search__filter__group__link btn btn-link"
														onClick={() => setFilterGroupSelected(filterGroup)}
													>
														{getFilterGroupActionText(filterGroup)}
													</button>
												</Col>
											</Row>
											<Row className="search__filter__group__list">
												{formatFacets(
													filterGroup.fieldName,
													filterGroup.facets,
													index
												)}
											</Row>
										</div>
									</Col>
								</Row>
							);
						})}
				</Col>
			</Row>
		</>
	);
};
