import React, { useEffect } from 'react';
import { useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { Table, TableItem } from '../shared/Table';
import axios from 'axios';
import { IUserRestCallResult } from '../shared/Interfaces/ApiCallInterfaces';
import { Button, Col, Row } from 'react-bootstrap';
import { t, language } from '../../services/translationService';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { useSearchParams } from 'react-router-dom';

interface IOrganizationQueryModel {
	query: string;
	exact: boolean;
	marketStartPageId: number;
}

interface IOrganizationViewModel {
	name: string;
	shortName: string;
	address: IAddressViewModel;
	returnAddress: IAddressViewModel;
	contact: IContactInfoViewModel;
	distributor: string;
	orgNumber: string;
	customerId: number;
	salesOrg: ISalesOrganizationViewModel;
}

interface IAddressViewModel {
	name: string;
	line1: string;
	line2: string;
	line3: string;
	postalCode: string;
	city: string;
}

interface IContactInfoViewModel {
	daytimePhoneNumber: string;
	email: string;
	webPage: string;
}

interface ISalesOrganizationViewModel {
	name: string;
	daytimePhoneNumber: string;
}

interface ISearchPublisherDistibutorResult extends IUserRestCallResult {
	errorList: string[] | undefined;
	organizationList: IOrganizationViewModel[] | undefined;
}

export const SearchPublisherDistributorComponent = () => {
    const [queryParams] = useSearchParams();
	const [searchText, setSearchText] = useState<string>(queryParams.get('id') ?? '');
	const [searchHeader, setSearchHeader] = useState<string>('');
	const [searchHits, setSearchHits] = useState<TableItem[]>([]);
	const [initialState, setInitialState] = useState<boolean>(true);
	const dispatch = useAppDispatch();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.currentTarget.value);
	};

	useEffect(() => {
        if (searchText != '') {
			search();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	const buildSearchHits = (
		organizationList: IOrganizationViewModel[]
	): TableItem[] => {
		let table: TableItem[] = organizationList.map(
			(org) =>
				({
					columns: [
						org.name,
						addressSection(org),
						contactInfo(org.contact),
						org.distributor,
						org.orgNumber,
						org.customerId,
						org.shortName,
					],
				} as TableItem)
		);
		return table;
	};

	const doSearch = (event: any) => {
        event.preventDefault();
        search();
	};
	
	const search = async () => {
		dispatch(setLoader(true));
		// Do search
		let searchQuery = {} as IOrganizationQueryModel;

		searchQuery.exact = false;
		searchQuery.marketStartPageId = 0;
		searchQuery.query = searchText;

		let search = await searchOrg(searchQuery, 'Fel');

		if (search.success && (search.organizationList?.length ?? 0) > 0) {
			setSearchHits(
				buildSearchHits(search.organizationList as IOrganizationViewModel[])
			);
		} else {
			setSearchHits({} as TableItem[]);
		}

		setSearchHeader(searchText);
        setInitialState(false);
        dispatch(setLoader(false));
	}

	const searchOrg = async (
		dataObject: IOrganizationQueryModel,
		genericErrorMessage: string
	): Promise<ISearchPublisherDistibutorResult> => {
		try {
			const { data } = await axios.post<ISearchPublisherDistibutorResult>(
				`/${
					language ?? 'sv-SE'
				}/api/searchpublisherdistributor/searchorganization`,
				dataObject,
				{
					headers: {
						Accept: 'application/json',
					},
				}
			);

			return data;
		} catch (error) {
			if (axios.isAxiosError(error)) {
				console.error('error message: ', error.message);
				let errorModel = {} as ISearchPublisherDistibutorResult;

				return errorModel;
			} else {
				console.error('unexpected error: ', error);
				let errorModel = {} as ISearchPublisherDistibutorResult;

				return errorModel;
			}
		}
	};

	const resultList = (listItems: TableItem[]) => {
		return (
			<div className="container u-border shadow-sm g-0">
				<div className="row small">
					{listItems && (
						<Table
							columns={[
								{
									title: t('publisherdistributor'),
									width: '20%',
								},
								{ title: t('address'), width: '20%' },
								{ title: t('contact'), width: '20%' },
								{ title: t('distributor'), width: '10%' },
								{ title: t('orgnumber'), width: '10%' },
								{ title: t('brid'), width: '10%' },
								{ title: t('shortname'), width: '10%' },
							]}
							items={listItems}
							smallTableHeadFont={true}
							firstColumnBold={false}
							striped
							verticalAlignTop={true}
						/>
					)}
				</div>
			</div>
		);
	};

	const addressSection = (organization: IOrganizationViewModel) => {
		if (!organization?.address) {
			return null;
		}
		return (
			<>
				{addressPart(organization.address)}
				{organization.returnAddress && (
					<>{addressPart(organization.returnAddress, t('returnaddess'))}</>
				)}
			</>
		);
	};

	const addressPart = (address: IAddressViewModel, headline?: string) => {
		if (!address) {
			return null;
		}
		return (
			<span className="d-block">
				{headline && <b className="d-block pt-2">{headline}</b>}
				{address?.name && <span className="d-block">{address.name}</span>}
				{address?.line1 && (
					<span className="d-block">
						{address.line1}
						<br />
					</span>
				)}
				{address?.line2 && (
					<span className="d-block">
						{address.line2}
						<br />
					</span>
				)}
				{address?.line3 && (
					<span className="d-block">
						{address.line2}
						<br />
					</span>
				)}
				{address?.postalCode && address?.city && (
					<span className="d-block">
						{address.postalCode}&nbsp;&nbsp;{address.city}
					</span>
				)}
			</span>
		);
	};

	const contactInfo = (contact: IContactInfoViewModel) => {
		if (!contact) {
			return null;
		}
		return (
			<>
				{contact?.daytimePhoneNumber && (
					<span className="d-block">{contact.daytimePhoneNumber}</span>
				)}
				{contact?.email && (
					<span className="d-block">
						<a href={`mailto:${contact.email}`}>{contact.email}</a>
					</span>
				)}
				{contact?.webPage && (
					<span className="d-block">
						<a href={contact.webPage}>{contact.webPage}</a>
					</span>
				)}
			</>
		);
	};

	const salesOrg = (salesOrganization: ISalesOrganizationViewModel) => {
		if (!salesOrganization) {
			return null;
		}
		return (
			<>
				{salesOrganization?.name && (
					<span className="d-block">{salesOrganization.name}</span>
				)}
				{salesOrganization?.daytimePhoneNumber && (
					<span className="d-block">
						Telefon: {salesOrganization.daytimePhoneNumber}
					</span>
				)}
			</>
		);
	};

	return (
		<>
			<div className="col-12">
				<div className="container card p-3 mb-4 mt-4">
					<form onSubmit={doSearch}>
						<Row>
							<Col md={12} lg={12}>
								<div className="inner-icon">
									<i className="bi bi-search inner-icon__icon inner-icon--left__icon"></i>
									<input
										type="text"
										id="searchString"
										className="inner-icon--left__input col-12 col-md-9"
										value={searchText ?? ''}
										onChange={handleChange}
										placeholder={t('searchplaceholder')}
									/>
								</div>
								<Button
									type="submit"
									variant="primary"
									className="col-12 col-md-2 ms-0 ms-md-3 mt-3 mt-md-0"
								>
									{t('searchbuttontext')}
								</Button>
							</Col>
						</Row>
					</form>
				</div>
				{!initialState && (
					//TODO: Hör lösa med translations?
					<h5 className="mb-4">
						<b>
							Din sökning på "{searchHeader}" gav {searchHits?.length ?? 0}{' '}
							{searchHits?.length === 1 ? <>träff</> : <>träffar</>}
						</b>
					</h5>
				)}
				{(searchHits?.length ?? 0) > 0 && resultList(searchHits)}
			</div>
		</>
	);
};
