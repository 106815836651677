import { configureStore } from '@reduxjs/toolkit';
import languageSlice from './reducers/languageSlice.reducer';
import loaderSlice from './reducers/loaderSlice.reducer';
import notificationsSlice from './reducers/notificationsSlice.reducer';
import cartSlice from './reducers/cartSlice.reducer';

export const store = configureStore({
	reducer: {
		language: languageSlice,
		notifications: notificationsSlice,
		loader: loaderSlice,
		cart: cartSlice,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
