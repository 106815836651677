import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';
import { t } from '../../../services/translationService';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import {
  getBooleanOptions,
  getChangedInformation,
  getDragClass,
  getDragContainerClass,
  getItemStyle,
  getOptions,
  getFilteredOptions,
  getOptionsLabel,
  hasChanged,
  shouldBeLocked,
} from '../common';
import { InformationIcon } from '../../shared/InformationIcon';


interface Props {
  errors?: any;
  register?: any;
  blockRef?: any;
  setValue?: any;
  getValues?: any;
  pageVariant?: string;
  lockedFields?: keyValue[];
  changedProps?: ChangedProperty[];
  watch?: any;
  control?: any;
  trigger?: any;
  visible?: boolean
}

export const Epub = ({
  errors,
  register,
  blockRef,
  setValue,
  getValues,
  control,
  trigger,
  visible
}: Props) => {

  // State variables
  const [tillganglighetstyp, setTillganglighetstyp] = useState('');
  const [epubsAttributState, setEpubsAttributState] = useState('');
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);

  // Field arrays
  const {
    fields: epubTillganglighetstyp,
    append: appendEpubTillganglighetstyp,
    move: moveEpubTillganglighetstyp,
    remove: removeEpubTillganglighetstyp,
  } = useFieldArray({
    control,
    name: 'epubTillganglighetsTyp',
  });

  const {
    fields: epubsAttribut,
    append: appendEpubsAttribut,
    remove: removeEpubsAttribut,
  } = useFieldArray({
    control,
    name: 'epubsAttribut',
  });

  const {
    fields: epubAnvandningsomrade,
    append: appendEpubAnvandningsomrade,
    update: updateEpubAnvandningsomrade,
    remove: removeEpubAnvandningsomrade,
  } = useFieldArray({
    control,
    name: 'epubAnvandningsomrade'
  });

  useEffect(() => {
    if (!visible) {
      // clear values
      setValue("epubversion", "");
      setValue("epubTillganglighetsbeskrivning", "");

      // clear value lists
      removeEpubAnvandningsomrade();
      removeEpubsAttribut();
      removeEpubTillganglighetstyp()
    }

  }, [visible, setValue, removeEpubAnvandningsomrade, removeEpubsAttribut, removeEpubTillganglighetstyp])

  const addEpubTillganglighetstyp = () => {
    if (tillganglighetstyp) {
      appendEpubTillganglighetstyp(tillganglighetstyp);
      setTillganglighetstyp('');
    }
  };

  const updateEpubTillganglighetstyp = (event: ChangeEvent<HTMLInputElement>): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setTillganglighetstyp(value);
  };

  const onEpubTillganglighetstypDragEnd = (result: any) => {
    moveEpubTillganglighetstyp(result.source.index, result.destination.index);
  };

  const addEpubsAttribut = () => {
    if (epubsAttributState) {
      appendEpubsAttribut(epubsAttributState);
      setEpubsAttributState('');
    }
  };

  const updateEpubsAttribut = (event: ChangeEvent<HTMLInputElement>): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setEpubsAttributState(value);
  };

  const saveEpubAnvandningsomrade = () =>
    trigger(
      `epubAnvandningsomrade.${currentIndex !== undefined ? currentIndex : epubAnvandningsomrade.length - 1
      }`
    ).then((validResult: boolean) => {
      if (validResult) {

        const last = currentIndex === undefined
          ? getValues(`epubAnvandningsomrade.${epubAnvandningsomrade.length - 1}`)
          : getValues(`epubAnvandningsomrade.${currentIndex}`);

        currentIndex === undefined && removeEpubAnvandningsomrade(epubAnvandningsomrade.length - 1);
        currentIndex !== undefined
          ? updateEpubAnvandningsomrade(
            currentIndex,
            {
              ...last
            }
          )
          : appendEpubAnvandningsomrade({
            ...last
          });

        setShowModal(false);
      }
    });

  const displayModal = (index?: number) => {
    index === undefined ? appendEpubAnvandningsomrade({}) : setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    currentIndex === undefined && removeEpubAnvandningsomrade(epubAnvandningsomrade.length - 1);
  };

  return visible ? (
    <>
      <h3 ref={blockRef}>{t('epub')}</h3>
      <Col xs={12}>
        <hr className="u-text-grey" />
      </Col>
      <Card className="nopadding my-4">
        <Card.Body className="p-3">
          <Row>
            <Col xs={12}>
              {/*Version*/}
              <Form.Group className="mb-3">
                {/*Version*/}
                <Form.Label className="mb-1 text-body">
                  {t('epubversion')}
                </Form.Label>
                <Form.Control
                  as={'select'}
                  type="select"
                  className="form-select"
                  placeholder={t('valjEllips')}
                  {...register('epubversion')}
                  isInvalid={!!errors.epubversion}
                >
                  {getOptions('epubVersions')}
                </Form.Control>
              </Form.Group>
              {/*Tillgänglighetsbeskrivning*/}
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('epubTillganglighetsBeskrivning')}
                </Form.Label>
                <Form.Control
                  placeholder={t('skrivEllips')}
                  {...register('epubTillganglighetsbeskrivning')}
                  isInvalid={!!errors.epubTillganglighetsbeskrivning}
                />
              </Form.Group>
              {/*Tillgänglighetstyp*/}
              <div className="d-flex align-items-end w-100">
                <Form.Group as={Col} className="mb-3 col me-3">
                  <Form.Label className="mb-1 text-body">
                    {t("epubTillganglighetsTyp")}
                    <InformationIcon
                      text={t('hjalpEpubTillganglighetsTyp')}
                      inline
                      className="ps-1"
                    />
                  </Form.Label>
                  <Form.Control
                    as={'select'}
                    type="select"
                    className="form-select"
                    placeholder={t('valjEllips')}
                    value={tillganglighetstyp}
                    onChange={updateEpubTillganglighetstyp}
                  >
                    {getFilteredOptions('epubAccessibilityTypes', getValues('epubTillganglighetsTyp'))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-auto">
                  <Button
                    className="primary"
                    type="button"
                    onClick={addEpubTillganglighetstyp}
                  >
                    <i className="bi bi-plus"></i>
                  </Button>
                </Form.Group>
              </div>
              <DragDropContext onDragEnd={onEpubTillganglighetstypDragEnd}>
                <Droppable droppableId="droppableepubtillganglighetstyp">
                  {(provided: any, snapshot: any) => (
                    <Col
                      xs={12}
                      className={`mb-3 ${getDragContainerClass(
                        snapshot.isDraggingOver
                      )}`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {epubTillganglighetstyp.map((row: any, index: number) => {
                        return (
                          <Draggable
                            key={row.id}
                            draggableId={row.id}
                            index={index}
                          >
                            {(provided: any, snapshot: any) => (
                              <Row
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                key={row.id}
                                className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                  } mt-2 p-1 ${getDragClass(snapshot.isDragging)}`}
                              >
                                <Col
                                  xs={11}
                                  className="d-flex align-items-center"
                                >
                                  {getOptionsLabel("epubAccessibilityTypes", getValues(`epubTillganglighetsTyp.${index}`))}
                                </Col>
                                <Col xs={1} className="d-flex align-items-center">
                                  <i
                                    className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                    onClick={() => removeEpubTillganglighetstyp(index)}
                                  />
                                </Col>
                              </Row>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Col>
                  )}
                </Droppable>
              </DragDropContext>
              <hr className="u-text-grey" />
              {/*Attribut*/}
              <div className="d-flex align-items-end w-100">
                <Form.Group as={Col} className="mb-3 col me-3">
                  <Form.Label className="mb-1 text-body">
                    {t("epubsAttribut")}
                    <InformationIcon
                      text={t('hjalpEpubsAttribut')}
                      inline
                      className="ps-1"
                    />
                  </Form.Label>
                  <Form.Control
                    as={'select'}
                    type="select"
                    className="form-select"
                    placeholder={t('valjEllips')}
                    value={epubsAttributState}
                    onChange={updateEpubsAttribut}
                  >
                    {getFilteredOptions('epubAttributes', getValues('epubsAttribut'))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-auto">
                  <Button
                    className="primary"
                    type="button"
                    onClick={addEpubsAttribut}
                  >
                    <i className="bi bi-plus"></i>
                  </Button>
                </Form.Group>
              </div>
              <DragDropContext onDragEnd={onEpubTillganglighetstypDragEnd}>
                <Droppable droppableId="droppableepubsattribut">
                  {(provided: any, snapshot: any) => (
                    <Col
                      xs={12}
                      className={`mb-3 ${getDragContainerClass(
                        snapshot.isDraggingOver
                      )}`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {epubsAttribut.map((row: any, index: number) => {
                        return (
                          <Draggable
                            key={row.id}
                            draggableId={row.id}
                            index={index}
                          >
                            {(provided: any, snapshot: any) => (
                              <Row
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                key={row.id}
                                className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                  } mt-2 p-1 ${getDragClass(snapshot.isDragging)}`}
                              >
                                <Col
                                  xs={11}
                                  className="d-flex align-items-center"
                                >
                                  {getOptionsLabel("epubAttributes", getValues(`epubsAttribut.${index}`))}
                                </Col>
                                <Col xs={1} className="d-flex align-items-center">
                                  <i
                                    className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                    onClick={() => removeEpubsAttribut(index)}
                                  />
                                </Col>
                              </Row>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Col>
                  )}
                </Droppable>
              </DragDropContext>
              <hr className="u-text-grey" />
            </Col>
            <Col xs={12}>
              {/*Användningsområde*/}
              <Form.Label className="mb-1 text-body">
                {t('epubsAnvandningsomraden')}
                <InformationIcon
                  text={t('hjalpInnehall')}
                  inline
                  className="ps-1"
                />
              </Form.Label>
              <Card className="mb-4 nopadding">
                <Card.Body>
                  {epubAnvandningsomrade?.length > 0 && (
                    <div className='mb-3 w-100 table'>
                      <div>
                        <div className="small px-3 py-1 d-flex border-bottom">
                          <div style={{ width: "30%" }}>
                            <span className="d-block">{t("kod")}</span>
                          </div>
                          <div style={{ width: "20%" }}>
                            <span className="d-block">{t("beskrivning")}</span>
                          </div>
                          <div style={{ width: "50%" }}>
                            <span className="d-block"></span>
                          </div>
                        </div>
                      </div>
                      <div>
                        {epubAnvandningsomrade.map((row: any, index: number) => {
                          return (
                            <div key={row.id} className={`${index % 2 === 1 ? 'u-background-grey' : ''
                              } d-flex gx-0 px-3 u-text-small-medium u-text-grey py-3 align-items-center border-bottom`}>
                              <div style={{ width: "30%" }}>
                                {getOptionsLabel("epubUses", row.key)}
                              </div>
                              <div style={{ width: "20%" }}>
                                {getOptionsLabel("epubStatuses", row.value)}
                              </div>
                              <div style={{ width: "50%" }}>
                                <div className="d-flex gap-3 justify-content-end">
                                  <i
                                    className="bi bi-pencil u-text-primary u-cursor-pointer"
                                    onClick={() => displayModal(index)}
                                  />
                                  <i
                                    className="bi bi-trash3 u-text-error u-cursor-pointer"
                                    onClick={() => removeEpubAnvandningsomrade(index)}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </Card.Body>
                <Card.Footer>
                  <Button
                    type="button"
                    className="primary"
                    onClick={() => displayModal()}
                  >
                    <i className="bi bi-plus" />
                    {t("laggTill")}
                  </Button>
                </Card.Footer>
              </Card>
              <Modal
                show={showModal}
                centered
                size="lg"
                onHide={closeModal}
                onExited={() => setCurrentIndex(undefined)}
              >
                <Modal.Header>
                  <p>{t("laggTill")}</p>
                </Modal.Header>
                <Modal.Body>
                  {epubAnvandningsomrade.map((row: any, index: number) => {
                    return (
                      index ===
                      (currentIndex !== undefined
                        ? currentIndex
                        : epubAnvandningsomrade.length - 1) && (
                        <Row key={row.id}>
                          <Col xs={12}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-1 text-body">
                                {t("kod")}*
                              </Form.Label>
                              <Form.Control
                                as={'select'}
                                type="select"
                                className="form-select"
                                placeholder={t('valjEllips')}
                                {...register(`epubAnvandningsomrade.${index}.key`, {
                                  validate: (value: any) => {
                                    return !!value || !!getValues(`epubAnvandningsomrade.${index}.key`) ? true : t("valideringEpubAnvandningsomrade");
                                  }
                                })}
                                isInvalid={
                                  !!errors.epubAnvandningsomrade &&
                                  !!errors.epubAnvandningsomrade[index].key
                                }
                              >
                                {getFilteredOptions("epubUses", getValues("epubAnvandningsomrade.key"))}
                              </Form.Control>
                              {!!errors.epubAnvandningsomrade &&
                                !!errors.epubAnvandningsomrade[index].key &&
                                (
                                  <Form.Control.Feedback type="invalid">
                                    {`${errors.epubAnvandningsomrade[index].key.message}`}
                                  </Form.Control.Feedback>
                                )}
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-1 text-body">
                                {t("beskrivning")}*
                              </Form.Label>
                              <Form.Control
                                as={'select'}
                                type="select"
                                className="form-select"
                                placeholder={t('valjEllips')}
                                {...register(`epubAnvandningsomrade.${index}.value`, {
                                  validate: (value: any) => {
                                    return !!value || !!getValues(`epubAnvandningsomrade.${index}.value`) ? true : t("valideringEpubAnvandningsomradeStatus");
                                  }
                                })}
                                isInvalid={
                                  !!errors.epubAnvandningsomrade &&
                                  !!errors.epubAnvandningsomrade[index].value
                                }
                              >
                                {getOptions("epubStatuses")}
                              </Form.Control>
                              {!!errors.epubAnvandningsomrade &&
                                !!errors.epubAnvandningsomrade[index].value &&
                                (
                                  <Form.Control.Feedback type="invalid">
                                  {`${errors.epubAnvandningsomrade[index].value.message}`}
                                  </Form.Control.Feedback>
                                )}
                            </Form.Group>
                          </Col>
                        </Row>
                      )
                    );
                  })}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeModal}>
                    {t('avbryt')}
                  </Button>
                  <Button variant="primary" onClick={saveEpubAnvandningsomrade}>
                    {t('spara')}
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  ) : null
};

