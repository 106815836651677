import { Card, Col, Form, Row } from 'react-bootstrap';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { InformationIcon } from '../../shared/InformationIcon';
import { LockedOrChanged } from '../../shared/LockedOrChanged';
import { shouldBeLocked, hasChanged, getChangedInformation } from '../common';
import { t } from '../../../services/translationService';

interface Props {
	errors: any;
	register: any;
	blockRef: any;
	pageVariant: string;
	lockedFields: keyValue[];
	changedProps: ChangedProperty[];
}

export const PublishingHouse = ({
	errors,
	register,
	blockRef,
	pageVariant,
	lockedFields,
	changedProps,
}: Props) => {
    const showExtendedChangeInfo = pageVariant !== "publisher";
	return (
		<>
			<h3 ref={blockRef}>{t('forlag')}</h3>
			<Col xs={12}>
				<hr className="u-text-grey" />
			</Col>
			<Card className="nopadding my-4">
				<Card.Body className="p-3">
					<Row>
						<Col xs={12}>
							<Form.Group className="mb-3">
								<Form.Label className="mb-1 text-body">
									{t('forlagetsNamn')}*
									<InformationIcon
										text={t('hjalpForlagnamn')}
										inline
										className="ps-1"
									/>
								</Form.Label>
								<LockedOrChanged
                                    locked={shouldBeLocked(lockedFields, 'forlag')}
                                    changed={hasChanged(changedProps, 'forlag')}
                                    showExtendedInfo={showExtendedChangeInfo}
									changedInformation={getChangedInformation(
										changedProps,
										'forlag'
									)}
									child={
										<Form.Control
											placeholder={t('skrivEllips')}
											{...register('forlagsnamn', {
												required: t('valideringForlagnamn'),
											})}
											isInvalid={!!errors.forlagsnamn}
										/>
									}
								/>

								{errors.forlagsnamn && (
									<Form.Control.Feedback type="invalid">
										{`${errors.forlagsnamn.message}`}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</Col>
						{pageVariant !== 'quick' && (
							<Col xs={12}>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('forlagId')}*
										<InformationIcon
											text={t('hjalpForlagid')}
											inline
											className="ps-1"
										/>
									</Form.Label>
									<LockedOrChanged
										locked={shouldBeLocked(lockedFields, 'forlagId')}
                                        changed={hasChanged(changedProps, 'forlagId')}
                                        showExtendedInfo={showExtendedChangeInfo}
										changedInformation={getChangedInformation(
											changedProps,
											'forlagId'
										)}
										child={
											<Form.Control
												placeholder={t('skrivEllips')}
												{...register('forlagId', {
													required: t('valideringForlagId'),
												})}
												isInvalid={!!errors.forlagId}
											/>
										}
									/>
									{errors.forlagId && (
										<Form.Control.Feedback type="invalid">
											{`${errors.forlagId.message}`}
										</Form.Control.Feedback>
									)}
								</Form.Group>
							</Col>
						)}
						{pageVariant !== 'publisher' && (
							<Col xs={12}>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('originalforlag')}
										<InformationIcon
											text={t('hjalpOriginalForlag')}
											inline
											className="ps-1"
										/>
									</Form.Label>
									<LockedOrChanged
										locked={shouldBeLocked(lockedFields, 'originalforlag')}
                                        changed={hasChanged(changedProps, 'originalforlag')}
                                        showExtendedInfo={showExtendedChangeInfo}
										changedInformation={getChangedInformation(
											changedProps,
											'originalforlag'
										)}
										child={
											<Form.Control
												placeholder={t('skrivEllips')}
												{...register('originalforlag')}
											/>
										}
									/>
								</Form.Group>
							</Col>
						)}
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};
