import { useState } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import { t } from '../../services/translationService';
import { OrderForm } from '../shared/OrderForm';
import { addOrderRowsToCart } from '../../services/CartService';
import { setCartSize } from '../../redux/reducers/cartSlice.reducer';
import { useDispatch } from 'react-redux';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from "../../typescript/enums/enums";
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';

export const QuickOrder = () => {

    const dispatch = useDispatch();

	const [rows, setRows] = useState([
		{
			articleNumber: '',
			amount: 1,
            distributors: [],
            selectedDistributor: '',
			title: '',
			reference: '',
		},
    ]);

    const clearForm = () => {
        setRows([
            {
                articleNumber: '',
                amount: 1,
                distributors: [],
                selectedDistributor: '',
                title: '',
                reference: '',
            },
        ]);
    }

    const [canAddToCart, setCanAddToCart] = useState(false);

    const createQuickOrder = async () => {
        dispatch(setLoader(true));
        let cartItems = await addOrderRowsToCart(rows);
        dispatch(setLoader(false));
        clearForm();
        dispatch(setCartSize(cartItems
            .map((p: any) => p.amount)
            .reduce((p: any, c: any) => p + c, 0)));

        NotificationClass.createNotification({
            type: NotificationTypes.Success,
            message: t('itemsAddedToCart')

        });

    }

	return (
		<>
			<Card className="nopadding">
				<Card.Header className="py-4 border-bottom-0">
					<p className="text-muted">
						{t('quickOrderInstructions')}
					</p>
				</Card.Header>
				<Card.Body>
                    <OrderForm rows={rows} setRows={setRows} isValid={setCanAddToCart} />
				</Card.Body>
				<Card.Footer>
                    <Button className={canAddToCart ? undefined : "disabled"} onClick={() => createQuickOrder()}>
						{t('addAll')}
					</Button>
				</Card.Footer>
			</Card>
		</>
	);
};
