import { addNotification, removeNotification } from "../../redux/reducers/notificationsSlice.reducer";
import { Notification } from '../../redux/types/types';
import { NotificationTypes } from "../../typescript/enums/enums";
import { store } from '../../redux/store';

class NotificationClass
{
    private static _instance: NotificationClass;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public createNotification(notification: Notification) {

        notification.id = this.generateId();
        if (notification.type === undefined) {
            notification.type = NotificationTypes.Success;
        }

        this.getType(notification).then((type) => {
            store.dispatch(addNotification(notification));
            if (type.timeout) {
            setTimeout(() => { this.removeNotification(notification.id ?? -1); }, type.timeout);
            }
        });
    }

    public removeNotification(id: number) {
        store.dispatch(removeNotification(id));
    }

    private generateId(): number {
        return Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER));
    }

    private getType(response: any): Promise<any> {
        return new Promise((resolve, reject) => {
          switch (response.type) {
            case NotificationTypes.Error:
              resolve({ timeout: null });
              break;
            case NotificationTypes.Success:
              resolve({ timeout: 3000 });
              break;
            case NotificationTypes.Warning:
              resolve({ timeout: 5000 });
              break;
            case NotificationTypes.Info:
              resolve({ timeout: 5000 });
              break;
            default:
              resolve({ timeout: 5000});
          }
        });
    }
}

export default NotificationClass.Instance;