import { useEffect, useState } from 'react';
import { Card, Button, FormControl, FormSelect, Modal } from 'react-bootstrap';
import useDebounce from '../../hooks/useDebounce';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import { useAppDispatch } from '../../redux/hooks';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import {
	Certificate,
	CertificateServiceError,
	CertificatesViewModel,
	getCertificateHistory,
	getCertificates,
	sendCertificate,
} from '../../services/certificateService';
import { Pagination } from '../shared/PaginationComponent';
import { StatusBadge } from '../shared/StatusBadge';
import { Table } from '../shared/Table';
import { t } from '../../services/translationService';

declare global {
	interface Bokinfo {
		certificates: CertificatesViewModel;
	}
}

export const Certificates = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const [sortField, setSortField] = useState(1);
	const [items, setItems] = useState<Certificate[]>(
		window.bokinfo.certificates.certificates || []
	);
	const [total, setTotal] = useState<number>(window.bokinfo.certificates.total);
	const pageSize = 20;
	const [searchField, setSearchField] = useState('');
	const debouncedSearch = useDebounce(searchField);

	const [modalFetching, setModalFetching] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState<any>();

	const [success, setSuccess] = useState<boolean | undefined>(undefined);

	const closeConfirmation = () => setSuccess(undefined);

	const dispatch = useAppDispatch();

	const columns = [
		{ id: 0, title: t('isbn'), width: '15%', sortable: true },
		{ id: 1, title: t('senastAndrad'), width: '15%', sortable: true },
		{ id: 2, title: t('status'), width: '10%' },
		{ id: 3, title: t('rubrik'), width: '30%' },
		{ id: 4, title: t('redigeraVisa'), width: '10%' },
		{ id: 5, title: t('skicka'), width: '10%' },
		{ id: 6, title: t('historik'), width: '10%' },
	];

	useEffect(() => {
		getCertificates(searchField, sortField, currentPage, pageSize).then(
			(res: CertificatesViewModel | CertificateServiceError) => {
				let model = res as CertificatesViewModel;
				if (model) {
					setItems(model.certificates || []);
					setTotal(model.total);
				}
			}
		);
	}, [currentPage, sortField, debouncedSearch]);

	useEffect(() => {
		if (modalFetching) {
			setModalContent(undefined);

			dispatch(setLoader(true));

			getCertificateHistory(modalFetching).then((data) => {
				dispatch(setLoader(false));
				setModalOpen(true);
				setModalContent(data);
				setModalFetching('');
			});
		}
	}, [modalFetching, dispatch]);

	const closeModal = () => {
		setModalOpen(false);
	};

	const submitCertificate = (id: any, isbn: any) => {
		dispatch(setLoader(true));

		sendCertificate(id, isbn)
			.then(() => {
				dispatch(setLoader(false));
				setSuccess(true);
				getCertificates(searchField, sortField, currentPage, pageSize).then(
					(res: CertificatesViewModel | CertificateServiceError) => {
						let model = res as CertificatesViewModel;
						if (model) {
							setItems(model.certificates || []);
							setTotal(model.total);
						}
					}
				);
			})
			.catch(() => {
				dispatch(setLoader(false));
				setSuccess(false);
			});
	};

	const xs = useScreenWidth(576);

	return (
		<>
			<Modal show={success !== undefined} centered onHide={closeConfirmation}>
				<Modal.Body>
					{success
						? 'Certifikatet skickades'
						: 'Certifikatet kunde inte skickas'}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeConfirmation}>
						{t('ok')}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={modalOpen}
				centered
				onHide={closeModal}
				onExited={() => setModalContent(undefined)}
			>
				<Modal.Header>
					<p>{t('historik')}</p>
				</Modal.Header>
				<Modal.Body>
					<Table
						smallTableHeadFont
						firstColumnBold={false}
						striped
						columns={[
							{ title: t('tid'), width: '45%' },
							{ title: t('handelse'), width: '55%' },
						]}
						items={modalContent?.map(({ created, text }: any) => ({
							columns: [created, text],
						}))}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModal}>
						{t('ok')}
					</Button>
				</Modal.Footer>
			</Modal>
			<div className="d-block d-sm-flex gap-3 align-items-center justify-content-between">
				<FormControl
					className="mb-3 mb-sm-0"
					style={{ width: xs ? 400 : '100%' }}
					placeholder={t('sok')}
					value={searchField}
					onChange={(e) => setSearchField((e.target as HTMLInputElement).value)}
				/>
				<FormSelect
					style={{ width: xs ? 250 : '100%' }}
					value={sortField}
					onChange={(e) => setSortField(Number(e.target.value))}
				>
					{columns.map(
						(c) => c.sortable && <option value={c.id}>{c.title}</option>
					)}
				</FormSelect>
			</div>
			<Card className="mt-3 nopadding">
				<Card.Header>
					<h2 className="h5 py-3 m-0">{t('certifikat')}</h2>
				</Card.Header>
				<Card.Body className="horizontal-scroll">
					<Table
						smallTableHeadFont
						firstColumnBold={false}
						striped
						columns={columns}
						items={items.map(
							({ id, isbn, modified, status, statusText, title }: any) => ({
								columns: [
									isbn,
									modified,
									<StatusBadge status={statusText} />,
									title,
									<Button variant="secondary" href={`${isbn}`}>
										{t('redigera')}
									</Button>,
									<Button
										variant="primary"
										onClick={() => submitCertificate(id, isbn)}
									>
										{t('skicka')}
									</Button>,
									<Button variant="link" onClick={() => setModalFetching(id)}>
										{t('visaHistorik')}
									</Button>,
								],
							})
						)}
					/>
				</Card.Body>
				{items.length > 1 && (
					<Card.Footer className="text-muted">
						<Pagination
							setCurrent={setCurrentPage}
							currentPage={currentPage}
							totalItems={total}
							pageSize={pageSize}
							disabled={false}
						/>
					</Card.Footer>
				)}
			</Card>
		</>
	);
};
