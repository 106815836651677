import { useEffect, useState } from 'react';
import { Button, Card, Form, Row, Col, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { t } from '../../services/translationService';
import {
	updateApplication,
} from '../../services/registrationService';
import { keyValue } from '../../redux/types/types';

export const RegistrationAdministrationDetails = () => {
	const [application, setApplication] = useState<any>(
		window.bokinfo.application || {}
	);
	const [distributors, setDistributors] = useState<[]>(
		window.bokinfo.dictionaries.distributors || {}
	);
	const [invoiceOptions, setInvoiceOptions] = useState<[]>(
		window.bokinfo.dictionaries.invoiceTypes || {}
	);

	const [actionStatus, setActionStatus] = useState<string>("");
	const [confirmationVisible, setConfirmationVisible] = useState(false);

	const { 
		register,
		getValues,
		formState: { errors },
		reset
	}: any = useForm({
		mode: 'onSubmit',
		defaultValues: application,
	});

	useEffect(() => {
		if(actionStatus?.length) {
            updateApplication(getValues(), actionStatus);
            setActionStatus("");
		}
    }, [actionStatus]);

	useEffect(() => {
        reset(application);
	}, [reset]);

	const ConfirmationModal = ({ show }: { show: boolean }) => (
		<Modal show={show} size="sm" centered>
			<Modal.Body>
				<p>{t('areYouSure')}</p>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => {setActionStatus("Delete"); setConfirmationVisible(false)}}>{t('buttonDeleteRequest')}</Button>
				<Button onClick={() => {setConfirmationVisible(false)}}>{t('cancel')}</Button>
			</Modal.Footer>
		</Modal>
    );

	return (
		<>
			<Form>
				<ConfirmationModal show={confirmationVisible} />
				<Card className="nopadding">
					<Card.Header>
						<h2 className="h5 py-3 m-0">{t('reviewRequest')}</h2>
					</Card.Header>
					<Card.Body className="p-3">
						<Row>
							<Col md={6}>
								<h3 className="h5 my-3">{t('publisherInformation')}</h3>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('publisherName')}*
									</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('publisherName')}
										isInvalid={!!errors.publisherName}
									/>
									{errors.publisherName && (
										<Form.Control.Feedback type="invalid">
											{`${errors.publisherName.message}`}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('companyName')}
									</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('companyName')}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('organizationNumber')}*
									</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('organizationNumber')}
										isInvalid={!!errors.organizationNumber}
									/>
									{errors.organizationNumber && (
										<Form.Control.Feedback type="invalid">
											{t('validationOrganizationNumber')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('website')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('webPage')}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('distributor')}</Form.Label>
									{distributors.map((d:keyValue, i) => (
										<Form.Check
											className="mt-3"
											key={d.key}
											id={"id" + d.key}
											type="radio"
											defaultChecked ={application.distributorId == d.key}
											label={d.value}
											{...register('distributorId')}
											value={d.key}
										/>
									))}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('invoiceType')}
									</Form.Label>
									{invoiceOptions.map((io:keyValue, i) => (
										<>
											<Form.Check
												className="mt-3"
												key={io.key}
												id={"id" + io.key}
												type="radio"
                                                defaultChecked={application.invoiceTypeId == io.key}
												label={io.value}
												{...register('invoiceTypeId')}
												value={io.key}
											/>
										</>
									))}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('invoiceEmail')}
									</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('invoiceEmail', {
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: t('validationEmailInvalid'),
											},
										})}
										isInvalid={!!errors.invoiceEmail}
									/>
									{errors.invoiceEmail && (
										<Form.Control.Feedback type="invalid">
											{`${errors.invoiceEmail.message}`}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('additionalInformation')}
									</Form.Label>
									<Form.Control
										as="textarea"
										placeholder={t('skrivEllips')}
										{...register('additionalInformation')}
									/>
								</Form.Group>
								<h3 className="h5 my-3">{t('userInformation')}</h3>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('email')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('userModel.email', {
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: t('validationEmailInvalid'),
											},
										})}
										isInvalid={!!errors.user?.email}
									/>
									{errors.user?.email && (
										<Form.Control.Feedback type="invalid">
											{`${errors.user?.email.message}`}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('userName')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('userModel.userName')}
										isInvalid={!!errors.userModel?.userName}
										disabled={true}
									/>
									{errors.user?.username && (
										<Form.Control.Feedback type="invalid">
											{t('validationUserName')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
							</Col>
							<Col md={6}>
								<h3 className="h5 my-3">{t('invoiceAddress')}</h3>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('name')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('invoiceAddress.name')}
										isInvalid={!!errors.invoiceAddress?.name}
									/>
									{errors.invoiceAddress?.name && (
										<Form.Control.Feedback type="invalid">
											{t('validationName')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('zipCode')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('invoiceAddress.postalCode')}
										isInvalid={!!errors.invoiceAddress?.zipCode}
									/>
									{errors.invoiceAddress?.zipCode && (
										<Form.Control.Feedback type="invalid">
											{t('validationZipCode')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('careOf')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('invoiceAddress.line1')}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('city')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('invoiceAddress.city')}
										isInvalid={!!errors.invoiceAddress?.city}
									/>
									{errors.invoiceAddress?.city && (
										<Form.Control.Feedback type="invalid">
											{t('validationCity')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('address')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('invoiceAddress.line2')}
										isInvalid={!!errors.invoiceAddress?.line2}
									/>
									{errors.invoiceAddress?.line2 && (
										<Form.Control.Feedback type="invalid">
											{t('validationAddress')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('address')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('invoiceAddress.line3')}
									/>
								</Form.Group>
								<h3 className="h5 my-3">{t('postAddress')}</h3>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('name')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('visitAddress.name')}
										isInvalid={!!errors.visitAddress?.name}
									/>
									{errors.visitAddress?.name && (
										<Form.Control.Feedback type="invalid">
											{t('validationName')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('zipCode')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('visitAddress.postalCode')}
										isInvalid={!!errors.visitAddress?.postalCode}
									/>
									{errors.visitAddress?.postalCode && (
										<Form.Control.Feedback type="invalid">
										{t('validationZipCode')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('careOf')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('visitAddress.line1')}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('city')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('visitAddress.city')}
										isInvalid={!!errors.visitAddress?.city}
									/>
									{errors.visitAddress?.city && (
										<Form.Control.Feedback type="invalid">
											{t('validationCity')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('address')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('visitAddress.line2')}
										isInvalid={!!errors.visitAddress?.line2}
									/>
									{errors.visitAddress?.line2 && (
										<Form.Control.Feedback type="invalid">
											{t('validationAddress')}
										</Form.Control.Feedback>
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('address')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('visitAddress.line3')}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">{t('email')}</Form.Label>
									<Form.Control
										placeholder={t('skrivEllips')}
										{...register('visitAddress.email', {
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: t('validationEmailInvalid'),
											},
										})}
										isInvalid={!!errors.visitAddress?.email}
									/>
									{errors.visitAddress?.email && (
										<Form.Control.Feedback type="invalid">
											{`${errors.visitAddress?.email.message}`}
										</Form.Control.Feedback>
									)}
								</Form.Group>
							</Col>
						</Row>
						<hr />
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('message')}
									</Form.Label>
									<Form.Control
										as="textarea"
										placeholder={t('skrivEllips')}
										{...register('emailMessage')}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Card.Body>
					<Card.Footer className="text-muted flex-column-reverse flex-md-row flex-wrap">
						<Button variant="secondary" onClick={() => {setConfirmationVisible(true)}}>
							{t('buttonDeleteRequest')}
						</Button>
						<Button variant="secondary" onClick={() => {setActionStatus("SendAgreement")}}>
							{t('buttonSendRequest')}
						</Button>
						<Button variant="secondary" onClick={() => {setActionStatus("Reject")}}>
							{t('buttonRejectRequest')}
						</Button>
						<Button variant="secondary" onClick={() => {setActionStatus("RequestMoreInformation")}}>
							{t('buttonRequestAdditionalInformation')}
						</Button>
						<Button variant="secondary" disabled={application.status == "SentAgreement" ? false : true } onClick={() => {setActionStatus("Approve")}}>
							{t('buttonApproveRequest')}
						</Button>
						<Button variant="secondary" onClick={() => {setActionStatus("Update")}}>
							{t('buttonUpdateRequest')}
						</Button>
					</Card.Footer>
				</Card>
			</Form>
		</>
	);
};
