import { Card } from 'react-bootstrap';
import { t } from '../../../services/translationService';

export const Validation = ({ blockRef, listOfErrors }: any) => {
	return (
		<div ref={blockRef}>
			{listOfErrors && listOfErrors.length > 0 && (
				<Card className="nopadding my-4">
					<Card.Body className="p-3">
						<div className="validation__error__block">
							<span className="validation__error__block__description">
								{t('valideringSparaBok')}
							</span>
							<ul className="validation__error__block__list">
								{listOfErrors.map((error: any, index: number) => {
									return <li key={index}>{error.value}</li>;
								})}
							</ul>
						</div>
					</Card.Body>
				</Card>
			)}
		</div>
	);
};
