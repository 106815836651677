import { Card, Col, Form, Row } from 'react-bootstrap';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { InformationIcon } from '../../shared/InformationIcon';
import { LockedOrChanged } from '../../shared/LockedOrChanged';
import {
  getBooleanOptions,
  getChangedInformation,
  getOptions,
  hasChanged,
  shouldBeLocked,
} from '../common';
import { t } from '../../../services/translationService';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { formatToSwedishDecimal, parseSwedishDecimal } from '../../../services/editBookService';

interface Props {
  errors: any;
  register: any;
  blockRef: any;
  setValue: any;
  getValues: any;
  pageVariant: string;
  lockedFields: keyValue[];
  changedProps: ChangedProperty[];
  watch: any;
  control: any;
  trigger: any;
  setIsEpub?: Dispatch<SetStateAction<boolean>>
}

export const Format = ({
  errors,
  register,
  blockRef,
  setValue,
  getValues,
  pageVariant,
  lockedFields,
  changedProps,
  watch,
  setIsEpub
}: Props) => {

  const showExtendedChangeInfo = pageVariant !== "publisher";
  const [initialRender, setInitialRender] = useState(true);

  const [bandtyper, setBandtyper] = useState([]);
  const [selectedBandtyp, setSelectedBandtyp] = useState<any[]>();
  const [currentMediaTyp, setCurrentMediaTyp] = useState<any>({});

  const mediatyp: string | undefined = watch('mediatyp');
  const bandtyp: string | undefined = watch('bandtyp');

  // On switch media type, reset formats
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      setValue('bandtyp', undefined);
      setSelectedBandtyp(undefined);
      setValue('epubversion', undefined);
    }

    const selectedMediaTyp = Object.values(
      window.bokinfo.dictionaries.mediaTypes
    ).find((m: any) => m.value === mediatyp) as any;

    selectedMediaTyp && setCurrentMediaTyp(selectedMediaTyp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediatyp]);

  useEffect(() => {
    !!setIsEpub && setIsEpub(mediatyp === 'Nedladdningsbar bok' && bandtyp === 'Epub');
  }, [mediatyp, bandtyp, setIsEpub])

  useEffect(() => {
    const properties = ["bandtyp", "viktExklusiveForpackning"]

    // Use Object.fromEntries to create an object from key-value pairs
    // Map over the properties array to create an array of [key, value] pairs
    const values = Object.fromEntries(properties.map((property, index) => [property, getValues(properties)[index]]));

    // Bandtyp is set as string value on book, but is used as key/value in select & typeahead
    // so we need to set it as an array on mount
    if (values.bandtyp) {
      if (Array.isArray(values.bandtyp)) {
        setSelectedBandtyp(values.bandtyp)
      }
      else {
        setSelectedBandtyp([values.bandtyp])
      }
    }

    setValue("viktExklusiveForpackning", formatToSwedishDecimal(values.viktExklusiveForpackning))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // This will fire on any change to the field, but will only contain a value when something is actually selected.
  const onTypeaheadSelect = (value: any) => {
    setSelectedBandtyp(value);
    if (value?.length && value[0]?.value) {
      setValue("bandtyp", value[0].value)
    }
  }

  const getBandTypes = () => {
    if (mediatyp !== '' && mediatyp !== undefined) {
      const list = window.bokinfo.dictionaries['mediaTypes'];
      const result = list.filter((obj: any) => obj.key === mediatyp);

      return result;
    }
  }

  // Select component options
  const getBandtypOptions = () => {
    if (mediatyp !== '' && mediatyp !== undefined) {
      const result = getBandTypes();

      if (result !== undefined && result.length > 0) {
        let bandTyper = result[0].formats.slice(0);
        bandTyper.unshift({
          key: '',
          value: t('valjEllips'),
        });
        return bandTyper.map((entry: any, index: number) => {
          return index === 0 ? (
            <option key={null} value={''}>
              {entry.value}
            </option>
          ) : (
            <option key={entry.key} value={entry.key}>
              {entry.value}
            </option>
          );
        });
      }
    } else {
      return (
        <option key={''} value={''}>
          {t('valjEllips')}
        </option>
      );
    }
  };

  // Typeahead component options
  const getSuggestions = useCallback(
    (queryString: string): void => {

      const result = queryString.length
        ? currentMediaTyp.formats.filter((t: any) => t.value.toLowerCase().includes(queryString.toLowerCase()))
        : currentMediaTyp.formats;

      setBandtyper(result);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentMediaTyp]
  );

  return (
    <>
      <h3 ref={blockRef}>{t('format')}</h3>
      <Col xs={12}>
        <hr className="u-text-grey" />
      </Col>
      <Card className="nopadding my-4">
        <Card.Body className="p-3">
          <Row>
            {pageVariant !== 'quick' && (
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-body">
                    {t('mediatyp')}*
                  </Form.Label>
                  <LockedOrChanged
                    locked={shouldBeLocked(lockedFields, 'mediatyp')}
                    changed={hasChanged(changedProps, 'mediatyp')}
                    showExtendedInfo={showExtendedChangeInfo}
                    changedInformation={getChangedInformation(
                      changedProps,
                      'mediatyp'
                    )}
                    child={
                      <Form.Control
                        as={'select'}
                        type="select"
                        className="form-select"
                        placeholder={t('valjEllips')}
                        {...register('mediatyp', {
                          required: t('valideringMediatyp'),
                        })}
                        isInvalid={!!errors.mediatyp}
                      >
                        {getOptions('mediaTypes')}
                      </Form.Control>
                    }
                  />
                  {errors.mediatyp && (
                    <Form.Control.Feedback type="invalid">
                      {`${errors.mediatyp.message}`}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            )}
            <Col xs={12}>
              {(currentMediaTyp?.allowText || currentMediaTyp.formats?.length > 0) &&
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-body">
                    {t('bandtyp')}{currentMediaTyp.requireFormat && "*"}
                    <InformationIcon
                      text={t('hjalpBandtyp')}
                      inline={true}
                      className="ps-1"
                    />
                  </Form.Label>
                  <LockedOrChanged
                    locked={shouldBeLocked(lockedFields, 'bandtyp')}
                    changed={hasChanged(changedProps, 'bandtyp')}
                    showExtendedInfo={showExtendedChangeInfo}
                    changedInformation={getChangedInformation(
                      changedProps,
                      'bandtyp'
                    )}
                    child={
                      (
                        <>
                          {currentMediaTyp?.allowText && !currentMediaTyp.formats?.length && (
                            <Form.Control
                              {...register('bandtyp', {
                                required: { value: currentMediaTyp.requireFormat, message: t('valideringBandtyp') }
                              })}
                              placeholder={t('skrivEllips')}
                            />)}

                          {currentMediaTyp?.formats?.length > 0 && !currentMediaTyp.allowText && (
                            <Form.Control
                              as={'select'}
                              type="select"
                              {...register('bandtyp', {
                                required: { value: currentMediaTyp.requireFormat, message: t('valideringBandtyp') }
                              })}
                              className="form-select"
                              placeholder={t('valjEllips')}
                              isInvalid={!!errors.bandtyp}
                            >
                              {getBandtypOptions()}
                            </Form.Control>)}

                          {currentMediaTyp?.allowText && currentMediaTyp.formats?.length > 0 && (
                            <AsyncTypeahead
                              id="bandtypTypeahead"
                              inputProps={{ required: currentMediaTyp.requireFormat }}
                              labelKey={'value'}
                              isLoading={false}
                              options={bandtyper}
                              disabled={shouldBeLocked(lockedFields, 'bandtyp')}
                              placeholder={t('skrivEllips')}
                              selected={selectedBandtyp}
                              minLength={0}
                              allowNew={true}
                              newSelectionPrefix={t('anvand')}
                              onFocus={() => {
                                getSuggestions("");
                              }}
                              onSearch={(query: string) => {
                                getSuggestions(query);
                              }}
                              onChange={(selected: any) => {
                                onTypeaheadSelect(selected);
                              }}
                            />)}
                        </>
                      )
                    }
                  />
                  {errors.bandtyp && (
                    <Form.Control.Feedback type="invalid">
                      {`${errors.bandtyp.message}`}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              }
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('omfang')}
                </Form.Label>
                <LockedOrChanged
                  locked={shouldBeLocked(lockedFields, 'omfang')}
                  changed={hasChanged(changedProps, 'omfang')}
                  showExtendedInfo={showExtendedChangeInfo}
                  changedInformation={getChangedInformation(
                    changedProps,
                    'omfang'
                  )}
                  child={
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('omfang', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.omfang}
                    />
                  } />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('omfangTyp')}
                </Form.Label>
                <LockedOrChanged
                  locked={shouldBeLocked(lockedFields, 'omfangTyp')}
                  changed={hasChanged(changedProps, 'omfangTyp')}
                  showExtendedInfo={showExtendedChangeInfo}
                  changedInformation={getChangedInformation(
                    changedProps,
                    'omfangTyp'
                  )}
                  child={
                    <Form.Control
                      as={'select'}
                      defaultValue={getValues('omfangTyp')}
                      type="select"
                      className="form-select"
                      placeholder={t('valjEllips')}
                      {...register('omfangTyp')}
                      isInvalid={!!errors.omfangTyp}
                    >
                      {getOptions('rangeTypes', true)}
                    </Form.Control>
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <hr className="u-text-grey" />
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('illustrerad')}
                </Form.Label>
                <LockedOrChanged
                  locked={shouldBeLocked(lockedFields, 'illustrerad')}
                  changed={hasChanged(changedProps, 'illustrerad')}
                  showExtendedInfo={showExtendedChangeInfo}
                  changedInformation={getChangedInformation(
                    changedProps,
                    'illustrerad'
                  )}
                  child={
                    <Form.Control
                      as={'select'}
                      type="select"
                      className="form-select"
                      placeholder={t('valjEllips')}
                      {...register('illustrerad', {
                        setValueAs: (v: any) => (v === 'true' || v === true)
                      })}
                      isInvalid={!!errors.illustrerad}
                    >
                      {getBooleanOptions()}
                    </Form.Control>
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('omslagsfarg')}
                </Form.Label>
                <LockedOrChanged
                  locked={shouldBeLocked(lockedFields, 'omslagsfarg')}
                  changed={hasChanged(changedProps, 'omslagsfarg')}
                  showExtendedInfo={showExtendedChangeInfo}
                  changedInformation={getChangedInformation(
                    changedProps,
                    'omslagsfarg'
                  )}
                  child={
                    <Form.Control
                      as={'select'}
                      type="select"
                      className="form-select"
                      placeholder={t('valjEllips')}
                      {...register('omslagsfarg')}
                      isInvalid={!!errors.omslagsfarg}
                    >
                      {getOptions('coverColors')}
                    </Form.Control>
                  }
                />
              </Form.Group>
            </Col>
            {pageVariant !== 'quick' && (
              <>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('bredd')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('bredd', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.bredd}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('hojd')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('hojd', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.hojd}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('ryggbredd')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('ryggbredd', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.ryggbredd}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('vikt')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('vikt', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                      isInvalid={!!errors.vikt}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t("viktExklusiveForpackning")}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('viktExklusiveForpackning', {
                        setValueAs: (value: any) => !!value ? parseSwedishDecimal(value) : null
                      })}
                      isInvalid={!!errors.viktExklusiveForpackning}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
