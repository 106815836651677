import { Row, Col } from 'react-bootstrap';
import { Table } from '../shared/Table';
import { t } from '../../services/translationService';

export const PrintOrder = ({ currentOrder, orderDetails, orderItems }: any) => (
	<>
		<Row>
			<Col>
				<h3 className="mb-3">
					{t('order')} {currentOrder}
				</h3>
			</Col>
		</Row>
		<Row>
			<Col>
				<Table
					columnGap={2}
					rowHeight={2}
					hideHeader
					firstColumnBold
					striped
					columns={[
						{ title: '', width: '180px' },
						{ title: '', width: 'calc(100% - 180px)' },
					]}
					items={orderDetails.map((t: any) => ({
						columns: t,
					}))}
				/>
			</Col>
		</Row>
		<Row className="mt-4">
			<Col>
				<Table
					columnGap={2}
					rowHeight={2}
					smallTableHeadFont
					firstColumnBold={false}
					striped
					columns={[
						{ title: t('articleNumber'), width: '20%' },
						{ title: t('title'), width: '20%' },
						{ title: t('amount'), width: '10%' },
						{ title: t('price'), width: '10%' },
						{ title: t('reference'), width: '15%' },
						{ title: t('priceId'), width: '10%' },
						{ title: t('response'), width: '15%' },
					]}
					items={orderItems.map((t: any) => {
						const x = Object.values(t);

						return { columns: [x[0], x[2], x[3], x[4], x[5], x[6], x[7]] };
					})}
				/>
			</Col>
		</Row>
	</>
);
