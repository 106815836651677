import axios, { AxiosError } from 'axios';

const BASEURI = '/api/authenticate';

export interface SigninModel {
    userName: string;
    password: string;
  };

export interface SignInResult {
    succeeded: boolean;
    isLockedOut: boolean;
    message: string;
};

const postSignin = async (language: string, singinModel: SigninModel): Promise<SignInResult> => {
  try {
    const { data } = await axios.post<SignInResult>(`/${language}${BASEURI}/signin`, singinModel);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      return error.response?.data as SignInResult;
    } else {
      console.log('unexpected error: ', error);
      const err = error as AxiosError;
      return err.response?.data as SignInResult;
    }
  }
}

const postSignOut = async (language: string): Promise<any> => {
    try {
      const { data } = await axios.post<{}>(`/${language}${BASEURI}/signout`);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error);
        return error
      } else {
        console.log('unexpected error: ', error);
        return error
      }
    }
  }

  export { postSignin, postSignOut }