import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PublicationsComponent } from './PublicationsComponent';
import { EditPublicationComponent } from './EditPublicationComponent';
import { RecentlyPublishedBooksPageComponent } from './RecentlyPublishedBooksPageComponent';
import { SearchPublisherDistributorComponent } from './SearchPublisherDistributorComponent';
import { SearchComponent } from '../search/Search';
import { Certificates } from '../certificates/Certificates';
import { EditCertificate } from '../certificates/EditCertificate';
import { Registration } from '../registration/Registration';
import { RegistrationAdministration } from '../registrationAdministration/RegistrationAdministration';
import { Order } from '../order/Order';
import { QuickOrder } from '../quickOrder/QuickOrder';
import { setLanguage } from '../../redux/reducers/languageSlice.reducer';
import { RegistrationAdministrationDetails } from '../registrationAdministration/RegistrationAdministrationDetails';
import { RegistrationAdministrationHistory } from '../registrationAdministration/RegistrationAdministrationHistory';
import { BulkOrder } from '../bulkOrder/BulkOrder';
import { ReturnRequests } from '../returnRequests/returnRequests';
import { Cart } from '../cart/Cart';
import { EditBook } from '../editBook/EditBook';

const routes = new Map<string, JSX.Element>([
	['(/lopsedlar/?)$', <PublicationsComponent />],
	['(/andra-lopsedel/?)$', <EditPublicationComponent />],
	[
		'(/sok-forlag-eller-distributor/?)$',
		<SearchPublisherDistributorComponent />,
	],
    ['(/sok/?)$', <SearchComponent />],
    ['(/sok/liknandetitlar/\\d+)$', <SearchComponent />],
	['(/nya-bocker-denna-vecka/?)$', <RecentlyPublishedBooksPageComponent />],
	['(/certifikat/\\d+/?)$', <EditCertificate />],
	['(/certifikat/?)$', <Certificates />],
	['(/kundregistrering/?)$', <Registration />],
	['(/administrera-kundansokningar/?)$', <RegistrationAdministration />],
	[
		'(/administrera-kundansokningar/granska/?)$',
		<RegistrationAdministrationDetails />,
	],
	[
		'(/administrera-kundansokningar/historik/?)$',
		<RegistrationAdministrationHistory />,
	],
    ['(/order/?)$', <Order />],
    ['(/order/\\d+)$', <Order />],
	['(/snabbestallning/?)$', <QuickOrder />],
	['(/massbestallning/?)$', <BulkOrder />],
	['(/returforfragningar/?)$', <ReturnRequests />],
	['(/kundvagn/?)$', <Cart />],
	['(/artiklar/?\\d*)$', <EditBook />],
]);

export const Router = () => {
	const location = useLocation();
	const [activeComponent, setActiveComponent] = useState<JSX.Element>(<></>);
	useEffect(() => {
		const splitLocation = location.pathname.split('/');
		if (splitLocation.length > 0) {
			setLanguage(splitLocation[0]);
		}

		setActiveComponent(
			Array.from(routes.entries()).find((route: [string, JSX.Element]) =>
				new RegExp(route[0]).test(location.pathname)
			)?.[1] ?? <></>
		);
	}, [location]);
	return <>{activeComponent}</>;
};
