import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import {
	PublisherInfo,
	DistributorInfo,
	UserInfo,
	AddressInfo,
	InvoiceAddressInfo,
	AdditionalInfo,
	Review,
    ThatsIt,
} from './Steps';
import { RegistrationProvider } from './context';
import { useReCaptcha } from '../../hooks/useReCaptcha';
import { t } from '../../services/translationService';

export const Registration = () => {
	const [step, setStep] = useState<number>(1);
    const [stepLength, setStepLength] = useState<number>(1);

	useReCaptcha();

	return (
		<RegistrationProvider>
			<Card className="nopadding">
				<Card.Body className="p-3">
                    {step === 1 && <PublisherInfo setStep={setStep} />}
					{step === 2 && <DistributorInfo setStep={setStep} />}
					{step === 3 && <UserInfo setStep={setStep} />}
					{step === 4 && (<AddressInfo setStep={setStep} setStepLength={setStepLength} />)}
					{step === 5 && <InvoiceAddressInfo setStep={setStep} />}
					{step === 6 && <AdditionalInfo setStep={setStep} />}
                    {step === 7 && <Review setStep={setStep} />}
                    {step === 8 && <ThatsIt /> }
				</Card.Body>
                <Card.Footer>
					{step > 1 && step < 8 && (
						<Button
							variant="secondary"
							onClick={() => setStep((current: number) => current - stepLength)}
						>
							{t('buttonPrevious')}
						</Button>
					)}
                    {step < 8 && <Button form={`step-${step}`} type="submit">
                        {step === 7 ? t('buttonSend') : t('buttonNext')}
                    </Button>}
				</Card.Footer>
			</Card>
		</RegistrationProvider>
	);
};
