import { Badge } from 'react-bootstrap';

export const StatusBadge = ({ status }: IStatus) => {
    const statusColor =
        ['Skickat avtal', 'Klar'].includes(status)
            ? 'success'
			: status === 'Under granskning'
			? 'warning'
			: status === 'Sparad'
			? 'info'
			: 'danger';
	const statusText =
		status === 'New' ? 'Ny' : status === 'Attention' ? 'Varning' : status === 'Accepted' ? 'Accepterad' : 'Misslyckad';

	return (
		<Badge pill bg={statusColor} text={statusColor}>
			{status}
		</Badge>
	);
};

interface IStatus {
	status: string;
}
