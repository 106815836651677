import { FormSelect } from 'react-bootstrap';
import { setItemDeliverability } from '../../services/orderService';
import { t } from '../../services/translationService';

const responses = {
	AcceptedShipping: t('confirmed'),
	CanceledCannotSupply: t('denied'),
	AcceptedBackordered: t('rest'),
};

const getDeliverability = (status: string) => {
	if (status === 'AcceptedBackordered') {
		return Object.entries(responses)[2];
	}
	if (status === 'CanceledCannotSupply') {
		return Object.entries(responses)[1];
	}
	if (status === 'AcceptedShipping' || status === '') {
		return Object.entries(responses)[0];
	}

	return;
};

export const ItemResponse = ({ isPublisher, isBookstore, status, orderId, index, issued }: any) => {
	if (isPublisher && !issued) {
		return (
			<FormSelect
				defaultValue={getDeliverability(status)?.[0]}
				onChange={(e) => setItemDeliverability(orderId, e.target.value, index)}
			>
				{Object.entries(responses).map(([k, v]) => (
					<option key={k} value={k}>
						{v}
					</option>
				))}
			</FormSelect>
		);
	}

	if (isBookstore && !issued) {
		return <span>-</span>;
	}

	return <span>{getDeliverability(status)?.[1]}</span>;
};
