import { useEffect, useState } from 'react';
import {
	Card,
	FormControl,
	FormLabel,
	Nav,
	Tab,
	Button,
	Modal,
	FormCheck,
} from 'react-bootstrap';
import { Pagination } from '../shared/PaginationComponent';
import { Table } from '../shared/Table';
import {
	getCurrent,
	getDetails,
	getHistory,
} from '../../services/returnRequestService';
import { useAppDispatch } from '../../redux/hooks';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { t } from '../../services/translationService';

declare global {
	interface Bokinfo {
		returnRequests: {
			id: string;
			submittedAt: string;
			reference: string;
			distributors: string[];
			trackingNumber: string;
			submissionMethod: string;
		};
	}
}

export const ReturnRequests = () => {
	const { returnRequests } = window.bokinfo;

	const [current, setCurrent] = useState<any>(returnRequests);
	const [history, setHistory] = useState<any>([]);

	const [tab, setTab] = useState<string | null>('current');

	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(0);

	const [modalFetching, setModalFetching] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState<any>();

	const dispatch = useAppDispatch();

	const switchTab = (selected: string | null) => {
		if (tab !== selected) {
			setTab(selected);

			if (selected === 'current') {
				getCurrent().then((data) => {
					setCurrent(data);
				});
			}
		}
	};

	useEffect(() => {
		if (tab === 'history') {
			getHistory(page).then(({ requests, total }) => {
				setHistory(requests);
				setTotal(total);
			});
		}
	}, [page, tab]);

	useEffect(() => {
		if (modalFetching) {
			setModalContent(undefined);

			dispatch(setLoader(true));

			getDetails(modalFetching).then((data) => {
				setTimeout(() => {
					dispatch(setLoader(false));
					setModalOpen(true);
					setModalContent(data);
					setModalFetching('');
				}, 0);
			});
		}
	}, [modalFetching, dispatch]);

	const closeModal = () => {
		setModalOpen(false);
	};

	return (
		<>
			<Modal
				show={modalOpen}
				size="xl"
				centered
				onHide={closeModal}
				onExited={() => setModalContent(undefined)}
			>
				<Modal.Header>
					<p>
						{t('returnRequestSent')} <u>{modalContent?.submittedAt}</u>{' '}
						{t('through')} <u>{modalContent?.submissionMethod}</u>{' '}
						{t('withReference')} <u>{modalContent?.reference}</u>
					</p>
				</Modal.Header>
				<Modal.Body className="p-0">
					<Table
						firstColumnBold={false}
						striped
						smallTableHeadFont
						headerBorder
						boldHeader
						columns={[
							{ title: t('articleNumber'), width: '20%' },
							{ title: t('author'), width: '30%' },
							{ title: t('title'), width: '30%' },
							{ title: t('distributor'), width: '10%' },
							{ title: t('amount'), width: '10%' },
						]}
						items={
							modalContent?.entries?.map(
								({
									articleNumber,
									author,
									title,
									distributor,
									amount,
								}: any) => ({
									columns: [
										articleNumber,
										author,
										<a href={title.url}>{title.name}</a>,
										<a href={distributor.url}>{distributor.name}</a>,
										amount,
									],
								})
							) || []
						}
					/>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-end">
					<Button variant="secondary" onClick={closeModal}>
						{t('close')}
					</Button>
				</Modal.Footer>
			</Modal>
			<Tab.Container id="left-tabs-example" defaultActiveKey="current">
				<Nav variant="tabs" role="tablist" onSelect={switchTab}>
					<Nav.Item>
						<Nav.Link eventKey="current">{t('current')}</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="history">{t('history')}</Nav.Link>
					</Nav.Item>
				</Nav>
				<Card className="mt-3 nopadding">
					<Tab.Content>
						<Tab.Pane eventKey="current">
							<Card.Body>
								<Table
									firstColumnBold={false}
									striped
									smallTableHeadFont
									headerBorder
									boldHeader
									columns={[
										{ title: t('articleNumber'), width: '20%' },
										{ title: t('author'), width: '20%' },
										{ title: t('title'), width: '20%' },
										{ title: t('distributorShort'), width: '15%' },
										{ title: t('amount'), width: '10%' },
										{ title: '', width: '15%' },
									]}
									items={current.map(
										({
											articleNumber,
											author,
											title,
											distributors,
											selectedDistributor,
											amount,
										}: any) => ({
											columns: [
												articleNumber,
												author,
												<a href={title.url}>{title.name}</a>,
												distributors.map(({ name, url }: any) => (
													<FormCheck
														type="radio"
														label={name}
														defaultChecked={name === selectedDistributor}
													/>
												)),
												<FormControl defaultValue={amount} />,
												<div className="w-100 d-flex justify-content-end">
													<Button variant="outline-danger">
														<i className="bi bi-trash3 me-2" />
														{t('remove')}
													</Button>
												</div>,
											],
										})
									)}
								/>
							</Card.Body>
							<Card.Footer className="text-muted">
								<div className="d-flex justify-content-between align-items-end w-100">
									<div>
										<FormLabel>{t('reference')}</FormLabel>
										<FormControl placeholder="Ange referens" />
									</div>
									<div className="d-flex gap-3">
										<Button variant="secondary">
											<i className="bi bi-trash3 me-2" />
											{t('clear')}
										</Button>
										<Button variant="secondary">{t('update')}</Button>
										<Button variant="primary">{t('send')}</Button>
									</div>
								</div>
							</Card.Footer>
						</Tab.Pane>
						<Tab.Pane eventKey="history">
							<Card.Body>
								<Table
									firstColumnBold={false}
									smallTableHeadFont
									headerBorder
									boldHeader
									striped
									columns={[
										{ title: t('sent'), width: '20%' },
										{ title: t('reference'), width: '20%' },
										{ title: t('distributor'), width: '15%' },
										{ title: t('trackingNumber'), width: '15%' },
										{ title: t('sentThrough'), width: '15%' },
										{ title: t('contents'), width: '15%' },
									]}
									items={history.map(
										({
											submittedAt,
											reference,
											distributors,
											trackingNumber,
											submissionMethod,
										}: any) => ({
											columns: [
												submittedAt,
												reference,
												distributors.map(({ name, url }: any) => (
													<a className="d-block" href={url}>
														{name}
													</a>
												)),
												trackingNumber,
												submissionMethod,
												<Button
													variant="link"
													onClick={() => {
														setModalFetching('id');
													}}
												>
													{t('show')}
												</Button>,
											],
										})
									)}
								/>
							</Card.Body>
							<Card.Footer className="text-muted">
								<Pagination
									currentPage={current}
									totalItems={total}
									pageSize={5}
									setCurrent={setCurrent}
								/>
							</Card.Footer>
						</Tab.Pane>
					</Tab.Content>
				</Card>
			</Tab.Container>
		</>
	);
};
