import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { InformationIcon } from '../../shared/InformationIcon';
import { LockedOrChanged } from '../../shared/LockedOrChanged';
import { getChangedInformation, hasChanged, shouldBeLocked } from '../common';
import { t } from '../../../services/translationService';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { setLoader } from '../../../redux/reducers/loaderSlice.reducer';
import { getAutoComplete } from '../../../services/autocompleteService';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { postCopyInfoFields } from '../../../services/editBookService';
import NotificationClass from '../../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../../typescript/enums/enums';
import { Kapitel } from './Chapters'

interface Props {
  errors: any;
  register: any;
  control: any;
  getValues: any;
  setValue: any;
  blockRef: any;
  pageVariant: string;
  lockedFields: keyValue[];
  changedProps: ChangedProperty[];
  trigger: any;
}

export const Title = ({
  errors,
  register,
  control,
  blockRef,
  pageVariant,
  getValues,
  setValue,
  lockedFields,
  changedProps,
  trigger,
}: Props) => {
  const [serieList, setSerieList] = useState<any>([]);
  const [serieValue, setSerieValue] = useState<any>([]);
  const language = useAppSelector((state: any) => state.language.value);
  const dispatch = useAppDispatch();
  const [copyArticleNumber, setCopyArticleNumber] = useState<string>('');
  const showExtendedChangeInfo = pageVariant !== "publisher";

  const getSuggestions = useCallback(
    (queryString: string, type: string): void => {
      dispatch(setLoader(true));
      getAutoComplete(queryString, type, language)
        .then((data: any) => {
          setSerieList(data);
          dispatch(setLoader(false));
        })
        .catch((err) => console.error(err));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setSerieValue(getValues('serie'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue('serie', serieValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serieValue]);

  const updateCopyArticleNumber = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setCopyArticleNumber(value);
  };

  const setCopyInformationResponse = (responseData: any) => {
    responseData.forEach((keyValueObject: keyValue) => {
      let value;

      if (keyValueObject.key.toLocaleLowerCase() === 'serie') {
        if (keyValueObject.value) {
          value = [keyValueObject.value];
        } else {
          value = [];
        }
      } else {
        value = keyValueObject.value;
      }

      keyValueObject.key.toLocaleLowerCase() === 'serie' &&
        setSerieValue(value);
        
      setValue(keyValueObject.key.toLocaleLowerCase(), value);
    });
  };

  const copyInformation = () => {
    dispatch(setLoader(true));
    postCopyInfoFields(language, copyArticleNumber)
      .then((responseData: any) => {
        setCopyInformationResponse(responseData);
        dispatch(setLoader(false));
        NotificationClass.createNotification({
          type: NotificationTypes.Success,
          message: t('infoHarKopierats'),
        });
      })
      .catch((error: any) => {
        dispatch(setLoader(false));
        NotificationClass.createNotification({
          type: NotificationTypes.Error,
          message: error as string,
        });
      });
  };

  return (
    <>
      <h3 ref={blockRef}>{t('titelInformation')}</h3>
      <Col xs={12}>
        <hr className="u-text-grey" />
      </Col>
      <Card className="nopadding my-4">
        <Card.Body className="p-3">
          <Row>
            <Col md={9} sm={12}>
              <Row>
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('artikelnummer')}*
                      <InformationIcon
                        text={t('hjalpArtikelnummer')}
                        inline={true}
                        className="ps-1"
                      />
                    </Form.Label>
                    <LockedOrChanged
                      locked={shouldBeLocked(lockedFields, 'artikelnummer')}
                      changed={hasChanged(changedProps, 'artikelnummer')}
                      showExtendedInfo={showExtendedChangeInfo}
                      changedInformation={getChangedInformation(
                        changedProps,
                        'artikelnummer'
                      )}
                      child={
                        <Form.Control
                          placeholder={t('skrivEllips')}
                          {...register('artikelnummer', {
                            required: t('valideringArtikelnummer'),
                          })}
                          isInvalid={!!errors.artikelnummer}
                        />
                      }
                    />
                    {errors.artikelnummer && (
                      <Form.Control.Feedback type="invalid">
                        {`${errors.artikelnummer.message}`}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                {pageVariant !== 'large' && (
                  <Col xs={12}>
                    <div className="d-flex align-items-end w-100">
                      <Form.Group as={Col} className="mb-3 col me-3">
                        <Form.Label className="mb-1 text-body">
                          {t('kopieraInfoFran')}
                        </Form.Label>
                        <Form.Control
                          value={copyArticleNumber}
                          onChange={updateCopyArticleNumber}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3 col-auto">
                        <Button
                          className="primary"
                          type="button"
                          onClick={copyInformation}
                        >
                          {t('kopiera')}
                        </Button>
                      </Form.Group>
                    </div>
                  </Col>
                )}
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('titel')}
                      <InformationIcon
                        text={t('hjalpTitel')}
                        inline={true}
                        className="ps-1"
                      />
                    </Form.Label>
                    <LockedOrChanged
                      locked={shouldBeLocked(lockedFields, 'titel')}
                      changed={hasChanged(changedProps, 'titel')}
                      showExtendedInfo={showExtendedChangeInfo}
                      changedInformation={getChangedInformation(
                        changedProps,
                        'titel'
                      )}
                      child={
                        <Form.Control
                          placeholder={t('skrivEllips')}
                          {...register('titel')}
                          isInvalid={!!errors.titel}
                        />
                      }
                    />
                    {errors.titel && (
                      <Form.Control.Feedback type="invalid">
                        {`${errors.titel.message}`}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('arbetstitel')}*
                      <InformationIcon
                        text={t('hjalpArbetsTitel')}
                        inline={true}
                        className="ps-1"
                      />
                    </Form.Label>
                    <LockedOrChanged
                      locked={shouldBeLocked(lockedFields, 'arbetstitel')}
                      changed={hasChanged(changedProps, 'arbetstitel')}
                      showExtendedInfo={showExtendedChangeInfo}
                      changedInformation={getChangedInformation(
                        changedProps,
                        'arbetstitel'
                      )}
                      child={
                        <Form.Control
                          placeholder={t('skrivEllips')}
                          {...register('arbetstitel', {
                            required: t('valideringArbetsTitel'),
                          })}
                          isInvalid={!!errors.arbetstitel}
                        />
                      }
                    />
                    {errors.arbetstitel && (
                      <Form.Control.Feedback type="invalid">
                        {`${errors.arbetstitel.message}`}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('originaltitel')}
                      <InformationIcon
                        text={t('hjalpOriginalTitel')}
                        inline={true}
                        className="ps-1"
                      />
                    </Form.Label>
                    <LockedOrChanged
                      locked={shouldBeLocked(lockedFields, 'originaltitel')}
                      changed={hasChanged(changedProps, 'originaltitel')}
                      showExtendedInfo={showExtendedChangeInfo}
                      changedInformation={getChangedInformation(
                        changedProps,
                        'originaltitel'
                      )}
                      child={
                        <Form.Control
                          placeholder={t('skrivEllips')}
                          {...register('originaltitel')}
                          isInvalid={!!errors.orginaltitel}
                        />
                      }
                    />
                    {errors.orginaltitel && (
                      <Form.Control.Feedback type="invalid">
                        {`${errors.orginaltitel.message}`}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                {pageVariant === 'large' && (
                  <Col xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t('omslagstitel')}
                        <InformationIcon
                          text={t('hjalpOmslagsTitel')}
                          inline={true}
                          className="ps-1"
                        />
                      </Form.Label>
                      <LockedOrChanged
                        locked={false}
                        changed={hasChanged(changedProps, 'omslagstitel')}
                        showExtendedInfo={showExtendedChangeInfo}
                        changedInformation={getChangedInformation(
                          changedProps,
                          'omslagstitel'
                        )}
                        child={
                          <Form.Control
                            placeholder={t('skrivEllips')}
                            {...register('omslagstitel')}
                            isInvalid={!!errors.omslagstitel}
                          />
                        }
                      />
                    </Form.Group>
                  </Col>
                )}
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('upplagenummer')}
                      <InformationIcon
                        text={t('hjalpUpplageNummer')}
                        inline={true}
                        className="ps-1"
                      />
                    </Form.Label>
                    <LockedOrChanged
                      locked={shouldBeLocked(lockedFields, 'upplagenummer')}
                      changed={hasChanged(changedProps, 'upplagenummer')}
                      showExtendedInfo={showExtendedChangeInfo}
                      changedInformation={getChangedInformation(
                        changedProps,
                        'upplagenummer'
                      )}
                      child={
                        <Form.Control
                          placeholder={t('skrivEllips')}
                          {...register('upplagenummer', {
                            setValueAs: (value: any) => !!value ? value : null
                          })}
                          isInvalid={!!errors.upplagenummer}

                        />
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('serie')}
                      <InformationIcon
                        text={t('hjalpSerie')}
                        inline={true}
                        className="ps-1"
                      />
                    </Form.Label>
                    <LockedOrChanged
                      locked={shouldBeLocked(lockedFields, 'serie')}
                      changed={hasChanged(changedProps, 'serie')}
                      showExtendedInfo={showExtendedChangeInfo}
                      changedInformation={getChangedInformation(
                        changedProps,
                        'serie'
                      )}
                      child={
                        <AsyncTypeahead
                          id="serie"
                          labelKey={'value'}
                          isLoading={false}
                          options={serieList}
                          disabled={shouldBeLocked(lockedFields, 'serie')}
                          placeholder={t('skrivEllips')}
                          selected={serieValue}
                          allowNew={true}
                          newSelectionPrefix={t('laggTill')}
                          inputProps={{
                            className: `${errors.serie
                              ? 'is-invalid'
                              : hasChanged(changedProps, 'serie') &&
                                !shouldBeLocked(lockedFields, 'serie')
                                ? 'changed'
                                : ''
                              }`,
                          }}
                          filterBy={() => true}
                          onSearch={(query: string) => {
                            getSuggestions(query, 'series');
                          }}
                          onChange={(selected: any) => {
                            if (selected[0]?.customOption) {
                              setSerieValue([selected[0]?.value]);
                            } else {
                              setSerieValue(selected);
                            }
                          }}
                        />
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('lasordning')}
                      <InformationIcon
                        text={t('hjalpLasordning')}
                        inline={true}
                        className="ps-1"
                      />
                    </Form.Label>
                    <LockedOrChanged
                      locked={shouldBeLocked(lockedFields, 'lasordning')}
                      changed={hasChanged(changedProps, 'lasordning')}
                      showExtendedInfo={showExtendedChangeInfo}
                      changedInformation={getChangedInformation(
                        changedProps,
                        'lasordning'
                      )}
                      child={
                        <Form.Control
                          placeholder={t('skrivEllips')}
                          {...register('lasordning')}
                          isInvalid={!!errors.lasordning}
                        />
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('hanvisningsIsbn')}
                      <InformationIcon
                        text={t('hjalpHanvisningsIsbn')}
                        inline={true}
                        className="ps-1"
                      />
                    </Form.Label>

                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('hanvisningsisbn')}
                      isInvalid={!!errors.hanvisningsisbn}
                    />
                  </Form.Group>
                </Col>
                {pageVariant === 'large' && (
                  <Col xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t('marknadsserie')}
                        <InformationIcon
                          text={t('hjalpMarknadsSerie')}
                          inline={true}
                          className="ps-1"
                        />
                      </Form.Label>
                      <LockedOrChanged
                        locked={shouldBeLocked(lockedFields, 'lasordning')}
                        changed={hasChanged(changedProps, 'lasordning')}
                        showExtendedInfo={showExtendedChangeInfo}
                        changedInformation={getChangedInformation(
                          changedProps,
                          'lasordning'
                        )}
                        child={
                          <Form.Control
                            placeholder={t('skrivEllips')}
                            {...register('marknadsserie')}
                            isInvalid={!!errors.marknadsserie}
                          />
                        }
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </Col>
            <Col md={3} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 text-body">
                  {t('aktuellOmslagsbild')}
                </Form.Label>
                {getValues('omslagsbildUrl') ? (
                  <>
                    <img
                      className="d-block mv-100"
                      src={getValues('omslagsbildUrl')}
                      alt="omslagsbild"
                    />
                  </>
                ) : (
                  <Col
                    sm={12}
                    className="u-background-grey w-100 py-6 u-border u-border-radius d-flex justify-content-center"
                  >
                    <span className="u-text-grey px-3 text-center">
                      <i className="bi bi-info-circle pe-2" />
                      {t('ingenOmslagsbild')}
                    </span>
                  </Col>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
