import React from 'react';
import { useEffect, useState } from 'react';

export interface IPaginator {
	setCurrent?: any;
	currentPage: number;
	totalItems: number;
	pageSize: number;
	disabled?: boolean;
}

const LinkPill = ({
	onClick,
	text,
	leadingText,
	arrow,
	disabled = false,
}: {
	onClick: () => void;
	text: string;
	leadingText?: boolean;
	arrow: 'right' | 'left';
	disabled?: boolean;
}) => {
	const buttonHandler = (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		!disabled && onClick();
	};

	return (
		<div
			onClick={buttonHandler}
			role="button"
			className={`link-pill d-flex align-items-center u-border px-3 ${
				disabled ? 'opacity-50' : ''
			}`}
		>
			{leadingText && (
				<div className="d-none d-md-flex align-self-center">{text}</div>
			)}
			<div>
				<i
					className={`bi ${
						arrow === 'right' ? 'bi-arrow-right' : 'bi-arrow-left'
					} d-inline-block mt-1`}
				/>
			</div>
			{leadingText || (
				<div className="d-none d-md-flex align-self-center">{text}</div>
			)}
		</div>
	);
};

const generatePages = (currentPage: number, totalPages: number) => {
	const roundedTotalPages = Math.ceil(totalPages);
	const baseArray = Array(roundedTotalPages < 5 ? roundedTotalPages : 5).fill(
		0
	);

	if (currentPage < 3) return baseArray.map((_, i) => i);

	if (currentPage > roundedTotalPages - 3) {
		return baseArray.map((_, i) => i + roundedTotalPages - 5);
	}

	return baseArray.map((_, i) => i + currentPage - 2);
};

const PagePills = ({
	pageSelectedCallback,
	totalPages,
	currentPage,
	disabled = false,
}: {
	pageSelectedCallback: (page: number) => void;
	totalPages: number;
	currentPage: number;
	disabled?: boolean;
}) => {
	const pages = generatePages(currentPage, totalPages);

	return (
		<>
			<div
				className={`page-pills d-none d-md-flex align-items-center justify-content-center ${
					disabled ? 'opacity-50' : ''
				}`}
			>
				{pages.map((p) => {
					return (
						<div
							key={`pagepill-${p}`}
							role="button"
							onClick={disabled ? () => null : () => pageSelectedCallback(p)}
							className={`${
								p === currentPage ? 'u-background-blue u-text-primary' : ''
							}`}
						>
							{p + 1}
						</div>
					);
				})}
			</div>
			<div
				className={`page-pills d-flex d-md-none align-items-center justify-content-center ${
					disabled ? 'opacity-50' : ''
				}`}
			>
				<span>
					Sida {currentPage + 1} av {totalPages}
				</span>
			</div>
		</>
	);
};

export const Pagination = ({
	setCurrent,
	currentPage,
	totalItems,
	pageSize,
	disabled = false,
}: IPaginator) => {
	const isOnLastPage = (currentPage + 1) * pageSize >= totalItems;
	const isOnFirstPage = currentPage < 1;
	
	if (totalItems < pageSize || pageSize < 1) {
		return null;
	}

	return (
		<div className="pagination">
			<LinkPill
				disabled={disabled || isOnFirstPage}
				onClick={() => setCurrent(currentPage - 1)}
				text="Tidigare"
				arrow="left"
			/>
			<PagePills
				disabled={disabled}
				pageSelectedCallback={(page) => setCurrent(page)}
				currentPage={currentPage}
				totalPages={totalItems / pageSize}
			/>
			<LinkPill
				disabled={disabled || isOnLastPage}
				onClick={() => setCurrent(currentPage + 1)}
				text="Nästa"
				leadingText
				arrow="right"
			/>
		</div>
	);
};
