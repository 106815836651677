import { useEffect, useState } from 'react';

declare global {
	interface Window {
        grecaptcha: any,
        siteKey: string
	}
}

export const useReCaptcha = () => {

	useEffect(() => {
		const script = document.createElement('script');
		script.src = `https://www.google.com/recaptcha/api.js?render=${window.siteKey}`;
		document.body.appendChild(script);
	}, []);
};

export const validateUser = (callback: Function) => {
	window.grecaptcha.ready(() => {
		window.grecaptcha
			.execute(window.siteKey, { action: 'register' })
			.then((token: any) => callback(token));
	});
};
