import axios from 'axios';
import { IUserRestCallResult } from "../components/shared/Interfaces/ApiCallInterfaces";

export interface IIdentityError {
    code: string;
    description: string;
}

export interface IChangePriceScaleResult extends IUserRestCallResult {
    errorList: string[] | undefined;
}

export interface IChangePriceScaleForm {
    priceScale: string;
    priceScalePercentage: string;
}

export interface IChangePasswordResult extends IUserRestCallResult {
    errorList: IIdentityError[] | undefined;
}

export interface IChangePasswordForm {
    presentPassword: string,
    newPassword: string,
    confirmPassword: string
}

export interface IRecoverPasswordPageViewModel {
    submitConfirmMessage: string | undefined;
    submitState: boolean | undefined;
    PasswordForm: IRecoverPasswordForm | undefined;
}

export interface IRecoverPasswordForm {
    usernameOrEmail: string | undefined;
}

export class RecoverPasswordPageViewModel implements IRecoverPasswordPageViewModel {
    submitConfirmMessage: string | undefined;
    submitState: boolean | undefined;
    PasswordForm: IRecoverPasswordForm | undefined;
}

export class RecoverPasswordForm implements IRecoverPasswordForm {
    usernameOrEmail: string | undefined;
}

const recoverPassword = async (dataObject: RecoverPasswordForm, genericErrorMessage: string): Promise<IRecoverPasswordPageViewModel> => {
    try {
        const { data } = await axios.post<IRecoverPasswordPageViewModel>(`${document.location.pathname}/Reset`, dataObject, {
            headers: {
                Accept: 'application/json',
            },
        });

        return data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('error message: ', error.message);
            let errorModel = new RecoverPasswordPageViewModel();
            errorModel.submitState = false;
            errorModel.submitConfirmMessage = error.message;
            return errorModel
        } else {
            console.error('unexpected error: ', error);
            let errorModel = new RecoverPasswordPageViewModel();
            errorModel.submitState = false;
            errorModel.submitConfirmMessage = genericErrorMessage;
            return errorModel
        }
    }
}

const changePassword = async (dataObject: IChangePasswordForm, genericErrorMessage: string): Promise<IChangePasswordResult> => {
    try {
        const { data } = await axios.post<IChangePasswordResult>('/api/useroperations/changepassword', dataObject, {
            headers: {
                Accept: 'application/json',
                "Accept-Language": 'sv-SE'
            },
        });

        return data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('error message: ', error.message);
            let errorModel = {
                success: false,
                message: error.message,
                reason: error.code
            } as IChangePasswordResult;

            return errorModel;
        } else {
            let errorModel = {
                success: false,
                message: error,
                reason: "other"
            } as IChangePasswordResult;

            return errorModel;
        }
    }
}

const changePriceScale = async (dataObject: IChangePriceScaleForm, genericErrorMessage: string): Promise<IChangePriceScaleResult> => {
    try {
        const { data } = await axios.post<IChangePriceScaleResult>('/api/useroperations/changepricescale', dataObject, {
            headers: {
                Accept: 'application/json',
                "Accept-Language": 'sv-SE'
            },
        });

        return data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('error message: ', error.message);
            let errorModel = {
                success: false,
                message: error.message,
                reason: error.code
            } as IChangePriceScaleResult;

            return errorModel;
        } else {
            let errorModel = {
                success: false,
                message: error,
                reason: "other"
            } as IChangePriceScaleResult;

            return errorModel;
        }
    }
}

export { recoverPassword, changePassword, changePriceScale };