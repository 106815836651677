//import React, { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import {
	getRecentlyPublishedBooks,
	IRecentlyPublishedBooksViewModel,
	RecentlyPublishedBooksPostData,
	RecentlyPublishedBooksServiceError,
} from '../../services/recentlyPublishedBooksService';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../typescript/enums/enums';
import { Pagination } from '../shared/PaginationComponent';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { useAppDispatch } from '../../redux/hooks';
import useDebounce from '../../hooks/useDebounce';

declare global {
	interface Bokinfo {
		payload: Payload;
	}
}

interface Payload {
	pageAreas: Block[];
	message: string;
}

interface Block {
	title: string;
	themaCodes: string[];
	seedValue: number;
	pageSize: number;
	emptyMessage: string;
}

export const RecentlyPublishedBooksBlock = ({
	title,
	themaCodes,
	seedValue,
	pageSize,
	emptyMessage,
}: Block) => {
	const [page, setPage] = useState(0);
	const [model, setModel] = useState<IRecentlyPublishedBooksViewModel>();
	const [loading, setLoading] = useState(true);
	const dispatch = useAppDispatch();
	const debouncedLoading = useDebounce(loading, 500);

	useEffect(() => {
		dispatch(setLoader(debouncedLoading));
	}, [debouncedLoading, dispatch]);

	useEffect(() => {
		const postData: RecentlyPublishedBooksPostData = {
			themaCodes,
			page,
			pageSize,
			seedValue,
		};

		setLoading(true);

		getRecentlyPublishedBooks(postData)
			.then((result) => {
				if ('statusCode' in (result as RecentlyPublishedBooksServiceError)) {
					const errorMessage = result as RecentlyPublishedBooksServiceError;
					NotificationClass.createNotification({
						type: NotificationTypes.Error,
						message: `Något gick fel när ${title} laddades!`,
					});
				} else {
                    const booksVm = result as IRecentlyPublishedBooksViewModel;
					booksVm && setModel(booksVm);
				}
				setLoading(false);
			})
			.catch((e) =>
				NotificationClass.createNotification({
					type: NotificationTypes.Error,
					message: `Något gick fel när ${title} laddades!`,
				})
			);
	}, [page]);

	return (
		<section className="Block py-md-5 my-md-5 py-3 my-3 border-bottom">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2 className="h2 d-inline-block">{title}</h2>
					</div>
				</div>
				<div className="row mt-3 mb-5">
					{!loading && !model?.books.length && (
						<div className="col text-center">{emptyMessage}</div>
					)}
					{model &&
						model.books.map((book, idx) => {
							return (
								<div
									key={book.title}
									className="col-md-2 col-sm-12 mt-3 mt-md-0"
								>
                                    <div className="card mt-3">
                                        {model.showBookLink ?
										<><a href={book.bookUrl}>
											<img
												className="img-fluid card__image card__image--limit-height mx-auto d-block"
												src={book.imageUrl}
												alt={book.title}
											/>
                                        </a>
                                         <a href={book.bookUrl} className="u-text-decoration-none">
                                            <div className="u-text-semibold u-chevron-after-right u-text-black pt-4">
                                                {book.title.length > 24
                                                    ? `${book.title.substring(0, 24)}...`
                                                    : book.title}
                                            </div>
                                                </a></> : <><img
												className="img-fluid card__image card__image--limit-height mx-auto d-block"
												src={book.imageUrl}
												alt={book.title}
											/>
                                            <div className="u-text-semibold u-chevron-after-right u-text-black pt-4">
                                            {book.title.length > 24
                                                ? `${book.title.substring(0, 24)}...`
                                                : book.title}
                                                </div></>}
										<div className="small pt-1 d-block">
											{book.authors?.length ? book.authors[0] : null}
										</div>
										<div className="card__list"></div>
									</div>
								</div>
							);
						})}
				</div>
				<div className="row d-none d-md-block">
					<Pagination
						currentPage={page}
						pageSize={12}
						totalItems={model?.totalCount ?? 0}
						setCurrent={setPage}
					/>
				</div>
			</div>
		</section>
	);
};

export const RecentlyPublishedBooksPageComponent = () => {
	const [areas, setAreas] = useState<Block[]>();

	const { pageAreas, message } = window.bokinfo.payload;

	useEffect(() => {
		setAreas(pageAreas);
	}, []);

	return (
		<div>
			{areas?.length &&
				areas.map((area, idx) => {
					return (
						<div key={idx} className="row justify-content-center">
							<RecentlyPublishedBooksBlock
								title={area.title}
								themaCodes={area.themaCodes}
								seedValue={area.seedValue}
								pageSize={area.pageSize}
								emptyMessage={message}
							/>
						</div>
					);
				})}
		</div>
	);
};
