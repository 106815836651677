import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useFieldArray } from 'react-hook-form';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import {
	getBooleanOptions,
	getDragClass,
	getDragContainerClass,
	getItemStyle,
	getOptions,
	getOptionsLabel,
} from '../common';
import { t } from '../../../services/translationService';
import { setLoader } from '../../../redux/reducers/loaderSlice.reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getAutoComplete } from '../../../services/autocompleteService';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';

interface Props {
	errors: any;
	register: any;
	control: any;
	getValues: any;
	setValue: any;
	blockRef: any;
	pageVariant: string;
	lockedFields: keyValue[];
	changedProps: ChangedProperty[];
}

export const Learning = ({
	errors,
	register,
	control,
	getValues,
	blockRef,
	pageVariant,
}: Props) => {
	const [laromedelstyp, setLaromedelstyp] = useState<any>('');
	const [skolamne, setSkolamne] = useState<any>([]);
	const [skolamneList, setSkolamneList] = useState<any>([]);
	const [utbildningsNiva, setUtbildningsNiva] = useState<any>();
	const [gymnasieprogram, setGymnasieprogram] = useState<any>();
	const skolamneRef = useRef<Typeahead>(null);
	const language = useAppSelector((state: any) => state.language.value);
    const dispatch = useAppDispatch();

	const {
		fields: laromedelstyper,
		append: appendLaromedelsTyp,
		move: moveLaromedelsTyp,
		remove: removeLaromedelsTyp,
	} = useFieldArray({
		control,
		name: 'laromedelstyper',
	});

	const {
		fields: skolamnen,
		append: appendSkolamne,
		move: moveSkolamne,
		remove: removeSkolamne,
	} = useFieldArray({
		control,
		name: 'skolamnen',
	});

	const {
		fields: utbildningsnivaer,
		append: appendUtbildningsniva,
		move: moveUtbildningsniva,
		remove: removeUtbildningsniva,
	} = useFieldArray({
		control,
		name: 'utbildningsnivaer',
	});

	const {
		fields: gymnasieprogramLista,
		append: appendGymnasieprogram,
		move: moveGymnasieprogram,
		remove: removeGymnasieprogram,
	} = useFieldArray({
		control,
		name: 'gymnasieprogram',
	});

	const updateLaromedelstyp = (event: ChangeEvent<HTMLInputElement>): void => {
		let value: string = (event.target as HTMLInputElement).value;
		setLaromedelstyp(value);
	};

	const updateUtbildningsniva = (
		event: ChangeEvent<HTMLInputElement>
	): void => {
		let value: string = (event.target as HTMLInputElement).value;
		setUtbildningsNiva(value);
	};

	const updateGymnasieprogram = (
		event: ChangeEvent<HTMLInputElement>
	): void => {
		let value: string = (event.target as HTMLInputElement).value;
		setGymnasieprogram(value);
	};

	/**
	 * On row drag end
	 */
	const onLaromedelsTypDragEnd = (result: any) => {
		moveLaromedelsTyp(result.source.index, result.destination.index);
	};
	const onSkolamnepDragEnd = (result: any) => {
		moveSkolamne(result.source.index, result.destination.index);
	};
	const onUtbildningsNivaDragEnd = (result: any) => {
		moveUtbildningsniva(result.source.index, result.destination.index);
	};
	const onGymnasieprogramDragEnd = (result: any) => {
		moveGymnasieprogram(result.source.index, result.destination.index);
	};

	const addLaromedelsTyp = () => {
		if (laromedelstyp !== '') {
			const result = window.bokinfo.dictionaries['teachingAidTypes'].filter(
				(obj: any) => {
					return obj.key === laromedelstyp;
				}
			);

			appendLaromedelsTyp(result);
			setLaromedelstyp('');
		}
	};
	const addSkolamne = () => {
		appendSkolamne(skolamne);
		skolamneRef.current?.clear();
		setSkolamne([]);
	};
	const addUtbildningsniva = () => {
		if (utbildningsNiva !== '') {
			const result = window.bokinfo.dictionaries['educationLevels'].filter(
				(obj: any) => {
					return obj.key === utbildningsNiva;
				}
			);

			appendUtbildningsniva(result);
			setUtbildningsNiva('');
		}
	};
	const addGymnasieprogram = () => {
		if (gymnasieprogram !== '') {
			const result = window.bokinfo.dictionaries['highSchoolPrograms'].filter(
				(obj: any) => {
					return obj.key === gymnasieprogram;
				}
			);
			appendGymnasieprogram(result);
			setGymnasieprogram('');
		}
	};

	const getSuggestions = useCallback(
		(queryString: string, type: string): void => {
			dispatch(setLoader(true));
			getAutoComplete(queryString, type, language)
                .then((data: any) => {
					setSkolamneList(data);
					dispatch(setLoader(false));
				})
				.catch((err) => console.log(err));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
    );

	return (
		<>
			<h3 ref={blockRef}>{t('laromedel')}</h3>
			<Col xs={12}>
				<hr className="u-text-grey" />
			</Col>
			<Card className="nopadding my-4">
				<Card.Body className="p-3">
					<Row>
						<Col xs={12} md={6}>
							<Form.Group className="mb-3">
								<Form.Label className="mb-1 text-body">
									{t('laromedel')}
								</Form.Label>
								<Form.Control
									as={'select'}
									type="select"
                                    className="form-select"
									placeholder={t('valjEllips')}
									{...register('laromedel', {
                                        setValueAs: (v: any) => (v === true || v === 'true')
									})}
									isInvalid={!!errors.laromedel}
								>
									{getBooleanOptions()}
								</Form.Control>
							</Form.Group>
						</Col>
						{pageVariant !== 'quick' && (
							<>
								<Col xs={12}>
									<div className="d-flex align-items-end w-100">
										<Form.Group as={Col} className="mb-3 col me-3">
											<Form.Label className="mb-1 text-body">
												{t('laromedelsTyp')}
											</Form.Label>
											<Form.Control
												as={'select'}
												type="select"
												className="form-select"
												placeholder={t('valjEllips')}
												value={laromedelstyp}
												onChange={updateLaromedelstyp}
											>
												{getOptions('teachingAidTypes')}
											</Form.Control>
										</Form.Group>
										<Form.Group as={Col} className="mb-3 col-auto">
											<Button
												className="primary"
												type="button"
												onClick={addLaromedelsTyp}
											>
												<i className="bi bi-plus"></i>
											</Button>
										</Form.Group>
									</div>
								</Col>
								<DragDropContext onDragEnd={onLaromedelsTypDragEnd}>
									<Droppable droppableId="droppableLaromedel">
										{(provided: any, snapshot: any) => (
											<Col
												xs={12}
												className={`mb-3 ${getDragContainerClass(
													snapshot.isDraggingOver
												)}`}
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{laromedelstyper.map((row: any, index: number) => {
													return (
														<Draggable
															key={row.id}
															draggableId={row.id}
															index={index}
														>
															{(provided: any, snapshot: any) => (
																<Row
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}
																	key={row.id}
																	className={`${
																		index % 2 === 1 ? 'u-background-grey' : ''
																	} mt-2 p-1 ${getDragClass(
																		snapshot.isDragging
																	)}`}
																>
																	<Col
																		xs={11}
																		className="d-flex align-items-center"
																	>
																		{getOptionsLabel(
																			'teachingAidTypes',
																			getValues(`laromedelstyper.${index}.key`)
																		)}
																	</Col>
																	<Col
																		xs={1}
																		className="d-flex align-items-center"
																	>
																		<i
																			className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
																			onClick={() => removeLaromedelsTyp(index)}
																		/>
																	</Col>
																</Row>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</Col>
										)}
									</Droppable>
								</DragDropContext>
								<Col xs={12}>
									<hr className="u-text-grey" />
								</Col>
							</>
						)}
						<Col xs={12}>
							<div className="d-flex align-items-end w-100">
								<Form.Group as={Col} className="mb-3 col me-3">
									<Form.Label className="mb-1 text-body">
										{t('skolamne')}
									</Form.Label>
									<Controller
										control={control}
										name="skolamne"
										render={({ field, fieldState }) => (
											<AsyncTypeahead
												{...field}
												id="skolamne"
												labelKey={'value'}
												isLoading={false}
												options={skolamneList}
												placeholder={t('skrivEllips')}
												ref={skolamneRef}
												inputProps={{
													className: `${errors.serie ? 'is-invalid' : ''}`,
												}}
												filterBy={() => true}
												onSearch={(query: string) => {
													getSuggestions(query, 'SchoolSubjects');
												}}
												onChange={(selected: any) => {
													setSkolamne(selected);
												}}
											/>
										)}
									/>
								</Form.Group>
								<Form.Group as={Col} className="mb-3 col-auto">
									<Button
										className="primary"
										type="button"
										onClick={addSkolamne}
									>
										<i className="bi bi-plus"></i>
									</Button>
								</Form.Group>
							</div>
						</Col>
						<DragDropContext onDragEnd={onSkolamnepDragEnd}>
							<Droppable droppableId="droppableSkolamne">
								{(provided: any, snapshot: any) => (
									<Col
										xs={12}
										className={`mb-3 ${getDragContainerClass(
											snapshot.isDraggingOver
										)}`}
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{skolamnen.map((row: any, index: number) => {
											return (
												<Draggable
													key={row.id}
													draggableId={row.id}
													index={index}
												>
													{(provided: any, snapshot: any) => (
														<Row
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={getItemStyle(
																snapshot.isDragging,
																provided.draggableProps.style
															)}
															key={row.id}
															className={`${
																index % 2 === 1 ? 'u-background-grey' : ''
															} mt-2 p-1 ${getDragClass(snapshot.isDragging)}`}
														>
															<Col
																xs={11}
																className="d-flex align-items-center"
															>
																{getValues(`skolamnen.${index}.value`)}
															</Col>
															<Col xs={1} className="d-flex align-items-center">
																<i
																	className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
																	onClick={() => removeSkolamne(index)}
																/>
															</Col>
														</Row>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</Col>
								)}
							</Droppable>
						</DragDropContext>
						<Col xs={12}>
							<hr className="u-text-grey" />
						</Col>
						<Col xs={12}>
							<div className="d-flex align-items-end w-100">
								<Form.Group as={Col} className="mb-3 col me-3">
									<Form.Label className="mb-1 text-body">
										{t('utbildningsniva')}
									</Form.Label>
									<Form.Control
										as={'select'}
										type="select"
										className="form-select"
										placeholder={t('valjEllips')}
										value={utbildningsNiva}
										onChange={updateUtbildningsniva}
									>
										{getOptions('educationLevels')}
									</Form.Control>
								</Form.Group>
								<Form.Group as={Col} className="mb-3 col-auto">
									<Button
										className="primary"
										type="button"
										onClick={addUtbildningsniva}
									>
										<i className="bi bi-plus"></i>
									</Button>
								</Form.Group>
							</div>
						</Col>
						<DragDropContext onDragEnd={onUtbildningsNivaDragEnd}>
							<Droppable droppableId="droppableUtbildningsnivå">
								{(provided: any, snapshot: any) => (
									<Col
										xs={12}
										className={`mb-3 ${getDragContainerClass(
											snapshot.isDraggingOver
										)}`}
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{utbildningsnivaer.map((row: any, index: number) => {
											return (
												<Draggable
													key={row.id}
													draggableId={row.id}
													index={index}
												>
													{(provided: any, snapshot: any) => (
														<Row
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={getItemStyle(
																snapshot.isDragging,
																provided.draggableProps.style
															)}
															key={row.id}
															className={`${
																index % 2 === 1 ? 'u-background-grey' : ''
															} mt-2 p-1 ${getDragClass(snapshot.isDragging)}`}
														>
															<Col
																xs={11}
																className="d-flex align-items-center"
															>
																{getOptionsLabel(
																	'educationLevels',
																	getValues(`utbildningsnivaer.${index}.key`)
																)}
															</Col>
															<Col xs={1} className="d-flex align-items-center">
																<i
																	className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
																	onClick={() => removeUtbildningsniva(index)}
																/>
															</Col>
														</Row>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</Col>
								)}
							</Droppable>
						</DragDropContext>
						<Col xs={12}>
							<hr className="u-text-grey" />
						</Col>
						{pageVariant !== 'quick' && (
							<>
								<Col xs={12}>
									<div className="d-flex align-items-end w-100">
										<Form.Group as={Col} className="mb-3 col me-3">
											<Form.Label className="mb-1 text-body">
												{t('gymnasieprogram')}
											</Form.Label>
											<Form.Control
												as={'select'}
												type="select"
												className="form-select"
												placeholder={t('valjEllips')}
												value={gymnasieprogram}
												onChange={updateGymnasieprogram}
											>
												{getOptions('highSchoolPrograms')}
											</Form.Control>
										</Form.Group>
										<Form.Group as={Col} className="mb-3 col-auto">
											<Button
												className="primary"
												type="button"
												onClick={addGymnasieprogram}
											>
												<i className="bi bi-plus"></i>
											</Button>
										</Form.Group>
									</div>
								</Col>
								<DragDropContext onDragEnd={onGymnasieprogramDragEnd}>
									<Droppable droppableId="droppablegymnasieprogram">
										{(provided: any, snapshot: any) => (
											<Col
												xs={12}
												className={`mb-3 ${getDragContainerClass(
													snapshot.isDraggingOver
												)}`}
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{gymnasieprogramLista.map((row: any, index: number) => {
													return (
														<Draggable
															key={row.id}
															draggableId={row.id}
															index={index}
														>
															{(provided: any, snapshot: any) => (
																<Row
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}
																	key={row.id}
																	className={`${
																		index % 2 === 1 ? 'u-background-grey' : ''
																	} mt-2 p-1 ${getDragClass(
																		snapshot.isDragging
																	)}`}
																>
																	<Col
																		xs={11}
																		className="d-flex align-items-center"
																	>
																		{getOptionsLabel(
																			'highSchoolPrograms',
																			getValues(`gymnasieprogram.${index}.key`)
																		)}
																	</Col>
																	<Col
																		xs={1}
																		className="d-flex align-items-center"
																	>
																		<i
																			className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
																			onClick={() =>
																				removeGymnasieprogram(index)
																			}
																		/>
																	</Col>
																</Row>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</Col>
										)}
									</Droppable>
								</DragDropContext>
							</>
						)}
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};
