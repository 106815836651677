import { Button } from 'react-bootstrap';
import { CartItem } from '../../redux/types/types';
import { t } from '../../services/translationService';
import { CartMobileItem } from './CartMobileItem';

interface Props {
	items: CartItem[];
	setItems: any;
	setInitialItems: any;
	clear: any;
	toggleAll: any;
}

export const CartMobile = ({
	items,
	setItems,
	setInitialItems,
	clear,
	toggleAll,
}: Props) => {
	return (
		<>
			<div className="d-flex justify-content-between mt-5 border-bottom">
				<span className="py-4" onClick={toggleAll}>
					<i className="bi bi-check pe-1 lh-1" />
					{t('selectAll')}
				</span>
				<Button
					variant="secondary"
					onClick={clear}
					className="d-sm-none border-0"
				>
					<i className="bi bi-trash3 pe-2 lh-1" />
					{t('clearCart')}
				</Button>
			</div>
			{items.map((item, index) => (
				<CartMobileItem
					key={`"${item.catalogEntryCode}-${index}"`}
					item={item}
					setItems={setItems}
					setInitialItems={setInitialItems}
				/>
			))}
			<div className="d-flex justify-content-end mb-3">
				<Button
					variant="secondary"
					onClick={clear}
					className="d-sm-none border-0"
				>
					<i className="bi bi-trash3 pe-2 lh-1" />
					{t('clearCart')}
				</Button>
			</div>
		</>
	);
};
