import axios from 'axios';

export interface CertificatesViewModel {
    total: number;
    certificates: Certificate[];
}

export interface SendCertificateModel {
    certificateId: string;
    code: string;
}

export interface Certificate {
    id: number,
    isbn: string,
    modified: string,
    status: number,
    statusText: string,
    title: string
}

export interface CertificateLog {
    created: string;
    text: string;
}

export interface CertificateServiceError {
    message: string;
    statusCode: string;
}

export interface CodedValue {
    key: string,
    value: string
}

export interface Options {
    glnSell: SelectDropdown,
    glnBuy: SelectDropdown,
    status: SelectDropdown,
    productClassCategory: SelectDropdown,
    palletType: SelectDropdown
}

export interface SelectDropdown {
    name: string,
    type: string,
    value: CodedValue,
    values: { [key: string]: string; }
}
export interface Pallet {
    palletItemsPerLayer: string,
    palletLayers: string,
    palletTotalHeight: string,
    palletTotalItems: string,
    palletTotalWeight: string
}
export interface FullCertificate {
    id: number,
    buyerGln: string,
    created: string,
    sellerGln: string,
    itemArticleId: string,
    modified: string,
    status: number,
    statusText: string,
    title: string,
    userId: string,
    validoo_CertificateId: string,
    productClassCategory: string,
    tradename: string,
    trademark_Author: string,
    shelfEdge_ReceiptText: string,
    width: number,
    height: number,
    backwidth: number,
    weight: number,
    vat: number,
    price: number,
    availabilityDate: string,
    palletType: string,
    palletLayers: string,
    palletItemsPerLayer: string,
    palletTotalHeight: string,
    palletTotalItems: string,
    palletTotalWeight: string,
    gtin: string,
    gtinPallet: string,
    dropDowns: Options,
    edit: string,
    errorMessage: string,
    gTIN: string,
    gTINPallet: string,
    iSBN: string,
    latestChange: Date
}

export const getCertificate = async (isbn: string): Promise<FullCertificate | CertificateServiceError> => {
    try {

        const { data } = await axios.get<FullCertificate>(
            `/sv-se/api/certificates/certificate/${isbn}`,
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return { message: error.message, statusCode: error.code || "" }
        } else {
            console.log('unexpected error: ', error);
            return { message: "Okänt fel", statusCode: "Okänd status" }
        }
    }
}

export const calculatePallet = async (type: string, height: number, width: number, backWidth: number, weight: number): Promise<Pallet | undefined> => {
    try {

        const { data } = await axios.get<Pallet>(
            `/sv-se/api/certificates/calculatepallet?type=${type}&height=${height}&width=${width}&back=${backWidth}&weight=${weight}`,
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return undefined
        } else {
            console.log('unexpected error: ', error);
            return undefined
        }
    }
}

export const saveCertificate = async (cert: FullCertificate) => {
    try {

        const { data } = await axios.post<boolean>(
            `/sv-se/api/certificates/save`,
            cert,
            {
                headers: {
                    Accept: 'application/json',
                },
            }
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return { message: error.message, statusCode: error.code || "" }
        } else {
            console.log('unexpected error: ', error);
            return { message: "Okänt fel", statusCode: "Okänd status" }
        }
    }
}

export const getCertificates = async (
    searchQuery: string,
    sortBy: number,
    page: number,
    pageSize: number
): Promise<CertificatesViewModel | CertificateServiceError> => {

    let dataObject = {
        searchQuery,
        sortBy,
        page,
        pageSize
    }

    try {

        const { data } = await axios.post<CertificatesViewModel>(
            `/sv-se/api/certificates/search/`,
            dataObject,
            {
                headers: {
                    Accept: 'application/json',
                },
            }
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return { message: error.message, statusCode: error.code || "" }
        } else {
            console.log('unexpected error: ', error);
            return { message: "Okänt fel", statusCode: "Okänd status" }
        }
    }

}

export const getCertificateHistory = async (
    certificateId: string
): Promise<CertificateLog[] | CertificateServiceError> => {

    try {
        const { data } = await axios.get<CertificateLog[]>(`/sv-se/api/certificates/history/${certificateId}`);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return { message: error.message, statusCode: error.code || "" }
        } else {
            console.log('unexpected error: ', error);
            return { message: "Okänt fel", statusCode: "Okänd status" }
        }
    }
}

export const sendCertificate = async (id: number, isbn: string) => {
    try {
        const { data } = await axios.post<boolean>(
            `/sv-se/api/certificates/send/`,
            {certificateId: id, code: isbn},
            {
                headers: {
                    Accept: 'application/json',
                },
            }
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return { message: error.message, statusCode: error.code || '' };
        } else {
            console.log('unexpected error: ', error);
            return { message: 'Ok�nt fel', statusCode: 'Ok�nd status' };
        }
    }
};
