import React, { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Table, TableColumn, TableItem } from '../shared/Table';
import { Pagination } from '../shared/PaginationComponent';
import {
    MediaPublicationMedia,
    MediaPublication,
} from '../shared/Interfaces/MediaPublication';
import axios, { AxiosResponse } from 'axios';

declare global {
    interface Bokinfo {
        editUrl: string;
    }
}

const renderMedia = (publications: string) => {
    if (!publications?.length) return;

    const mediaObj = JSON.parse(publications) as MediaPublicationMedia[];
    return mediaObj.map((m) => {
        return (
            <div key={m?.name || ''} className="row">
                <div className="col">{m?.name}</div>
                <div className="col">{m?.date}</div>
            </div>
        );
    });
};

export const PublicationsComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(-1);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(0);

    const [items, setItems] = useState<TableItem[]>();
    interface ILinkButton {
        url?: string;
        role?: string;
        className?: string;
        onClick?: () => void;
    }

    useEffect(() => {
        const LinkButton = (props: ILinkButton) => {
            return (
                <div>
                    {props.url ? (
                        <a href={props.url}>
                            <i role="button" className={props.className}></i>
                        </a>
                    ) : props.onClick && (
                        <i onClick={props.onClick} role="button" className={props.className}></i>
                    )}
                </div>
            )
        }

        const deletePublication = async (catalogEntryCode: string) => {
            const url = "/api/publications/";

            try {
                const response: AxiosResponse = await axios.delete(
                    url,
                    {
                        data: `"${catalogEntryCode}"`,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                );

                const total:number = JSON.parse(response.data.total) as number;

                setTotalItems(total);

                // handle success
            } catch (e) {
                // handle error
                console.log("Error", e);
            }
        }

        setIsLoading(true);

        if (currentPage > -1 || totalItems === -1) {
            fetch(`/api/publications/${currentPage}`)
                .then((content) =>
                    content.json().then((res) => {
                        res?.total && setTotalItems(res.total);
                        res?.pagesize && setPageSize(res.pagesize);

                        const items = (res.items as MediaPublication[]).map((p) => {
                            let editUrl = window.bokinfo.editUrl
                                ? `${window.bokinfo.editUrl}?id=${p.catalogEntryCode}`
                                : '';
                            return {
                                columns: [
                                    p.title,
                                    p.authors,
                                    p.catalogEntryCode,
                                    renderMedia(p.publications),
                                    <LinkButton url={editUrl} className="bi bi-pencil" />,
                                    <LinkButton onClick={() => deletePublication(p.catalogEntryCode)} className="bi bi-trash3 u-text-error" />
                                ],
                            } as TableItem;
                        });

                        setItems(items);

                        setIsLoading(false);
                    })
                )
                .catch((e) => console.log(e));
        }
    }, [currentPage, totalItems]);

    return (
        <div
            className={`container u-border shadow-sm g-0 ${isLoading && 'u-blurred'}`}
        >
            <div className="row small">
                {items && (
                    <Table
                        columns={[
                            { title: 'Titel', width: '30%' },
                            { title: 'Författare', width: '20%' },
                            { title: 'Artikelnummer', width: '20%' },
                            { title: 'Media', width: '26%' },
                            { key: 'EditButton', width: '2%' },
                            { key: 'DeleteButton', width: '2%' }
                        ]}
                        items={items}
                    />
                )}
            </div>
            <div className="row gx-0 pt-3 pb-3 ps-3 small">
                {totalItems > 0 && (
                    <Pagination
                        setCurrent={setCurrentPage}
                        currentPage={currentPage}
                        totalItems={totalItems}
                        pageSize={pageSize}
                        disabled={isLoading}
                    />
                )}
            </div>
        </div>
    );
};
