import { useEffect, useState } from 'react';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from "../../typescript/enums/enums";
import { addItem } from '../../services/CartService';

import {
    SearchResult,
    SearchResultItem,
} from '../../redux/types/types';
import { SearchResultListItem } from './SearchResultListItem';
import { useDispatch } from 'react-redux';
import { setCartSize } from '../../redux/reducers/cartSlice.reducer';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { t } from '../../services/translationService';

interface Props {
    searchResultList: SearchResult;
    returnProps: (key: string, value: string) => void;
    editBookPageBaseUrl: string;
    editDigitalProductsPageUrl: string;
    editCertificateUrl: string;
    publisher: boolean;
    admin: boolean;
    orderAccess: boolean;
    csvCallback: (extended: boolean, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const SearchResultComponent = ({
    searchResultList,
    returnProps,
    editBookPageBaseUrl,
    editDigitalProductsPageUrl,
    editCertificateUrl,
    publisher,
    admin,
    orderAccess,
    csvCallback
}: Props) => {
    const [searchResult, setSearchResult] = useState<SearchResult>(
        {} as SearchResult
    );

    const dispatch = useDispatch();

    useEffect(() => {
        setSearchResult(searchResultList);
    }, [searchResultList]);

    const onSelectedDistributor = (item: SearchResultItem, distributorId: number) => {
        const currentState = ({ ...searchResult } as unknown) as SearchResult;
        let stateItems = ([...searchResult.items] as unknown) as SearchResultItem[];
        let stateItem: SearchResultItem | undefined = undefined;
        let mutateId = -1;

        for (let i = 0; i < stateItems.length; i++) {
            if (stateItems[i].catalogEntryCode === item.catalogEntryCode) {
                stateItem = stateItems[i];
                mutateId = i;
            }
        }

        if (stateItem && mutateId > -1) {
            stateItems[mutateId].selectedDistributor = distributorId
            currentState.items = stateItems;
            setSearchResult(currentState);
        }

    };

    const addItemToCart = (catalogEntryCode: string, quantity: number, selectedDistributorId: number, selectedDistributorShort: string, reference: string) => {
        dispatch(setLoader(true));
        addItem(catalogEntryCode, quantity, selectedDistributorId, selectedDistributorShort, reference)
            .then((res) => {
                dispatch(setCartSize(res?.data
                    .map((p: any) => p.amount)
                    .reduce((p: any, c: any) => p + c, 0)));

                NotificationClass.createNotification({
                    type: NotificationTypes.Success,
                    message: 'Artikeln lades till i varukorgen'

                });
                
			    dispatch(setLoader(false));
            }).catch(() => {
                NotificationClass.createNotification({
                    type: NotificationTypes.Error,
                    message: 'Artikeln kunde inte l�ggas till!'
                });
			    dispatch(setLoader(false));
            });
    }

    return (
        <>
            {searchResult.items &&
                searchResult.items.length > 0 &&
                searchResult.items.map(
                    (resultItem: SearchResultItem, index: number) => {
                        return <SearchResultListItem
                            key={resultItem.catalogEntryCode}
                            searchResultItem={resultItem}
                            index={index}
                            addToCart={addItemToCart}
                            onSelectedDistributor={onSelectedDistributor}
                            returnProps={returnProps}
                            editBookPageBaseUrl={editBookPageBaseUrl}
                            editDigitalProductsPageUrl={editDigitalProductsPageUrl}
                            editCertificateUrl={editCertificateUrl}
                            publisher={publisher}
                            admin={admin}
                            orderAccess={orderAccess}
                        />
                    }
                )}
            {searchResult.items?.length > 0 && searchResult.items.length < 500 && <a href="#" className="d-block mt-2" onClick={(e) => csvCallback(false, e)}>{t('exportToCsv')}</a>}
            {searchResult.items?.length > 0 && admin && <a href="#" className="d-block" onClick={(e) => csvCallback(true, e)}> Exportera till Csv - Utökad</a>}
        </>
    );
};
