import axios from 'axios';

export interface IRecentlyPublishedBookViewModel {

    title: string,
    imageUrl: string,
    bookUrl: string,
    publisher?: string,
    authors: string[]

}

export interface IRecentlyPublishedBooksViewModel {
    books: IRecentlyPublishedBookViewModel[],
    totalCount: number,
    showBookLink: boolean
}
export interface RecentlyPublishedBooksServiceError {
    message: string;
    statusCode: string;
}
export interface RecentlyPublishedBooksPostData {
    themaCodes: string[],
    page: number,
    pageSize: number,
    seedValue: number
}

const getRecentlyPublishedBooks = async (dataObject: RecentlyPublishedBooksPostData): Promise<IRecentlyPublishedBooksViewModel | RecentlyPublishedBooksServiceError> => {
    try {
        const { data } = await axios.post<IRecentlyPublishedBooksViewModel>('/api/recentlypublishedbooks', dataObject, {
            headers: {
                Accept: 'application/json',
            },
        });

        return data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('error message: ', error.message);
            return { message: error.message, statusCode: error.code || "" }
        } else {
            console.error('unexpected error: ', error);
            return { message: "Ok�nt fel", statusCode: "Ok�nd status" }
        }
    }
}



export { getRecentlyPublishedBooks };