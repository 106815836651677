import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Col, Row, Button, Modal, Form } from 'react-bootstrap';

import {
  SearchResultItem,
  SearchResultItemPrice,
} from '../../redux/types/types';

import { t } from '../../services/translationService';
import { StatusIcon } from '../shared/StatusIcon';
import { useKeyEvent } from '../../hooks/useKeyPressed';

interface Props {
  searchResultItem: SearchResultItem;
  index: number;
  addToCart: (
    catalogEntryCode: string,
    quantity: number,
    selectedDistributorId: number,
    selectedDistributorShort: string,
    reference: string
  ) => void;
  onSelectedDistributor: (
    item: SearchResultItem,
    distributorId: number
  ) => void;
  returnProps: (key: string, value: string) => void;
  editBookPageBaseUrl: string;
  editDigitalProductsPageUrl: string;
  editCertificateUrl: string;
  publisher: boolean;
  admin: boolean;
  orderAccess: boolean;
}

export const SearchResultListItem = ({
  searchResultItem,
  index,
  addToCart,
  onSelectedDistributor,
  returnProps,
  editBookPageBaseUrl,
  editDigitalProductsPageUrl,
  editCertificateUrl,
  publisher,
  admin,
  orderAccess,
}: Props) => {
  const [numberOfItems, setNumberOfItems] = useState('1');
  const [reference, setReference] = useState('');
  const [selectedDistributorId, setSelectedDistributorId] = useState(
    searchResultItem.firstSelectableDistributor
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const onChangeSelectedDistributor = (distributorId: number) => {
    onSelectedDistributor(searchResultItem, distributorId);
    setSelectedDistributorId(distributorId);
  };

  const addItemToCart = () => {
    let selectedDistributorShortName = '';
    setReference('');
    setShowModal(false);
    for (const price of searchResultItem.prices) {
      if (price.distributorId === selectedDistributorId) {
        selectedDistributorShortName = price.distributorShortName;
      }
    }
    const numberOfItemsParsed = Number(numberOfItems);
    if (numberOfItemsParsed > 0) {
      addToCart(
        searchResultItem.catalogEntryCode,
        numberOfItemsParsed,
        selectedDistributorId,
        selectedDistributorShortName,
        reference
      );
    }
  };

  const onKeyEvent = useKeyEvent;

  return (
    <Row>
      <Col>
        <Modal show={showModal} size="sm" centered>
          <Modal.Header>
            {t('addReference')}
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">
                {t('reference')}
              </Form.Label>
              <Form.Control
                type={'text'}
                value={reference}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => onKeyEvent(e, [
                  { eventKey: "Enter", onEvent: () => addItemToCart() },
                  { eventKey: "Escape", onEvent: () => { setShowModal(false); setReference(""); } }
                ])}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setReference(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => addItemToCart()}>{t('buttonOk')}</Button>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              {t('buttonClose')}
            </Button>
          </Modal.Footer>
        </Modal>
        <div
          className={`search__result__item ${searchResultItem.reviewed && admin ? 'search__result__item--reviewed' : ''
            }`}
        >
          <Row className="p-3">
            <Col sm={12} md={12} lg={2}>
              <a href={searchResultItem.itemUrl}>
                <img
                  className="u-border-radius img-fluid mx-auto d-block search__result__item__image"
                  src={searchResultItem.imageUrl}
                  alt=""
                ></img>
              </a>
            </Col>
            <Col sm={12} md={12} lg={10}>
              <Row>
                <Col className="ps-0">
                  <button
                    className="btn btn-link title"
                    onClick={() => returnProps('titel', searchResultItem.title)}
                  >
                    <h4 className="h4 no-visited u-chevron-after-right title">
                      {searchResultItem.title}
                    </h4>
                  </button>
                </Col>
              </Row>
              <Row>
                <Col className="author ps-0">
                  Av:{' '}
                  {searchResultItem.authors?.map(
                    (author: string, index: number) => {
                      return (
                        <span key={index}>
                          {index < 2 &&
                            searchResultItem.authors.length - 1 > index ? (
                            <>
                              <button
                                className="no-visited btn btn-link"
                                onClick={() => returnProps('medarbetare', author)}
                              >
                                {author}
                              </button>
                              {', '}
                            </>
                          ) : (
                            <button
                              className="no-visited btn btn-link"
                              onClick={() => returnProps('medarbetare', author)}
                            >
                              {author}
                            </button>
                          )}
                        </span>
                      );
                    }
                  )}
                </Col>
                <Col md={12}>
                  <Row className="mt-2">
                    <Col sm={8}>
                      <Row>
                        <Col sm={12} className="prop u-background-grey">
                          <span className="description">{t('isbnEan')}:</span>
                          <span className="value">
                            <a href={searchResultItem.itemUrl} className="no-visited">
                              {searchResultItem.catalogEntryCode}
                            </a>
                          </span>
                        </Col>
                        <Col sm={12} className="prop">
                          <span className="description">{t('series')}:</span>
                          <span className="value">
                            <button
                              className="no-visited btn btn-link"
                              onClick={() =>
                                returnProps('serie', searchResultItem.series)
                              }
                            >
                              {searchResultItem.series}
                            </button>{' '}
                            / {searchResultItem.readingOrder}
                          </span>
                        </Col>
                        <Col sm={12} className="prop u-background-grey">
                          <span className="description">{t('publisher')}:</span>
                          <span className="value">
                            <a href={searchResultItem.publisherLink}>
                              {searchResultItem.publisher}
                            </a>
                          </span>
                        </Col>
                        <Col sm={12} className="prop">
                          <span className="description">
                            {t('publishDate')}:
                          </span>
                          <span className="value">
                            {searchResultItem.releaseDate}
                          </span>
                        </Col>
                        <Col sm={12} className="prop u-background-grey">
                          <span className="description">{t('format')}:</span>
                          <span className="value">
                            {searchResultItem.format}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} md={4} className="mt-3 mt-md-0">
                      <Row>
                        <Col sm={12} className="u-text-semibold">
                          {t('orderFrom')}:
                        </Col>
                        {searchResultItem.prices.map(
                          (
                            price: SearchResultItemPrice,
                            priceIndex: number
                          ) => {
                            const selectedDistributor =
                              price.distributorId ===
                              searchResultItem.selectedDistributor ||
                              (!searchResultItem.selectedDistributor &&
                                price.distributorId ===
                                searchResultItem.firstSelectableDistributor);
                            return (
                              <Col
                                key={
                                  searchResultItem.catalogEntryCode +
                                  '-' +
                                  price.distributorId
                                }
                                sm={12}
                                className="d-flex align-items-center gap-2"
                              >
                                {orderAccess && (
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={'price_' + index + priceIndex}
                                    id={price.distributorShortName}
                                    checked={selectedDistributor}
                                    disabled={!price.isSellable}
                                    onChange={() =>
                                      onChangeSelectedDistributor(
                                        price.distributorId
                                      )
                                    }
                                  />)}
                                <label>
                                  <a href="# " className="no-visited">
                                    {price.distributorShortName}
                                  </a>
                                </label>
                                <span className={price.isDiscounted ? "u-text-red" : ""} data-bs-toggle="tooltip" data-bs-placement="bottom" title={price.isDiscounted ? price.publisherPrice.toFixed(2) + ", Rea F-Pris" : price.publisherPrice.toFixed(2) + ", F-Pris"}>
                                  {price.retailPrice.toFixed(2)} kr
                                </span>
                                <StatusIcon
                                  status={price.stockStatus}
                                  description={price.stockStatusDescription}
                                  amount={price.stockBalance}
                                />
                              </Col>
                            );
                          }
                        )}
                        {orderAccess && searchResultItem.sellable && (
                          <Col className="mt-2">
                            <input
                              className="w-40 w-sm-20 sm"
                              type={'number'}
                              value={numberOfItems}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => setNumberOfItems(e.target.value)}
                              onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => onKeyEvent(e, [
                                { eventKey: "Enter", onEvent: () => setShowModal(true) },
                              ])}
                              disabled={
                                !searchResultItem.firstSelectableDistributor ||
                                searchResultItem.firstSelectableDistributor === 0
                              }
                            />
                            <Button
                              className="btn-sm mb-1 ms-2"
                              color="primary"
                              onClick={() => setShowModal(true)}
                              disabled={
                                !searchResultItem.firstSelectableDistributor ||
                                searchResultItem.firstSelectableDistributor === 0
                              }
                            >
                              {t('buttonAdd')}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {(publisher || admin) && (
            <Row>
              <Col>
                <div className="search__result__item__footer">
                  <Row className="p-3">
                    <Col className="text-end">
                      {(publisher || admin) && editDigitalProductsPageUrl?.length && searchResultItem.media === "Nedladdningsbar bok" && (
                        <Button
                          className="me-3"
                          variant="secondary"
                          href={`${editDigitalProductsPageUrl}&isbn=${searchResultItem.catalogEntryCode}`}
                        >
                          {t('buttonManageDigitalProducts')}
                        </Button>
                      )}
                      {(publisher || admin) && editBookPageBaseUrl?.length && (
                        <Button
                          className="me-3"
                          variant="secondary"
                          href={`${editBookPageBaseUrl}${searchResultItem.catalogEntryCode}`}
                        >
                          {t('buttonEdit')}
                        </Button>
                      )}
                      {searchResultItem.canEditCertificate && editCertificateUrl?.length && (
                        <Button
                          className=""
                          disabled={!searchResultItem.canEditCertificate}
                          variant="secondary"
                          href={`${editCertificateUrl}${searchResultItem.catalogEntryCode}`}
                        >
                          {t('buttonCreateCertificate')}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};
