import { Table } from 'react-bootstrap';
import { CartItem } from '../../redux/types/types';
import { t } from '../../services/translationService';
import { CartTableRow } from './CartTableRow';

interface Props {
    items: CartItem[];
    setItems: any;
    setInitialItems: any;
    toggleAll: any;
    updateCart?: Function;
    hasChanged?: boolean;
    submitCart?: Function;
}

export const CartTable = ({
    items,
    setItems,
    setInitialItems,
    toggleAll,
    updateCart,
    hasChanged,
    submitCart
}: Props) => {

    return (
        <Table className="mt-3 mb-3" borderless>
            <thead>
                <tr className="border-top border-bottom align-middle">
                    <th
                        className="py-4 lh-1"
                        onClick={toggleAll}
                        style={{ fontSize: '.75rem', cursor: 'pointer' }}
                    >
                        <div className='d-flex align-items-center'>
                            <i className="bi bi-check pe-1 lh-1" />
                            {t('selectAll')}
                        </div>
                    </th>
                    <th>{t('productDetails')}</th>
                    <th>{t('distributor')}</th>
                    <th>{t('price')}</th>
                    <th>{t('amount')}</th>
                    <th>{t('reference')}</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item) => (
                    <CartTableRow
                        key={item.cartItemId}
                        item={item}
                        setItems={setItems}
                        setInitialItems={setInitialItems}
                        updateCart={updateCart}
                        hasChanged={hasChanged}
                        submitCart={submitCart}
                    />
                ))}
            </tbody>
        </Table>
    );
};
