import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface cartState {
	size: number;
}

const initialState: cartState = {
	size: 0,
};

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		setCartSize: (state, action: PayloadAction<number>) => {
			state.size = action.payload;
		},
	},
});

export const { setCartSize } = cartSlice.actions;

export default cartSlice.reducer;
