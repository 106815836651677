import axios from "axios";
import { BrowserRouter, Navigate } from "react-router-dom";
import { receiveMessageOnPort } from "worker_threads";
import { ApplicationResult, Notification } from "../redux/types/types";

const getApplications = async (): Promise<ApplicationResult> => {
	try {
	  const { data } = await axios.get<ApplicationResult>(`${document.location.pathname}/aktiva`);
	  return data;
	} catch (error) {
	  if (axios.isAxiosError(error)) {
		console.log('error message: ', error.message);
		return {} as ApplicationResult;
	  } else {
		console.log('unexpected error: ', error);
		return {} as ApplicationResult;
	  }
	}
}

const getApplicationHistory = async (page:number): Promise<ApplicationResult> => {
	try {
	  const { data } = await axios.get<ApplicationResult>(`${document.location.pathname}slutforda/?page=${page}`);
	  return data;
	} catch (error) {
	  if (axios.isAxiosError(error)) {
		console.log('error message: ', error.message);
		return {} as ApplicationResult;
	  } else {
		console.log('unexpected error: ', error);
		return {} as ApplicationResult;
	  }
	}
}

enum ReCaptchaError {
    NoToken = 0,
    LowScore = 1,
    Generic = 2,
    Ok = 3
}

interface RegistrationResult {
    validationResult: RecaptchaValidationResult,
    formSent: boolean
}
interface ValidationResult {
    validationResult: {[key:string]: [string]},
}
interface RecaptchaValidationResult {
    valid: boolean,
    errorMessage: ReCaptchaError
}

export const updateApplication = async (form: any, status: any) => {
    try {
        const { data } = await axios.post<Notification>(`${document.location.pathname.replace("granska/", "")}uppdatera/?action=${status}`, form, {
            headers: {
                Accept: 'application/json',
            },
        });

        localStorage.setItem("updateApplicationNotification", JSON.stringify(data));

        document.location.href = `/${document.location.pathname.split("/").filter(item => item).slice(0, -1).join("/") }/`
    }
    catch (error) {
        console.log(error);
    }
}

export const validateForm = async (form: any): Promise<ValidationResult | undefined> => {
    try {
        const { data } = await axios.post<ValidationResult>(`${document.location.pathname}/Validate`, { "formValues": form }, {
            headers: {
                Accept: 'application/json',
            },
        });
        return data;
    }
    catch (error) {
        console.log(error)
    }
}

export const sendApplication = async (reCaptchaToken: string, form: any): Promise<RegistrationResult | undefined> => {
    try {
        const { data } = await axios.post<RegistrationResult>(`${document.location.pathname}/Send`, { "reCaptchaToken": reCaptchaToken, "formValues": form} , {
            headers: {
                Accept: 'application/json',
            },
        });
        return data;
    }
    catch (error) {
        console.log(error)
    }
}

export { getApplicationHistory, getApplications }