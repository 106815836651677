export interface KeyEventModel {
  eventKey: string;
  onEvent: Function;
}

export function useKeyEvent(event: KeyboardEvent, models: KeyEventModel[]) {
  models.forEach(model => {
    if (event.key === model.eventKey) {
      model.onEvent();
    }
  });
}
// export useKeyEvent for use in javascript
(window as any).useKeyEvent = useKeyEvent;