import { Card, Col, Form, Row } from 'react-bootstrap';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { InformationIcon } from '../../shared/InformationIcon';
import { LockedOrChanged } from '../../shared/LockedOrChanged';
import { shouldBeLocked, hasChanged, getChangedInformation } from '../common';
import { t } from '../../../services/translationService';

interface Props {
	errors: any;
	register: any;
	blockRef: any;
	pageVariant: string;
	lockedFields: keyValue[];
	changedProps: ChangedProperty[];
}

export const Comments = ({
	errors,
	register,
	blockRef,
	pageVariant,
	lockedFields,
	changedProps,
}: Props) => {
	return (
		<>
			<h3 ref={blockRef}>{t('kommentarer')}</h3>
			<Col xs={12}>
				<hr className="u-text-grey" />
			</Col>
			<Card className="nopadding my-4">
				<Card.Body className="p-3">
					<Row>
						<Col xs={12} md={6}>
							<Form.Group className="mb-3">
								<Form.Label className="mb-1 text-body">
									{t('internKommentar')}
								</Form.Label>
								<Form.Control
									placeholder={t('skrivEllips')}
									{...register('internKommentar')}
									isInvalid={!!errors.InternKommentar}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};
