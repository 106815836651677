import React, { useCallback, useEffect, useState } from 'react';
import {
    MediaPublication,
    MediaPublicationMedia,
} from '../shared/Interfaces/MediaPublication';
import {
    PostPublications,
    GetPublications,
    PublicationServiceError,
} from '../../services/publicationService';
import { t } from '../../services/translationService';
import NotificationClass from '../../typescript/classes/NotificationClass';
import { NotificationTypes } from '../../typescript/enums/enums';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';

registerLocale('sv', sv);

declare global {
    interface Bokinfo {
        catalogEntryCode: string;
    }
}

interface IRenderMedia {
    publications: MediaPublicationMedia[];
    changeCallback: (i: number, e: React.ChangeEvent<HTMLInputElement>) => void;
    changeDateCallback: (index: number, date: Date | null) => void;
    removeCallback: (m: MediaPublicationMedia) => void;
}

const RenderMedia = ({
    publications,
    changeCallback,
    changeDateCallback,
    removeCallback,
}: IRenderMedia) => {
    return (
        <div className="row">
            <div className="col-12">
                {publications.map((media, publication) => {
                    return (
                        <div
                            key={publication}
                            className={`row mt-3 pt-2 gx-0 pt-3 ${publication > 0 && 'border-top'}`}
                        >
                            <PublicationFormItem
                                textCallback={changeCallback}
                                dateCallback={changeDateCallback}
                                index={publication}
                                publication={media}
                            />
                            <div className="row justify-content-start mt-3 gx-0 pe-1">
                                <div className="col-12 text-end">
                                    <div className="row">
                                        <div
                                            role="button"
                                            onClick={() => removeCallback(media)}
                                            className="col small u-text-error text-end"
                                        >
                                            <i className="bi bi-trash3" />
                                            <span className="ms-2">{t("delete")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const PublicationFormItem = ({
    publication,
    index,
    dateCallback,
    textCallback,
}: {
    publication: MediaPublicationMedia;
    index: number;
    dateCallback: (index: number, date: Date | null) => void;
    textCallback: (i: number, e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    const parseDateString = (dateString: string) => {
        const timestamp = Date.parse(dateString);

        return isNaN(timestamp) ? null : new Date(timestamp);
    };

    const [publicationDate, setPublicationDate] = useState<Date | null>();

    useEffect(() => {
        if (publicationDate == null && publication.date) {
            let date: Date | null = parseDateString(publication.date);

            if (date) {
                setPublicationDate(date);
            }
        }
    }, [publication.date, publicationDate]);

    return (
        <div className="row gx-0">
            <div className="small my-1">{t("source")}</div>
            <input
                className="form-control"
                type="text"
                name="name"
                onChange={(e) => textCallback(index, e)}
                value={publication.name}
            />
            <div className="small my-1">{t("link")}</div>
            <input
                className="form-control"
                type="text"
                placeholder="http://"
                name="link"
                onChange={(e) => textCallback(index, e)}
                value={publication.link}
            />
            <div className="small my-1">{t("date")}</div>

            <DatePicker
                locale="sv"
                dateFormat="yyyy-MM-dd"
                className="form-control"
                name="date"
                onChangeRaw={(e) => {
                    const newDate = parseDateString(e.currentTarget.value);

                    if (newDate instanceof Date && !isNaN(newDate.getTime())) {
                        setPublicationDate(newDate);
                    }
                }}
                onChange={(date, e) => {
                    setPublicationDate(date);
                    dateCallback(index, date);
                }}
                selected={publicationDate}
                placeholderText={t('yyyymmdd')}
            />
        </div>
    );

};

export const EditPublicationComponent = () => {
    const [item, setItem] = useState<MediaPublication>();
    const [publications, setPublications] = useState<MediaPublicationMedia[]>([
        { name: '', date: '', link: '' },
    ]);
    const [catalogEntryCode] = useState<string>(window.bokinfo.catalogEntryCode);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoader(true));
        catalogEntryCode &&
            GetPublications(catalogEntryCode)
                .then((res) => {
                    if ('statusCode' in (res as PublicationServiceError)) {
                        const errorMessage = res as PublicationServiceError;
                        errorMessage && NotificationClass.createNotification({
                            type: NotificationTypes.Error,
                            message: errorMessage.message,
                        });
                    } else {
                        setItem(res as MediaPublication);
                    }
                    dispatch(setLoader(false));
                })
                .catch((e) => {
                    console.log(e);
                    NotificationClass.createNotification({
                        type: NotificationTypes.Error,
                        message: t("generalerror"),
                    });
                });
    }, [catalogEntryCode]);

    useEffect(() => {
        if (item) {
            const itemPublications =
                item.publications &&
                (JSON.parse(item.publications) as MediaPublicationMedia[]);
            if (itemPublications) {
                setPublications(itemPublications);
            }
        }
    }, [item]);

    const handleChange = useCallback(
        (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
            if (publications?.length) {
                let values = [...publications];
                values[i][e.target.name] = e.target.value;
                setPublications(values);
            }
        },
        [publications]
    );

    const handleDateChange = useCallback(
        (index: number, date: Date | null) => {
            if (publications?.length) {
                let values = publications;
                values[index].date = date?.toLocaleDateString('sv-SE') ?? "";
                setPublications([...values]);
            }
        },
        [publications]
    );

    const AddPublication = () => {
        let current = [...publications] || [];
        if (current[current.length - 1]?.name?.length) {
            const addItem = { name: '', date: '', link: '' };
            current.push(addItem);
            setPublications(current);
        }
    };

    const RemovePublication = (publication: MediaPublicationMedia) => {
        setPublications((prev) =>
            prev?.filter(
                (x) =>
                    x.link !== publication.link ||
                    x.date !== publication.date ||
                    x.name !== publication.name
            )
        );
    };

    const SaveAndPublish = async () => {
        dispatch(setLoader(true));

        for (const publication of publications) {
            if (!publication.name?.length) {
                NotificationClass.createNotification({
                    type: NotificationTypes.Warning,
                    message: t("sourceMissing"),
                });
                dispatch(setLoader(false));
                return;
            }
            if (!Date.parse(publication.date)) {
                NotificationClass.createNotification({
                    type: NotificationTypes.Warning,
                    message: t("dateMissing"),
                });
                dispatch(setLoader(false));
                return;
            }
        }

        const returnData = await PostPublications({
            catalogEntryCode: catalogEntryCode,
            publisherId: item?.publisherId,
            publications: publications.length ? publications : [],
        });

        dispatch(setLoader(false));
        returnData.errorMessage?.length
            ? NotificationClass.createNotification({
                type: NotificationTypes.Error,
                message: returnData.errorMessage,
            })
            : NotificationClass.createNotification({
                type: NotificationTypes.Success,
                message: t("published"),
            });
    };

    const Preview = () => {
        if (!item) return null;

        return (
            <div className="card">
                <img
                    className="img-fluid card__image card__image--limit-height mx-auto"
                    src={item.imageUrl}
                    alt=""
                />
                <a href={item.productPageUrl} className="u-text-decoration-none">
                    <h4 className="h4 u-chevron-after-right u-text-black pt-4">
                        {item.title}
                    </h4>
                </a>
                <div className="small pt-1 d-block">{item.authors}</div>
                <div className="card__list">
                    <ul>
                        {publications.map((pub, idx) => (
                            <li key={idx} className="small">
                                {pub.link ? (
                                    <a target="_blank" href={pub.name}>
                                        {pub.name}
                                    </a>
                                ) : (
                                    pub.name
                                )}
                                <span className="float-end">{pub.date}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-7">
                    <InfoBlock
                        heading={t("articleNumber")}
                        text={item?.catalogEntryCode || ''}
                    />
                    <InfoBlock heading={t("title")} text={item?.title || ''} />
                    <InfoBlock heading={t("author")} text={item?.authors || ''} />
                    <RenderMedia
                        changeCallback={handleChange}
                        changeDateCallback={handleDateChange}
                        removeCallback={RemovePublication}
                        publications={publications}
                    />
                    <div className="col-12 col-md-3">
                        <button
                            type="button"
                            onClick={AddPublication}
                            className="btn btn-primary btn-sm w-100 mt-4 py-0"
                        >
                            <i className="bi bi-plus me-1" />
                            {t("addMedia")}
                        </button>
                    </div>
                </div>
                <div className="col-10 col-md-3 offset-1 mt-4 mt-md-0">
                    <Preview />
                </div>
                <div className="row border-top mt-4 py-3 pe-0">
                    <div className="col pe-0 text-end">
                        <button className="btn btn-primary" onClick={SaveAndPublish}>
                            {t("publish")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


const InfoBlock = ({ heading, text }: { heading: string; text: string }) => {
    return (
        <div className="row mb-4">
            <div className="small mb-1">{heading}</div>
            <div className="regular">{text}</div>
        </div>
    );
};

