import { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';
import { t } from '../../../services/translationService';
import { getOptionsString } from '../common';

interface Props {
  errors: any;
  register: any;
  control: any;
  getValues: any;
  setValue: any;
  trigger: any;
  blockRef: any;
  pageVariant: string;
  errorRows?: number[];
}

export const Eudr = ({
  errors,
  register,
  control,
  getValues,
  trigger,
  blockRef,
  pageVariant,
  errorRows,
}: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  const {
    fields: eudrDokument,
    append: appendEudrDokument,
    remove: removeEudrDokument,
    update: updateEudrDokument
  } = useFieldArray({
    control,
    name: 'eudrDokument',
  });

  const saveEudrDokument = () =>
    trigger(
      `eudrDokument.${currentIndex !== undefined ? currentIndex : eudrDokument.length - 1
      }`
    ).then((validResult: boolean) => {
      if (validResult) {
        const last = getValues(`eudrDokument.${eudrDokument.length - 1}`);

        currentIndex === undefined && removeEudrDokument(eudrDokument.length - 1);
        currentIndex !== undefined
          ? updateEudrDokument(
            currentIndex,
            getValues(`eudrDokument.${currentIndex}`)
          )
          : appendEudrDokument({
            ...last,
          });
        setShowModal(false);
      }
    });

  const displayModal = (index?: number) => {
    index === undefined ? appendEudrDokument({}) : setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    currentIndex === undefined && removeEudrDokument(eudrDokument.length - 1);
  };

  return (
    <>
      <h3 ref={blockRef}>{t("eudr")}</h3>
      <Col xs={12}>
        <hr className="u-text-grey" />
      </Col>
      <Card className="my-4 nopadding">
        <Card.Body>
          {eudrDokument?.length > 0 && (
            <div className='mb-3 w-100 table'>
              <div className="small px-3 py-1 d-flex border-bottom">
                <div style={{ width: "20%" }}>
                  <span className="d-block">{t('eudrReferens')}</span>
                </div>
                <div style={{ width: "10%" }}>
                  <span className="d-block">{t('eudrTyp')}</span>
                </div>
                <div style={{ width: "15%" }}>
                  <span className="d-block">{t('eudrLand')}</span>
                </div>
                <div style={{ width: "15%" }}>
                  <span className="d-block">{t('eudrDatum')}</span>
                </div>
                <div style={{ width: "30%" }}>
                  <span className="d-block">{t('eudrKoordinater')}</span>
                </div>
                <div style={{ width: "10%" }}>
                  <span className="d-block"></span>
                </div>
              </div>
              <div>
                {eudrDokument.map((row: any, index: number) => {
                  return (
                    <div
                      key={row.id}
                      className={`${index % 2 === 1 ? 'u-background-grey' : ''
                        } d-flex gx-0 px-3 u-text-small-medium u-text-grey py-3 align-items-center border-bottom`}
                    >
                      <div style={{ width: "20%" }}>
                        {getValues(`eudrDokument.${index}.referens`)}
                      </div>
                      <div style={{ width: "10%" }}>
                        {getValues(`eudrDokument.${index}.typ`)}
                      </div>
                      <div style={{ width: "15%" }}>
                        {getValues(`eudrDokument.${index}.ursprungsland`)}
                      </div>
                      <div style={{ width: "15%" }}>
                        <span className="text-truncate d-block">
                          {getValues(`eudrDokument.${index}.avverkningsdatum`)}
                        </span>
                      </div>
                      <div style={{ width: "30%" }}>
                        <span className="text-truncate d-block">
                          {getValues(`eudrDokument.${index}.gpskoordinater`)}
                        </span>
                      </div>
                      <div style={{ width: "10%" }}>
                        <div className="d-flex gap-3 justify-content-end">
                          <i
                            className="bi bi-pencil u-text-primary u-cursor-pointer"
                            onClick={() => displayModal(index)}
                          />
                          <i
                            className="bi bi-trash3 u-text-error u-cursor-pointer"
                            onClick={() => removeEudrDokument(index)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Card.Body>
        <Card.Footer>
          <Button
            type="button"
            className="primary"
            onClick={() => displayModal()}
          >
            <i className="bi bi-plus" />
            {t('laggTill')}
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        show={showModal}
        centered
        size="lg"
        onHide={closeModal}
        onExited={() => setCurrentIndex(undefined)}
      >
        <Modal.Header>
          <p>{t("laggTill")}</p>
        </Modal.Header>
        <Modal.Body>
          {eudrDokument.map((row: any, index: number) => {
            return (
              index ===
              (currentIndex !== undefined
                ? currentIndex
                : eudrDokument.length - 1) && (
                <Row key={index}>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t("eudrReferens")}*
                      </Form.Label>
                      <Form.Control
                        key={row.id}
                        placeholder={t('skrivEllips')}
                        {...register(`eudrDokument.${index}.referens`,
                          { required: t("valideringEudrReferens") })
                        }
                        isInvalid={
                          !!errors.eudrDokument && errors.eudrDokument[index]?.referens
                        }
                      />
                      {!!errors.eudrDokument &&
                        errors.eudrDokument[index]?.referens && (
                          <Form.Control.Feedback type="invalid">
                            {`${errors.eudrDokument[index].referens.message}`}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t("eudrTyp")}
                      </Form.Label>
                      <Form.Control
                        as={'select'}
                        type="select"
                        className="form-select"
                        placeholder={t('valjEllips')}
                        {...register(`eudrDokument.${index}.typ`)}
                      >
                        {getOptionsString('eudrSectionTypes')}
                      </Form.Control>
                      {!!errors.eudrDokument &&
                        errors.eudrDokument[index]?.typ && (
                          <Form.Control.Feedback type="invalid">
                            {`${errors.eudrDokument[index].typ.message}`}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t("eudrLand")}
                      </Form.Label>
                      <Form.Control
                        key={row.id}
                        placeholder={t('skrivEllips')}
                        {...register(`eudrDokument.${index}.ursprungsland`)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t("eudrDatum")}
                      </Form.Label>
                      <Form.Control
                        key={row.id}
                        placeholder={t('skrivEllips')}
                        {...register(`eudrDokument.${index}.avverkningsdatum`, {
                          pattern: { value: /^\d{4}(-\d{2})?(-\d{2})?$/, message: t("valideringEudrDatum") }
                        })}
                        isInvalid={
                          !!errors.eudrDokument && errors.eudrDokument[index]?.avverkningsdatum
                        }
                      />
                      {!!errors.eudrDokument &&
                        errors.eudrDokument[index]?.avverkningsdatum && (
                          <Form.Control.Feedback type="invalid">
                            {`${errors.eudrDokument[index].avverkningsdatum.message}`}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 text-body">
                        {t("eudrKoordinater")}
                      </Form.Label>
                      <Form.Control
                        key={row.id}
                        placeholder={t('skrivEllips')}
                        {...register(`eudrDokument.${index}.gpskoordinater`)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            {t('avbryt')}
          </Button>
          <Button variant="primary" onClick={saveEudrDokument}>
            {t('spara')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
