import { t } from '../../services/translationService';

const statuses = [
	{
		name: 'InStock',
		color: '#12B76A',
		icon: '',
		iconSize: undefined,
	},
	{
		name: 'TemporaryOutOfStock',
		color: '#FEC84B',
		icon: 'bi-exclamation',
		iconSize: 17,
	},
	{
		name: 'OrderItem',
		color: '#12B76A',
		icon: 'bi-clock-history',
		iconSize: 11,
	},
	{
		name: 'NotPublished',
		color: '#53B1FD',
        icon: 'bi-dash',
		iconSize: 11,
	},
	{
		name: 'OutOfStock',
		color: '#FEC84B',
		icon: 'bi-exclamation',
		iconSize: 17,
	},
	{
		name: 'WillNotBePublished',
		color: '#D92D20',
        icon: 'bi-three-dots',
		iconSize: 11,
	},
	{
		name: 'Blocked',
		color: '#D92D20',
		icon: 'bi-x',
		iconSize: 17,
	},
	{
		name: 'DefinitelyOutOfStock',
		color: '#D92D20',
		icon: 'bi-dash',
		iconSize: 17,
	},
	{
		name: 'AwaitingReprintDecision',
		color: '#53B1FD',
		icon: 'bi-three-dots',
		iconSize: 17,
	},
];

interface Props {
	status: string;
	amount?: number;
	description?: string;
	label?: boolean;
}

export const StatusIcon = ({ status, amount, description, label }: Props) => {
	const { name, color, icon, iconSize }: any = statuses.find(
		(s) => s.name.toLowerCase() === status.toLowerCase()
	) || { name: '', color: '', icon: '', iconSize: 0 };
	
	return (
		<div className="d-flex gap-1 align-items-center" title={description}>
			<div className="status-icon" style={{ background: color }}>
				{icon && (
					<i
						className={`bi ${icon} icon d-flex align-items-center justify-content-center u-text-black`}
						style={{
							fontSize: iconSize,
						}}
					/>
				)}
			</div>
			{amount ? (
				<span
					className="amount d-flex align-items-center"
					style={{
						color: color,
					}}
				>
					({amount >= 100 ? '>100' : amount})
				</span>
			) : (
				''
			)}
			{label && (
				<span className="ms-1">
					{t(name.charAt(0).toLowerCase() + name.substr(1))}
				</span>
			)}
		</div>
	);
};
