import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAppSelector } from '../../redux/hooks';

export const LoaderComponent = () => {
	const loading = useAppSelector((state: any) => state.loader.value);
	const delay = useAppSelector((state: any) => state.loader.delay);
	const [loaderShow, setLoaderShow] = useState<boolean>(false);

	useEffect(() => {
		if (!loading) {
			setLoaderShow(loading);
		} else {
			const timer = setTimeout(() => {
				setLoaderShow(loading);
			}, delay);

			return () => {
				clearTimeout(timer);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	return (
		<div className={`loader ${loaderShow ? 'opacity-100' : 'opacity-0'}`}>
			<Spinner
				animation="border"
				role="status"
				className="loader__spinner"
				variant="primary"
			>
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
};
