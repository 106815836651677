import { changePassword, IChangePasswordResult, IChangePasswordForm, IIdentityError } from "../../services/userService";

export default class changePasswordClass {
    changePasswordForm: HTMLFormElement;
    presentPasswordField: HTMLInputElement;
    newPasswordField: HTMLInputElement;
    confirmPasswordField: HTMLInputElement;
    submitbutton: HTMLButtonElement;
    alertBox: HTMLDivElement;

    static init() {
        let form = document.getElementById("changepwdform") as HTMLFormElement;

        if (form) {
            new changePasswordClass(form);
        }
    }

    constructor(form: HTMLFormElement) {
        this.changePasswordForm = form;
        this.changePasswordForm.addEventListener("submit", (e: Event) => {
            this.submitForm(e, this);
        });

        this.presentPasswordField = this.changePasswordForm.querySelector("#PresentPassword") as HTMLInputElement;
        this.newPasswordField = this.changePasswordForm.querySelector("#NewPassword") as HTMLInputElement;
        this.confirmPasswordField = this.changePasswordForm.querySelector("#ConfirmPassword") as HTMLInputElement;
        this.alertBox = this.changePasswordForm.querySelector("#resetpasswordalert") as HTMLDivElement;
        this.submitbutton = document.getElementById('changepwdformsubmitbutton') as HTMLButtonElement;

        this.presentPasswordField.addEventListener("input", () => {
            this.submitbutton.disabled = this.checkIfFormIsInvalid();
        });

        this.newPasswordField.addEventListener("input", () => {
            this.submitbutton.disabled = this.checkIfFormIsInvalid();
        });

        this.confirmPasswordField.addEventListener("input", () => {
            this.submitbutton.disabled = this.checkIfFormIsInvalid();
        });

        this.alertBox.dataset.genericText = this.alertBox.innerText;
        this.alertBox.innerText = "";
    }

    submitForm(event: Event, cObject: changePasswordClass) {
        event.preventDefault();
        if (!this.alertBox.classList.contains("d-none")) {
            this.alertBox.classList.add("d-none");
        }

        this.submitbutton.disabled = true;
        this.submitbutton.dataset.initialtext = this.submitbutton.innerText;
        this.submitbutton.innerText = this.submitbutton.dataset.waittext as string;

        let dataObject = {
            presentPassword: this.presentPasswordField.value,
            newPassword: this.newPasswordField.value,
            confirmPassword: this.confirmPasswordField.value
        } as IChangePasswordForm;

        changePassword(dataObject, this.alertBox.dataset.genericText as string).then((data: IChangePasswordResult) => {
            let message = data.message as string ?? "";

            let errorList = data.errorList as IIdentityError[];

            if (errorList !== null && errorList.length > 0) {
                if (message !== "") {
                    message += "<br />";
                }

                let isMulti = errorList.length > 1;
                message += isMulti ? "<ul class=\"mb-0\">" : "";
                for (var i = 0; i < errorList.length; i++) {
                    if ((errorList[i].description ?? "") !== "") {
                        let description = `${isMulti ? "<li>" : ""}${errorList[i].description}${isMulti ? "</li>" : "" }`;
                        message += description;
                    }
                }
                message += isMulti ? "</ul>" : "";
            }

            this.showMessage(message, data.success ? "success" : "error");

            this.submitbutton.disabled = false;
            this.submitbutton.innerText = this.submitbutton.dataset.initialtext as string;
        });
    }

    checkIfFormIsInvalid() : boolean {
        return this.presentPasswordField.value === "" || this.newPasswordField.value === "" || this.confirmPasswordField.value === "";
    }

    showMessage(message: string, type: string) {

        this.alertBox.innerHTML = message;
        if (type === "error") {
            this.alertBox.classList.remove("alert-success");
            this.alertBox.classList.add("alert-danger");
        } else {
            this.alertBox.classList.remove("alert-danger");
            this.alertBox.classList.add("alert-success");
        }

        this.alertBox.classList.remove("d-none");
    }
}