import axios from 'axios';

const baseUri = '/sv-se/api/OrderApi';

export const getSearchOptions = async () => {
	try {
		const { data } = await axios.get(`${baseUri}/searchoptions`);

		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};

export const getOrders = async (
	searchTab: string,
	query: string,
	searchBy: string,
) => {
	try {
		const { data } = await axios.post(`${baseUri}/orders`, {
			query,
			searchBy,
			status: searchTab,
		});
		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};

export const getOrder = async (orderNumber: string) => {
	try {
		const { data } = await axios.get(`${baseUri}/orders/${orderNumber}`);

		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};

export const archive = async (orderNumber: string) => {
	try {
		const { data } = await axios.get(`${baseUri}/archive/${orderNumber}`);

		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};

export const setItemDeliverability = async (
	orderId: number,
	status: string,
	itemIndex: number
) => {
	try {
		const { data } = await axios.post(`${baseUri}/deliverable`, {
			id: orderId,
			status,
			lineItem: itemIndex,
		});

		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};

export const sendOrderConfirmation = async (orderGroupId: string) => {
	try {
		const { data } = await axios.get(
			`${baseUri}/sendorderresponse/${orderGroupId}`
		);

		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message);
			return {};
		} else {
			console.log('unexpected error: ', error);
			return {};
		}
	}
};

export const sendExcel = (articles: any) =>
	new Promise((resolve, reject) =>
		setTimeout(() => {
			Math.round(Math.random())
				? reject(
						articles.map((article: any) =>
							Math.round(Math.random())
								? {
										...article,
										title: ':)',
										distributors: [
											{ name: 'ASDF', stock: 'two' },
											{ name: '4352', stock: 'three' },
											{ name: 'SDFS', stock: 'inStock' },
											{ name: 'QWEE', stock: 'five' },
										],
										selectedDistributor: 'ASDF',
								  }
								: {
										...article,
										title: 'Artikeln saknas',
										distributors: [],
										invalid: true,
								  }
						)
				  )
				: resolve(articles);
		}, 500)
	);
