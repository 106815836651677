import axios from 'axios';
import fileDownload from 'js-file-download';
import { SearchObject, SearchResult } from '../redux/types/types';

const BASEURI = '/api/search';
const apiClient = axios.create()

// Add a request interceptor to serialize the date object to a string
apiClient.interceptors.request.use((config) => {
    // Serialize dates in the request body
    config.data && (config.data = serializeDates(config.data));

    return config;
});

// Function to format a date to ISO 8601 string without timezone
const formatDateToIsoStringWithoutTimeZone = (date: Date): string => {
    const pad = (num: number) => String(num).padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Month is zero-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

// Function to serialize all dates in an object
function serializeDates(obj: any): any {
    if (obj === null || obj === undefined) {
        return obj;
    }
    if (obj instanceof Date) {
        return formatDateToIsoStringWithoutTimeZone(obj);
    }
    if (Array.isArray(obj)) {
        return obj.map(serializeDates);
    }
    if (typeof obj === 'object') {
        const serializedObj: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                serializedObj[key] = serializeDates(obj[key]);
            }
        }
        return serializedObj;
    }
    return obj;
}

export const getSearch = async (language: string, queryModel: SearchObject): Promise<SearchResult> => {
  try {
    const { data } = await apiClient.post<SearchResult>(`/${language}${BASEURI}/search`, queryModel);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      return {} as SearchResult;
    } else {
      console.log('unexpected error: ', error);
      return {} as SearchResult;
    }
  }
}

export const getCsv = async (language: string, id: string, fileName: string, extended: boolean): Promise<any> => {
    try {
        const { data } = extended ? await apiClient.request({
            method: 'GET',
            url: `/${language}${BASEURI}/csvexport-extended/${id}`,
            responseType: 'blob',
            responseEncoding: 'utf-8'
        }) : await axios.request({
            method: 'GET',
            url: `/${language}${BASEURI}/csvexport/${id}`,
            responseType: 'blob',
            responseEncoding: 'utf-8'
        })

        fileDownload(data, fileName);
        return true;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return {};
        } else {
            console.log('unexpected error: ', error);
            return {};
        }
    }
}