import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { t } from '../../services/translationService';

interface ICartFunctions {
    clear: MouseEventHandler;
    setShowSendOrder: Function;
    update: MouseEventHandler;
    hasChanged: boolean;
    anySelected: boolean;
    anyInvalid: boolean;
    submitting: boolean;
}

export const CartFunctions = ({
    clear,
    setShowSendOrder,
    update,
    hasChanged,
    anySelected,
    anyInvalid,
    submitting
}: ICartFunctions) => {

    const doSubmit = () => {
        setShowSendOrder(true);
    }

    return <div className="cart-functions d-flex justify-content-between">
        <Button variant="secondary" onClick={clear} className="d-none d-sm-block">
            <i className="bi bi-trash3 pe-2 lh-1" />
            {t('clearCart')}
        </Button>
        <div className="d-flex">
            <Button
                variant="secondary"
                className="position-relative"
                onClick={update}
                disabled={!hasChanged}
            >
                <i className="bi bi-arrow-repeat pe-2 lh-1 " />
                {t('update')}
                {hasChanged && (
                    <span
                        className="d-block position-absolute top-0 start-100 translate-middle badge rounded-pill p-1 pulse"
                        style={{ background: '#4075C8' }}
                    />
                )}
            </Button>
            <Button
                variant="primary"
                className="ms-3"
                onClick={doSubmit}
                disabled={!anySelected || anyInvalid || submitting}
            >
                {t('sendOrder')}
            </Button>
        </div>
    </div>
};
